import "./output.css"
import { createRoot } from 'react-dom/client';
import { PrivyProvider } from '@privy-io/react-auth';
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import mixpanel from 'mixpanel-browser';
import * as Sentry from '@sentry/capacitor';
import * as SentryReact from '@sentry/react';
import mitt from 'mitt';
import { ThemeProvider } from "@mui/styles";
import { MobileProvider } from "./utils/MobileContext";
import { createTheme, StyledEngineProvider } from "@mui/material/styles";
import { ThemeTypes } from './themes/ThemeTypes';
import CssBaseline from '@mui/material/CssBaseline';
import Parse from 'parse';
import ErrorPage from "./ErrorPage";
import { Capacitor } from "@capacitor/core";
import RootTabs from "./Navigation/RootTabs";
import { AnimationBuilder, IonApp, isPlatform, setupIonicReact } from '@ionic/react';
import { CapacitorUpdater } from '@capgo/capacitor-updater';
import { Animation, createAnimation } from "@ionic/react";
import { HelmetProvider } from 'react-helmet-async';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

CapacitorUpdater.notifyAppReady();

const platform = Capacitor.getPlatform();
const isMobileApp = platform === "ios" || platform === "android";
const isMobileWeb = window.innerWidth < 768;

const noAnimationBuilder: AnimationBuilder = () => {
  const animation = createAnimation();
  animation.duration(0);
  return animation;
};

setupIonicReact({
  navAnimation: isPlatform('mobileweb') || isPlatform('desktop') ? noAnimationBuilder : undefined,
});

if ('serviceWorker' in navigator) {
  window.addEventListener('load', function () {
    navigator.serviceWorker.register('/serviceworker.js').then(function (registration) {
      // Registration was successful
      console.log('ServiceWorker registration successful with scope: ', registration.scope);
    }, function (err) {
      // registration failed :(
      console.log('ServiceWorker registration failed: ', err);
    });
  });
}

window.addEventListener('error', e => {
  if (e.message === 'ResizeObserver loop limit exceeded' || e.message === 'ResizeObserver loop completed with undelivered notifications.' || e.message === "Script error.") {
    const resizeObserverErrDiv = document.getElementById(
      'webpack-dev-server-client-overlay-div'
    );
    const resizeObserverErr = document.getElementById(
      'webpack-dev-server-client-overlay'
    );
    if (resizeObserverErr) {
      resizeObserverErr.setAttribute('style', 'display: none');
    }
    if (resizeObserverErrDiv) {
      resizeObserverErrDiv.setAttribute('style', 'display: none');
    }
  }
});

const isDevelopment = process.env.NODE_ENV === 'development';
const mixpanelToken = process.env.REACT_APP_MIXPANEL_TOKEN || "";

if (mixpanelToken) {
  mixpanel.init(mixpanelToken, { debug: isDevelopment });

  if (isDevelopment) {
    mixpanel.disable();
  }
}

Sentry.init({
  dsn: "https://d8e0cdc3494f2a40019d1640e08df54d@o4504958178426880.ingest.us.sentry.io/4507035809415168",
  enabled: window.location.hostname !== "localhost",
  tracesSampleRate: 1.0, // Performance Monitoring
  ignoreErrors: ['ResizeObserver loop limit exceeded'],
},
  // Forward the init method from @sentry/react
  SentryReact.init
);

if (!isMobileApp) {
  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY || "",
    authDomain: "seam-social.firebaseapp.com",
    projectId: "seam-social",
    storageBucket: "seam-social.appspot.com",
    messagingSenderId: "363033650632",
    appId: "1:363033650632:web:1b18efe80a70dab3b00cfd",
    measurementId: "G-3L53PDR2FM"
  };

  // Initialize Firebase
  import("firebase/app").then(firebase => {
    firebase.initializeApp(firebaseConfig)
  });
  FirebaseAnalytics.initializeFirebase(firebaseConfig);
}

// Global Event Bus (pubsub)
window.emitter = mitt()

const defaultTheme = createTheme(ThemeTypes["Blueprint"].theme)
const devBackendMode = false
const serverURL = devBackendMode ? "http://localhost:1337/server" : "https://seam-db-backend.herokuapp.com/server"
//const serverURL = devBackendMode ? "http://172.16.22.239:1337/server" : "https://seam-db-backend.herokuapp.com/server"

function isLocalStorageAvailable() {
  var test = 'test';
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
}

Parse.initialize("SeamBackend")
Parse.serverURL = serverURL
Parse.enableLocalDatastore()

// https://react.dev/blog/2022/03/08/react-18-upgrade-guide#updates-to-client-rendering-apis
const container = document.getElementById('root');
const root = createRoot(container!);
if (isLocalStorageAvailable()) {
  root.render(
    <HelmetProvider>
    <IonApp className="seam">
      <MobileProvider>
        <PrivyProvider
          appId={process.env.REACT_APP_PRIVY_APP_ID || ""}
          config={{
            loginMethods: isMobileWeb ? ['sms'] : ['sms', 'email', 'wallet'],
            captchaEnabled: true,
            appearance: {
              theme: 'light',
              accentColor: '#676FFF',
              logo: '/seam-logo.png',
              showWalletLoginFirst: false,
            },
          }}
        >
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={defaultTheme}>
              <CssBaseline />
              <RootTabs />
            </ThemeProvider>
          </StyledEngineProvider>
        </PrivyProvider>
      </MobileProvider>
    </IonApp>
    </HelmetProvider>
  );
} else {
  mixpanel.track("localstorage unavailable")
  root.render(
    <ErrorPage />
  );
}
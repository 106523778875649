import { ThemeType } from "./ThemeTypes";

export const getCustomStyle = (
  themeType: ThemeType,
  bgImgUrl?: string,
) => {
  const { blockOptions, palette } = themeType.theme;
  const bg = palette.primary.main;
  const backgroundSize = themeType.theme.blockOptions.backgroundSize;
  const backgroundPosition = themeType.theme.blockOptions.backgroundPosition;
  const cursorUrl = blockOptions.cursorURL;

  return {
    backgroundImage: bgImgUrl ? `url(${bgImgUrl})` : "none",
    backgroundSize: backgroundSize,
    backgroundPosition: backgroundPosition,
    backgroundRepeat: "no-repeat",
    cursor: `url(${cursorUrl}), auto`,
    backgroundColor: bgImgUrl ? "" : bg,
  };
};

import React, { useState, useEffect } from 'react';
import { useSeamUser } from '../../utils/SeamUserContext';
import { useSeamNavigator } from '../../Navigation/SeamNavigatorContext';
import { LibraryAdd, LibraryAddOutlined } from '@mui/icons-material';
import { CircularProgress, Divider } from '@mui/material';
import SeamChannelRepository from '../../Channels/SeamChannelRepository';
import QuestManager from '../../Quests/QuestManager';

const SeamCollectingBottomSheet = ({ post, postInChannel, modalRef }) => {
  const { account, userCreatedChannels } = useSeamUser();
  const [postChannels, setPostChannels] = useState([]);
  const [loading, setLoading] = useState(false);
  const seamNavigator = useSeamNavigator();

  useEffect(() => {
    const postChannelsRelation = post.relation("channels");
    postChannelsRelation.query().find().then((results) => {
      setPostChannels(results);
    });
  }, []);

  const sendPostToChannel = async (channel, post, account) => {
    SeamChannelRepository.collectPostToChannel(channel, post, account, () => {
      const toastContent = (
        <div className="flex items-center justify-between h-auto w-auto">
          <h4 className="text-[#FEFEFE]/70 mr-2">You've added a post to</h4>
          <div
            onClick={() => seamNavigator.navigateTo(`/collection/${channel.id}`)}
            className="flex flex-row items-center justify-center cursor-pointer w-auto max-w-[50%] truncate"
          >
            <span className="no-underline">{channel.get("emoji")}</span><h4 className="underline truncate w-full">{channel.get("name")}</h4>
          </div>
        </div>
      );

      window.emitter.emit("SEAM_EVENT_ADDED_TO_COLLECTION", toastContent);
      QuestManager.completeStreakCollect(account);

      modalRef.current?.dismiss();
    }, () => {
      console.error('Failed to add post to the channel');
    });
  };

  const alreadyCollected = (channel) => {
    return postChannels.some((postChannel) => postChannel.id === channel.id);
  };

  const ChannelItem = (channel) => {
    const collected = alreadyCollected(channel);
    return (
      <React.Fragment key={channel.id}>
        <div className="flex flex-row items-center justify-between w-full space-x-4">
          <h3 className="truncate">{channel.get("emoji")} {channel.get("name")}</h3>
          <div
            onClick={() => {
              if (!collected) {
                sendPostToChannel(channel, post, account)
              } else {
                SeamChannelRepository.removePostFromChannel(post, postInChannel, () => {
                  modalRef.current?.dismiss();
                }, channel)
              }
            }}
            className="px-4 py-3 cursor-pointer border border-seam-gray rounded-[30px] flex items-center justify-center"
            style={{ backgroundColor: collected ? 'blue' : 'white' }}
          >
            {collected ? <LibraryAdd className="w-4 h-4 text-white color-seam-blue" /> : <LibraryAddOutlined className="w-4 h-4 text-seam-black" />}
          </div>
        </div>
        <Divider className="my-4 w-full bg-seam-gray" />
      </React.Fragment>
    )
  }

  return (
    <div className="flex flex-col h-full justify-between">
      {/* Header */}
      <div className="w-full flex items-center space-x-4 px-4">
        <LibraryAddOutlined className="w-4 h-4 text-seam-blue" />
        <h3 className="text-seam-blue">My Collections</h3>
      </div>

      {/* Scrollable list of channels */}
      <div className="flex-grow overflow-y-auto px-4 space-y-4" style={{ maxHeight: 'calc(100vh - 200px)' }}>
        {userCreatedChannels.map((channel) => (
          ChannelItem(channel)
        ))}
      </div>

      {/* Footer for new collection button */}
      <div
        className="w-auto py-4 mb-6 px-4 flex items-center space-x-2 justify-center text-seam-white cursor-pointer bg-seam-blue rounded-[30px]"
        onClick={() => {
          if (loading) return;
          setLoading(true);
          SeamChannelRepository.createChannel(
            {
              creator: account,
              name: "New Collection",
              emoji: "😊",
              headerImage: "",
            },
            newChannel => {
              const id = newChannel.id;
              SeamChannelRepository.collectPostToChannel(newChannel, post, account, () => {
                seamNavigator.navigateTo(`/collection/${id}`, newChannel);
                modalRef.current?.dismiss();
              }, () => {
                console.error('Failed to add post to the channel');
              });
            }
          );
        }}
      >
        {loading ? (
          <CircularProgress color="inherit" />
        ) : (
          <>
            <LibraryAddOutlined className="w-4 h-4 text-seam-white" />
            <h3 className="text-seam-white font-sans">New Collection</h3>
          </>
        )}
      </div>
    </div>
  );
};

export default SeamCollectingBottomSheet;
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import mixpanel from 'mixpanel-browser';
import Parse from 'parse';
import InfiniteScroll from "react-infinite-scroll-component";
import Divider from '@mui/material/Divider';
import ProfileItem from './ProfileItem';
import { useSeamNavigator } from '../Navigation/SeamNavigatorContext';

const useStyles = makeStyles({

});

export default function RandomCardList({ closeModal }) {
  const classes = useStyles();
  const seamNavigator = useSeamNavigator();
  const [accounts, setAccounts] = useState([]);

  useEffect(() => {
    const getSpecificAccount = async (userId) => {
      const AccountQuery = new Parse.Query("Account")
      AccountQuery.equalTo('userId', userId)
      const account = await AccountQuery.first();
      return await account;
    };

    const getSpecificAccounts = async () => {
      const userIds = ["Z3OHvYyCwp", "A8DUrAGnCwCuGEXG22O2TjMj", "3udnMDapvR", "hA08MTjGa3", "oBcFZRcfph"];
      try {
        const specificAccounts = await Promise.all(
          userIds.map(id => getSpecificAccount(id))
        );
        setAccounts(specificAccounts);
      } catch (error) {
        console.error("Error fetching cards:", error);
      }
    };

    getSpecificAccounts();
  }, []);

  return (
    <div className={classes.root}>
      <h2 style={{marginTop: '12px',}}>Suggested Friends</h2>
      <Divider />
      <InfiniteScroll
        dataLength={accounts.length}
        next={() => { }}
        hasMore={false}
        loader={<h4>Loading...</h4>}
      >
        {accounts.map((account, index) => (
          <ProfileItem
            account={account}
            index={index}
            tapAction={(account) => {
              console.log("navigating to " + account.get("profileId"));
              seamNavigator.navigateTo("user/" + account.get("profileId"), "_blank");
              closeModal();
              mixpanel.track("navigated", {location: account.get("profileId")})
            }}
          />
        ))}
      </InfiniteScroll>
      {accounts.length === 0 && <p>{"No random pages to display."}</p>}
    </div>
  );
}
import React from 'react';
import { Star, Public } from "@mui/icons-material";
import { Typography } from "@mui/material";
import DailyQuestsBar from "../Quests/DailyQuestsBar";
import { useMobile } from "../utils/MobileContext";

const FeedType = {
  global: "global",
  friends: "friends",
};

const FeedTypeSwitcher = ({ state, handleButtonClick }) => {
  const { isMobile } = useMobile();

  return (
    <div className="flex p-4 mb-6 flex-col justify-center w-auto h-auto items-center overflow-hidden hide-scrollbar">
      <div className="flex w-full max-w-[720px] justify-center items-center space-x-4"> {/* 16px space between buttons */}
        <button
          className={`py-3 flex flex-1 justify-center items-center space-x-1 relative ${state.feedType === FeedType.friends
            ? 'text-blue-600'
            : 'text-gray-400'
            }`}
          onClick={() => handleButtonClick(FeedType.friends)}
        >
          <Star className={`${isMobile ? 'w-4 h-4' : 'w-6 h-6'}`} />
          <Typography className="font-sans" variant={`${isMobile ? 'h4' : 'h3'}`}>Followed</Typography>
          {state.feedType === FeedType.friends && (
            <div className="absolute bottom-0 left-0 w-full h-1 bg-blue-600"></div>
          )}
        </button>
        <button
          className={`py-3 flex flex-1 justify-center items-center space-x-1 relative ${state.feedType === FeedType.global
            ? 'text-blue-600'
            : 'text-gray-400'
            }`}
          onClick={() => handleButtonClick(FeedType.global)}
        >
          <Public className={`${isMobile ? 'w-4 h-4' : 'w-6 h-6'}`} />
          <Typography className="font-sans" variant={`${isMobile ? 'h4' : 'h3'}`}>Everyone</Typography>
          {state.feedType === FeedType.global && (
            <div className="absolute bottom-0 left-0 w-full h-1 bg-blue-600"></div>
          )}
        </button>
      </div>
    </div>
  );
};

const HomeFeedHeader = ({ state, handleButtonClick, account }) => {
  const { isMobile } = useMobile();

  return (
    <div className="mb-4 flex w-full items-center hide-scrollbar overflow-x-visible justify-center">
      <DailyQuestsBar account={account} />
    </div>
  );
};

export default HomeFeedHeader;
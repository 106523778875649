import React, { createContext, useState, useContext } from 'react';

const ScrollingContext = createContext();

export const ScrollingProvider = ({ children }) => {
  const [isScrolling, setIsScrolling] = useState(false);

  return (
    <ScrollingContext.Provider value={{ isScrolling, setIsScrolling }}>
      {children}
    </ScrollingContext.Provider>
  );
};

export const useScrolling = () => {
  return useContext(ScrollingContext);
};

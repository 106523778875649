import InternalBlock from "./InternalBlock"
import { useMobile } from "../utils/MobileContext";
import { BlockModel } from '../Block-SDK/src/blocks/types'
import Button from "@mui/material/Button";
import { useState } from 'react';
import { Theme } from "@mui/material"
import { Dialog } from "@mui/material";
import LoadingComponent from '../components/LoadingComponent';
import CardList from '../components/CardList';

export default class InternalButtonBlock extends InternalBlock {
  render() {
    return InternalButton(this.model, this.theme, this.creatorAccount)
  }

  renderEditModal(done: (data: BlockModel) => void) {
    // Internal blocks can't be edited
    console.error("Internal blocks can't be edited!")
    return (
      <></>
    )
  }

  renderErrorState() {
    return (
      <h1>Error: Internal Block</h1>
    )
  }
}

function InternalButton(model: BlockModel, theme: Theme, creatorAccount: Parse.Object) {
  const [isLoading, setIsLoading] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false);
  let title = model.data["title"]
  let userId = model.data["userId"]

  const { isMobile } = useMobile();

  const modalForState = () => {
    if (isLoading) {
      return <LoadingComponent />
    }

    switch (model.type) {
      case "internal: cards": return CardList(userId)
      case "internal: channels": return <h1>My Channels</h1>
    }
  }

  return (
    <>
    <Button
      variant="contained"
      onClick={() => {setIsModalVisible(true)}}
      style={{
        backgroundColor: theme.palette.info.main,
        color: theme.palette.secondary.main,
        whiteSpace: "normal",
        height: '100%',
        width: '100%',
        fontFamily: theme.typography.fontFamily,
        textTransform: "none",
        borderRadius: theme.blockOptions.cornerRadius
      }}>
      {title}
    </Button>
    <Dialog
    open={isModalVisible}
    onClose={() => {
      setIsModalVisible(false)
      setIsLoading(false)
    }}
    sx={{
      '.MuiPaper-root': {
        padding: isMobile ? 1 : 5,
        backgroundColor: isLoading ? "black" : "white",
        width: isMobile ? "95%" : "40%"
      },
    }}
  >
    <div>
      {modalForState()}
    </div>
  </Dialog>
  </>
  );
}
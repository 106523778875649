import { Capacitor } from '@capacitor/core';
import { FirebaseMessaging } from "@capacitor-firebase/messaging";
import Parse from 'parse'
import mixpanel from 'mixpanel-browser';
import { useEffect } from 'react';
import { useSeamNavigator } from '../Navigation/SeamNavigatorContext';

/*

Construct and add a notification to the notifications table, where

actor: who is causing this notification to be sent (account id)
notifiers [array]: who should be notified about this notification (user ids)
description: string for the notif body
url: where should this notification go when tapped

*/
export const sendSubChannelNotification = (account: Parse.Object, notifier: string, collection: Parse.Object) => {
  // don't send notifications to following things
  if (account.id == notifier) { return }

  const url = "/user/" + account.get("profileId")
  const description = account.get("profileId") + " followed \"" + collection.get("emoji") + " " + collection.get("name") +"\""
  return Parse.Cloud.run('sendNotification', { actorAccountId: account.id, notifiers: [notifier], description, url });
}
export const sendCollectPostNotification = (account: Parse.Object, notifier: string, collection: Parse.Object) => {
  // don't send notifications to yourself collecting things
  if (account.id == notifier) { return }

  const url = "/collection/" + collection.id
  const description = account.get("profileId") + " collected your post into " + collection.get("name")
  return Parse.Cloud.run('sendNotification', { actorAccountId: account.id, notifiers: [notifier], description, url });
}
export const sendGroupPostNotification = (currentAccountName: string, notifiers: string[], groupId: string, description: string | undefined) => {
  const url = "/space/" + groupId
  const isDescriptionEmpty = description === undefined || description === null || description === ""
  const subtitle = isDescriptionEmpty ? currentAccountName + " sent a message in your group" : description
  Parse.Cloud.run("sendPushNotification", {
    notifiers: notifiers,
    description: subtitle,
    url: url,
    title: currentAccountName,
  }).then((message) => {
    // TODO: add to mobile badge counter
  }).catch((error) => {
    // Handle error if needed
  });
}
export const sendCommentNotification = (account: Parse.Object, currentAccountName: string, notifier: string, postId: string, text: string | undefined) => {
  const actorAccountId = account.id;
  const url = "/post/" + postId;
  const title = currentAccountName + " responded";
  const description = currentAccountName + " commented ";
  return Parse.Cloud.run('sendNotification', { actorAccountId: actorAccountId, notifiers: [notifier], description, url, title });
}
export const sendPostLikeNotification = (actorAccount: Parse.Object, currentAccountName: string, notifier: string, postId: string) => {
  const actorAccountId = actorAccount.id;
  const url = "/post/" + postId;
  const description = currentAccountName + " liked your post";
  return Parse.Cloud.run('sendNotification', { actorAccountId: actorAccountId, notifiers: [notifier], description, url });
}
export const sendCommentLikeNotification = (actorAccount: Parse.Object, currentAccountName: string, notifier: string, postId: string) => {
  const actorAccountId = actorAccount.id;
  const url = "/post/" + postId;
  const description = currentAccountName + " liked your comment";
  return Parse.Cloud.run('sendNotification', { actorAccountId: actorAccountId, notifiers: [notifier], description, url });
}

export const sendMentionNotification = (
  actorAccount: Parse.Object,
  notifier: string,
  postId: string
) => {
  const actorAccountId = actorAccount.id;
  const url = "/post/" + postId;
  const description = `${actorAccount.get("name")} mentioned you in a post`;
  return Parse.Cloud.run('sendNotification', { actorAccountId: actorAccountId, notifiers: [notifier], description, url });
}

export const sendCommentMentionNotification = (
  actorAccount: Parse.Object,
  currentAccountName: string,
  notifier: string,
  postId: string,
) => {
  const actorAccountId = actorAccount.id;
  const url = "/post/" + postId;
  const description = `${currentAccountName} mentioned you in a comment`;
  return Parse.Cloud.run('sendNotification', { actorAccountId, notifiers: [notifier], description, url });
}

export const useApplePushNotifications = (): void => {
  const navigator = useSeamNavigator();
  useEffect(() => {
    const platform = Capacitor.getPlatform();
    if (platform !== 'ios') return;
    FirebaseMessaging.addListener("notificationActionPerformed", (event) => {
      console.log("tapped notification")
      let url: string = (event.notification.data as any).url;
      mixpanel.track("tapped on ios notification")
      navigator.navigateTo(url);
    });
  }, []);
}
import { makeStyles } from "@mui/styles";
import Parse from 'parse';
import { Colors } from "../utils/colors"
import { SetStateAction, useEffect, useState } from "react";
import { Typography, Avatar, Box, TextField, IconButton, Tooltip } from "@mui/material";
import * as Sentry from "@sentry/react";
import mixpanel from "mixpanel-browser";
import { useSeamUser } from "../utils/SeamUserContext";
import SeamUserItem from "../components/SeamUserItem";
import { UserX } from "react-feather";

const useStyles = makeStyles({
  cardTitle: {
    fontFamily: "Public Sans",
    fontWeight: 700,
    color: 'black',
  },
  cardSubtitle: {
    textAlign: "left",
    fontSize: 14,
    fontWeight: 300,
    color: 'black',
  },
  emptyState: {
    textAlign: "left",
    fontSize: 28,
    fontWeight: 300,
    color: Colors.SEAM_GRAY_600
  },
  cardItem: {
    width: "95%",
    fontSize: "16px",
    color: Colors.SEAM_GRAY_100,
    cursor: "pointer",
    backgroundColor: "#EFEFEF",
    borderRadius: 24,
    marginBottom: '16px',
    marginLeft: '8px',
    marginRight: '8px',
    display: 'flex',
    alignItems: 'center',
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1)",
    border: '1px solid rgba(0, 0, 0, 0.1)',
    '&:hover': {
      backgroundColor: "#DADADA",
      boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)",
    }
  },
});

interface BlockedUserPage {

}

export default function BlockedUserPage(
  { }: BlockedUserPage
) {
  const classes = useStyles();
  const { account } = useSeamUser();

  const [blockedUsers, setBlockedUsers] = useState<Parse.Object[]>([])

  useEffect(() => {
    if (account == undefined) { return }
    const blockedArray = account?.get("blockedUsers") ?? []
    if (blockedArray.length === 0) { return }

    const blockedUsersQuery = new Parse.Query("Account")
    blockedUsersQuery.containedIn("objectId", blockedArray.map((user: Parse.Object) => user.id));

    blockedUsersQuery.find().then(function (results) {
      setBlockedUsers(results)
    })
      .catch(function (error) {
        console.log(error)
        Sentry.captureException(error);
      })
  }, [account]);

  if (blockedUsers.length == 0) {
    return <Typography className={classes.emptyState}>{"Nothing here - you haven't blocked anyone."}</Typography>
  }

  const tapAction = (connection: Parse.Object) => {
    // turning off navigation for now because it feels weird to navigate to a blocked user's profile
    // seamNavigator.navigateTo(connection.get("profileId"))
  }

  return (
    <div className="flex flex-col text-center items-start w-full">
      {blockedUsers.map((connection, index) => (
        <div className="w-full mb-4">
          <SeamUserItem
            avatar={connection.get("profilePhoto")}
            title={<h3>@{connection.get("profileId")}</h3>}
            subtitle={<h4>{connection.get("name")}</h4>}
            link={`/user/${connection.get("profileId")}`}
            actions={
              <div 
                onClick={(event) => {
                  event.stopPropagation();
                  if (window.confirm("Unblock " + connection.get("profileId") + "?")) {
                    setBlockedUsers(blockedUsers.filter((user) => user.id != connection.id))
                    account?.remove("blockedUsers", connection);
                    account?.save()
                      .then((updatedAccount: any) => {
                        mixpanel.track("unblocked_user")
                        console.log('Blocked user removed:', updatedAccount);
                      }, (error: any) => {
                        console.error('Error while removing blocked user:', error);
                      });
                  }
                }}
                className="flex flex-row items-center justify-center px-2 py-1 space-x-1 bg-seam-red rounded-[20px] "
              >
                <UserX size={16} color="#EFEFEF"/>
                <h4 className="text-seam-white">Unblock</h4>
            </div>}
          />
        </div>
      ))}
    </div>
  );
}
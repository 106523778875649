import InternalBlock from "./InternalBlock"
import { BlockModel } from '../Block-SDK/src/blocks/types'
import { useState } from 'react';
import { Divider, Stack, Theme, Button, Tooltip } from "@mui/material"
import { useEffect } from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import { createFriendRequest } from '../utils/ConnectionRequestNetworkHelpers';
import Parse from 'parse';
import * as Sentry from "@sentry/react";
import { useSeamNavigator } from "../Navigation/SeamNavigatorContext";

export default class InternalGroupMembersBlock extends InternalBlock {
  render() {
    return InternalGroupMembers(this.model, this.theme, this.creatorAccount, this.account, this.currentCard)
  }

  renderEditModal(done: (data: BlockModel) => void) {
    // Internal blocks can't be edited
    console.error("Internal blocks can't be edited!")
    return (
      <></>
    )
  }

  renderErrorState() {
    return (
      <h1>Error: Internal Block</h1>
    )
  }
}

function InternalGroupMembers(model: BlockModel, theme: Theme, creatorAccount: Parse.Object, account: Parse.Object, currentCard: Parse.Object) {
  const [isLoading, setIsLoading] = useState(false)

  const seamNavigator = useSeamNavigator();
  const isAuthenticated = Parse.User.current() != undefined

  const [group, setGroup] = useState<Parse.Object | undefined>();
  const [membershipStatus, setMembershipStatus] = useState<Parse.Object | undefined>();
  const [members, setMembers] = useState<Parse.Object[]>([])
  const [numMembers, setNumMembers] = useState(0)

  let primaryColor = theme.palette.primary.main
  let secondaryColor = theme.palette.secondary.main
  let teritaryColor = theme.palette.info.main

  const groupName = currentCard.get("name")

  // fetch the group object
  useEffect(() => {
    setIsLoading(true)
    const query = new Parse.Query("Group")
    query.equalTo("name", groupName)
    query.first().then(function (result) {
      setGroup(result)
    })
  }, []);

  // fetch the first 100 group members
  useEffect(() => {
    if (group) {
      setIsLoading(true)
      const connectionsRelation = group.relation("Members")
      const query = connectionsRelation.query()
      query.limit(100).find().then(function (results) {
        setMembers(results)
        if (results.length == 100) {
          //too many members, fire a query to find out how many it actually has
          query.count().then((number) => {
            setNumMembers(number)
          });
        } else {
          setNumMembers(results.length)
        }
        setIsLoading(false)
      })
    }
  }, [group]);

  // check to see if viewer has joined the group already
  useEffect(() => {
    if (account != undefined && group != undefined) {
      const connectionsRelation = account.relation("Groups")
      const query = connectionsRelation.query()
      query.equalTo("objectId", group.id)
      query.first().then(function (result) {
        setMembershipStatus(result)
      })
        .catch(function (error) {
          console.log(error)
          Sentry.captureException(error);
        })
    }
  }, [account, group]);

  const joinGroup = () => {
    if (window.confirm("Do you want to join the group?")) {
      Parse.Cloud.run("joinGroup", { groupId: group?.id, accountId: account.id }).then((result) => {
        // optimistically add as member
        setMembershipStatus(result)
        setNumMembers(numMembers + 1)
        setMembers([...members, account])
      })
    }
  }

  const leaveGroup = () => {
    if (window.confirm("Are you sure you want to leave the group?")) {
      Parse.Cloud.run("leaveGroup", { groupId: group?.id, accountId: account.id }).then((result) => {
        // optimistically remove as member
        setMembershipStatus(undefined)
        setNumMembers(numMembers - 1)
        let membersCopy = members
        membersCopy = membersCopy.filter(item => item.id !== result.id);
        setMembers(membersCopy)
      })
    }
  }

  const isMember = membershipStatus != undefined
  let friendsLabel = isMember ? "Leave" : "Join"
  let friendsIcon = isMember ? <PersonRemoveIcon /> : <PersonAddIcon />

  const onTap = () => {
    // if the person tapping join isn't logged into Seam yet, send them to the homepage (with a referral)
    if (!isAuthenticated) {
      const currentURL = window.location.protocol + '//' + window.location.host; // use this to avoid cors on dev and preview deployments
      seamNavigator.navigateTo(currentURL)
      return
    }

    if (account === undefined) {
      alert("You haven't created an account yet! Try logging out and logging back in again.");
      return
    }

    if (isMember) {
      leaveGroup()
    } else {
      joinGroup()
    }
  }

  const emptyStateComponent = () => {
    const emptyText = "No members yet :("
    return (
      <div style={{ display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center", width: "100%", height: 'calc(100% - 60px)' }}>
        <h3 style={{ color: teritaryColor }}>{emptyText}</h3>
      </div>
    )
  }

  const tapAction = (member: Parse.Object) => {
    if (isEmbedded) {
      window.open("/" + member.get("profileId"))
    } else {
      seamNavigator.navigateTo(member.get("profileId"))
    }
  }

  const getProcessedProfilePictureURL = (rawProfileURL: string) => {
    const eligibleForResize = !rawProfileURL.includes(".gif")
    let imageURLRaw = rawProfileURL + "?w=320&h=320"
    return eligibleForResize ? imageURLRaw.replace('raw', 'image') : rawProfileURL;
  }

  function inIframe() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  const isEmbedded = inIframe()
  // for pixels and embedded usecases, do not scroll and have a see more link back into Seam
  const shouldScroll = !isEmbedded
  const scrollAttribute = shouldScroll ? "scroll" : "hidden"
  const needsPlaceholders = members.length < 8
  const isEmpty = members.length == 0 && !isLoading
  let bg = secondaryColor + 'e6'

  return (
    <Stack direction={"column"} style={{ height: "100%", width: "100%" }} sx={{ border: '2px solid', borderColor: teritaryColor, backgroundColor: bg }} spacing={"16px"}>
      <div style={{ backgroundColor: secondaryColor }}>
        <Stack direction={"row"} style={{ justifyContent: "space-between", margin: 4 }}>
          <h2 style={{ color: teritaryColor }}>{"[" + numMembers + "]" + " Members"}</h2>
          <Button
            sx={{ color: teritaryColor, borderRadius: 0 }}
            style={{ border: "1px solid" }}
            size="large"
            onClick={() => { onTap() }}
            variant="outlined"
            startIcon={friendsIcon}> {friendsLabel}
          </Button>
        </Stack>
        <Divider sx={{ backgroundColor: teritaryColor, height: '1px' }} />
      </div>
      <div style={{ position: "relative", height: '100%', width: "100%" }}>
        {isEmpty && emptyStateComponent()}
        <ImageList cols={4} style={{ maxHeight: '100%', position: 'absolute', overflow: scrollAttribute }} sx={{ '&::-webkit-scrollbar': { display: 'none' } }}>
          {members.map((member) => (
            <Tooltip key={member.id} title={<h4 style={{ color: "white" }}>{member.get("name")}</h4>}>
              <ImageListItem key={member.id} onClick={() => { tapAction(member) }} style={{ cursor: "pointer" }}>
                <img
                  src={getProcessedProfilePictureURL(member.get("profilePhoto"))}
                  alt={member.get("name")}
                  loading="lazy"
                  style={{ aspectRatio: 1, borderRadius: "64px" }}
                />
              </ImageListItem>
            </Tooltip>
          ))}
          {needsPlaceholders && Array(8 - members.length)
            .map((_, i) => (
              <ImageListItem key={i}>
                <div style={{
                  backgroundColor: '#cccccc',
                  aspectRatio: 1
                }} />
              </ImageListItem>
            ))}
        </ImageList>
      </div>
    </Stack>
  );
}
import { createTheme } from "@mui/material";
import { ThemeType, ThemeTypes } from "./ThemeTypes";
import Parse from 'parse'

export class ThemeFactory {

  private static async isHolderOfCollection(wallet: string, contractAddress: string): Promise<boolean> {
    const apiKey = process.env.REACT_APP_ALCHEMY_API_KEY;
    const endpoint = `https://eth-mainnet.g.alchemy.com/nft/v2/${apiKey}/isHolderOfCollection?wallet=${wallet}&contractAddress=${contractAddress}`;
    
    const options = {
        method: 'GET',
        headers: {
            accept: 'application/json'
        }
    };

    try {
        const response = await fetch(endpoint, options);
        const data = await response.json();
        return data.isHolderOfCollection;
      } catch (error) {
        console.error("Error checking holder of collection:", error);
        return false;
    }
  } 

  
  // Fetch themes, convert them, and assign them to ThemeTypes
  public static async fetchConvertedThemes(): Promise<void> {
    const backendThemes = await ThemeFactory.fetchBackendThemes();
    const convertedThemes = ThemeFactory.convertBackendThemes(backendThemes);
    Object.assign(ThemeTypes, convertedThemes);
  }
  
  // Fetch themes from backend
  private static async fetchBackendThemes(): Promise<any[]> {
    return await Parse.Cloud.run("getThemes");
  }

  // Convert backend themes to the ThemeTypes format
  private static convertBackendThemes(backendThemes: any[]): { [key: string]: ThemeType } {
    const convertedThemes: { [key: string]: ThemeType } = {};
    backendThemes.forEach((backendTheme) => {
      const attributes = backendTheme.attributes;
      if (!attributes.name || !attributes.primaryColor || !attributes.secondaryColor) {
        console.error(`Theme ${attributes.name || 'undefined'} is missing name, primary, or secondary color. Skipping.`);
        return;
      }
      const theme: ThemeType = {
        name: attributes.name,
        theme: createTheme({
          palette: {
            primary: { main: attributes.primaryColor },
            secondary: { main: attributes.secondaryColor },
            info: { main: attributes.tertiaryColor || "#FFFFFF" },
          },
          typography: { fontFamily: attributes.typography },
          blockOptions: {
            cornerRadius: parseInt(attributes.cornerRadius, 10),
            cursorURL: attributes.cursorURL,
            border: attributes.border,
            dropShadow: attributes.dropShadow,
            backgroundImage: attributes.backgroundImage,
            backgroundSize: attributes.backgroundSize,
            backgroundPosition: attributes.backgroundPosition,
            contractAddress: attributes.contractAddress,
            collectionSlug: attributes.collectionSlug,
            thumbnailURL: attributes.thumbnailURL,
          },
        }),
        retired: attributes.retired || false,
      };
      convertedThemes[backendTheme.id] = theme;
    });
    return convertedThemes;
  }

  // Get a specific theme by its key
  public static getTheme(themeKey: keyof typeof ThemeTypes): ThemeType | null {
    return ThemeTypes[themeKey] || null;
  }

  // Load all themes, process them, and return in AllThemes array structure
  public static async loadAllThemes(): Promise<any[]> {
    // Fetch backend themes, convert to MUI theme, and implement into the ThemeTypes object
    await ThemeFactory.fetchConvertedThemes();

    // Convert ThemeTypes into the desired structure for allThemes
    return Object.entries(ThemeTypes)
      .filter(([key, themeType]) => !themeType.retired)
      .map(([key, themeType]) => ({
        name: themeType.name,
        themeKey: key, // Use the key as the themeKey
        thumbnailURL: themeType.theme.blockOptions.thumbnailURL,
      }));
  }

  public static loadLocalThemes() {
    return Object.entries(ThemeTypes)
      .filter(([key, themeType]) => !themeType.retired)
      .map(([key, themeType]) => ({
        name: themeType.name,
        themeKey: key, // Use the key as the themeKey
        thumbnailURL: themeType.theme.blockOptions.thumbnailURL,
      }));
  }

  // fetch any owned themes from the user's eth address by collection slug
  public static async loadOwnedThemes(ethAddress: string): Promise<any[]> {    
    const owner = ethAddress;
  
    if (!owner) {
      console.error("Unable to resolve owner address. Exiting.");
      return [];
    }
  
    let nftThemes: any[] = [];
    let freeThemes: any[] = [];
  
    const themesWithContractAddress = Object.entries(ThemeTypes).filter(
      ([key, themeType]) => themeType.theme.blockOptions.contractAddress
    );
  
    const isHolderPromises = themesWithContractAddress.map(([key, themeType]) =>
      ThemeFactory.isHolderOfCollection(owner, themeType.theme.blockOptions.contractAddress)
    );
    
    // Promise.all to execute API calls concurrently
    const isHolderResults = await Promise.all(isHolderPromises);
  
    // populate NFT themes with results from API call
    for (let i = 0; i < themesWithContractAddress.length; i++) {
      const [key, themeType] = themesWithContractAddress[i];
      const isHolder = isHolderResults[i];
  
      if (isHolder) {
        nftThemes.push({
          name: themeType.name,
          themeKey: key,
          thumbnailURL: themeType.theme.blockOptions.thumbnailURL,
        });
      }
    }
    freeThemes = Object.entries(ThemeTypes).filter(
        ([key, themeType]) => !themeType.theme.blockOptions.contractAddress
    ).map(([key, themeType]) => ({
        name: themeType.name,
        themeKey: key,
        thumbnailURL: themeType.theme.blockOptions.thumbnailURL,
    }));
    // Return a combined list of free themes and themes the user owns
    return [...nftThemes, ...freeThemes];
  }

}
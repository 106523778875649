import {
  IonContent,
  IonModal,
  useIonRouter,
} from "@ionic/react";
import { useEffect, useRef, useState, useReducer } from "react";
import { Activity, ChevronLeft, X } from "react-feather";
import { AddRounded, LibraryAdd, LibraryAddOutlined, ExpandMore, ExpandLess } from "@mui/icons-material";
import { replaceMentionsInText } from "../components/helpers/formatters";
import mixpanel from "mixpanel-browser";
import Parse from "parse";
import { useSeamUser } from "../utils/SeamUserContext";
import SeamHeaderBar from "../Block-SDK/src/components/SeamHeaderBar";
import { Button, Divider, Avatar, CircularProgress, Stack, Typography } from "@mui/material";
import BlockFactory from "../Block-SDK/src/blocks/BlockFactory";
import MentionSearch from "../Comment/Mentions/MentionSearch";
import { BlockTypes } from "../Block-SDK/src/blocks/types";
import { makeStyles } from "@mui/styles";
import QuestManager from "../Quests/QuestManager";
import BlockSelectorModal from "../Block-SDK/src/Composer/BlockSelectorModal";
import { useMobile } from '../utils/MobileContext';
import SeamComposerUnlockMiniAppStep from "./SeamComposerUnlockMiniAppStep";
import SeamComposerChooseBlockStep from "./SeamComposerChooseBlockStep";
import NotificationReminderDialog from "../components/NotificationReminderDialog";
import { CheckNotificationPermission } from "../components/NotificationRegistrationHelpers";
import { getProcessedProfilePictureURL } from "../components/helpers/ImageTransformers";
import SeamPostRepository from "../Post/SeamPostRepository";
import SeamChannelRepository from "../Channels/SeamChannelRepository";
import { useSeamNavigator } from "../Navigation/SeamNavigatorContext";

const initialState = {
  isOpen: false,
  selectedBlockType: null,
  description: "",
  tags: [],
  mentionList: [],
  selectedBlockData: {},
  composerStep: "selectBlock",
  isPosting: false,
};

const useStyles = makeStyles({
  noScrollBar: {
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "-ms-overflow-style": "none" /* IE and Edge */,
    "scrollbar-width": "none" /* Firefox */,
  },
});

// Define the reducer function
function reducer(state, action) {
  switch (action.type) {
    case "dismiss":
      return { ...initialState };
    case "handleGoBack":
      if (state.composerStep === "editBlock") {
        return {
          ...state,
          composerStep: "selectBlock",
          selectedBlockType: null,
          selectedBlockData: {},
        };
      } else if (state.composerStep === "previewPost") {
        return { ...state, composerStep: "editBlock" };
      } else if (state.composerStep === "unlock") {
        return { ...state, composerStep: "selectBlock" };
      }
    case "chooseBlock":
      return {
        ...state,
        selectedBlockType: action.payload,
        composerStep: "editBlock",
      };
    case "unlockBlock":
      return {
        ...state,
        selectedBlockType: action.payload,
        composerStep: "unlock",
      };
    case "successUnlock":
      return {
        ...state,
        composerStep: "successUnlock",
      };
    case "tapBlockAttribution":
      return {
        ...state,
        selectedBlockType: action.payload,
        isOpen: true,
        composerStep: "editBlock",
      };
    case "tapBlockAttributionUnlock":
      return {
        ...state,
        selectedBlockType: action.payload,
        isOpen: true,
        composerStep: "unlock",
      };
    case "finishBlockEditing":
      return {
        ...state,
        selectedBlockData: action.payload,
        composerStep: "previewPost",
      };
    case "setIsOpen":
      return { ...state, isOpen: action.payload };
    case "setDescription":
      return { ...state, description: action.payload };
    case "setTags":
      return { ...state, tags: action.payload };
    case "setMentionList":
      return { ...state, mentionList: action.payload };
    case "postCompleted":
      return {
        ...state,
        composerStep: "selectBlock",
      };
    case "setIsPosting":
      return { ...state, isPosting: action.payload };
    default:
      throw new Error();
  }
}

function SeamComposer({
  composerTitle,
  communityName,
  communityId,
  currentCard,
  channel,
  completion,
  desktopComposer = false,
  postingMiniappsOnly = true,
  commentCompletionOverride = null,
  hidePlusIcon = false,
  unlockableMiniappsOnly = false,
  darkMode,
}) {
  const modal = useRef(null);
  const blockPreviewRef = useRef(null);
  const tagInputBarRef = useRef(null);
  const classes = useStyles();
  const seamNavigator = useSeamNavigator();
  const { account, setAccount, isAdmin, userCreatedChannels, recentlyUpdatedChannel, setRecentlyUpdatedChannel } = useSeamUser();
  const { isMobile, isMobileApp } = useMobile();
  const ipadsAndMobiles = isMobile || isMobileApp;
  const router = useIonRouter();

  const [state, dispatch] = useReducer(reducer, initialState);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [disableDialog, setDisableDialog] = useState(false);

  const isGroupPost = currentCard && Array.isArray(currentCard?.get("Members"));
  const pageEditorComposerMode = postingMiniappsOnly === false;

  const [isAdminPost, setIsAdminPost] = useState(false);
  const [selectedChannel, setSelectedChannel] = useState(recentlyUpdatedChannel);
  const [isExpanded, setIsExpanded] = useState(false);

  const isOnChannelPage = router.routeInfo.pathname.includes("/collection/");
  const urlPath = router.routeInfo.pathname;
  const urlChannelId = urlPath.substring(urlPath.lastIndexOf('/') + 1);

  const color = darkMode ? "white" : "black";

  useEffect(() => {
    if (state.isOpen) {
      mixpanel.track("composer enter step " + state.composerStep, {
        "Composer Block Type": state.selectedBlockType?.type,
      });
    }
  }, [state.composerStep]);

  useEffect(() => {
    const checkPermissions = async () => {
      const permission = await CheckNotificationPermission();
      setDisableDialog(permission === 'granted' || permission === 'denied' || permission === 'unsupported');
    };

    checkPermissions();
  }, []);

  // if a channel gets updated, have the composer dropdown update
  useEffect(() => {
    if (recentlyUpdatedChannel) {
      setSelectedChannel(recentlyUpdatedChannel);
    }
  }, [recentlyUpdatedChannel]);


  // if selectedchannel is not an unsaved channel, and its not present in usercreatedchannels, its a deleted channel
  // remove it and set selectedchannel to the first createdchannel if it exists
  useEffect(() => {
    if (
      selectedChannel &&
      !selectedChannel.isNew() &&
      !userCreatedChannels.some(channel => channel.id === selectedChannel.id)
    ) {
      setSelectedChannel(
        userCreatedChannels.length > 0 ? userCreatedChannels[0] : recentlyUpdatedChannel
      );
    }
  }, [userCreatedChannels, selectedChannel]);

  useEffect(() => {
    if (isOnChannelPage) {
      const matchedChannel = userCreatedChannels.find(channel => channel.id === urlChannelId);

      if (matchedChannel) {
        setSelectedChannel(matchedChannel);
        setIsExpanded(false);
      }
    }
  }, [isOnChannelPage, urlChannelId, userCreatedChannels]);

  useEffect(() => {
    window.emitter.on("SEAM_OPEN_COMPOSER", () => {
      dispatch({ type: "setIsOpen", payload: true });
    });
    return () => {
      window.emitter.off("SEAM_OPEN_COMPOSER");
    };
  }, []);

  useEffect(() => {
    const handleBlockTypeClick = (blockType) => {
      if (blockType === undefined) {
        dispatch({ type: "setIsOpen", payload: true });
        return
      }

      const isMiniappOwned = account?.get("unlockedMiniapps")?.includes(blockType);
      const isDefaultMiniapp = BlockTypes[blockType].createdBy[0].username === "seam";
      if (isMiniappOwned || isDefaultMiniapp) {
        dispatch({ type: "tapBlockAttribution", payload: BlockTypes[blockType] });
      } else {
        dispatch({ type: "tapBlockAttributionUnlock", payload: BlockTypes[blockType] });
      }
    };

    window.emitter.on("SEAM_POST_BLOCKTYPE_CLICK", handleBlockTypeClick);
    window.emitter.on("SEAM_POST_BLOCKTYPE_UNLOCK", handleBlockTypeClick); // Handle unlock event

    return () => {
      window.emitter.off("SEAM_POST_BLOCKTYPE_CLICK", handleBlockTypeClick);
      window.emitter.off("SEAM_POST_BLOCKTYPE_UNLOCK", handleBlockTypeClick); // Cleanup unlock event
    };
  }, []);

  const addMention = (mentionId) => {
    dispatch({
      type: "setMentionList",
      payload: [...state.mentionList, { id: mentionId }],
    });
  };

  const handleCreatePost = async () => {
    if (state.isPosting) return;

    if (!selectedChannel) {
      alert("Please select at least one channel.");
      return;
    }

    dispatch({ type: "setIsPosting", payload: true });
    if (state.composerStep === "textPost" && !state.description.trim()) {
      alert("Please enter some text before posting.");
      return;
    }

    if (!account) {
      alert("Current account could not be found. Unable to create a post. Try signing in again!");
      return;
    }

    if (isGroupPost && currentCard) {
      const userName = account.get("name").split(" ")[0];
      const actionString = `${userName} sent a post`;

      const Group = Parse.Object.extend("Group");
      const groupQuery = new Parse.Query(Group);
      groupQuery.equalTo("objectId", currentCard.id);
      try {
        const group = await groupQuery.first();
        if (group) {
          group.set("activity", actionString);
          await group.save();
        }
      } catch (error) {
        console.error("Error updating group activity: ", error);
      }
    }

    const blockTypeTag = state.selectedBlockType
      ? state.selectedBlockType.displayName.replace(/^#+/, "").trim()
      : null;
    const tagsWithBlockType = blockTypeTag
      ? [blockTypeTag, ...state.tags]
      : [...state.tags];

    const partialTag = tagInputBarRef.current?.getPartialTag();
    if (partialTag) {
      tagsWithBlockType.push(partialTag);
    }

    const tags = tagsWithBlockType;

    const processedMentions = state.mentionList.map((mention) => {
      return { type: "user", userIds: [mention.id] };
    });

    let height = blockPreviewRef.current?.offsetHeight;
    let width = blockPreviewRef.current?.offsetWidth;
    let ratio = width / height;
    let aspectRatio = { width: width, ratio: ratio };

    let channelToPostInto = selectedChannel;
    const isNewChannel = selectedChannel.isNew();
    if (isNewChannel) {
      channelToPostInto = await selectedChannel.save();
    }

    const PostToast = () => {
      return (
        <div className="flex items-center justify-between h-auto w-auto">
          <h4 className="text-[#FEFEFE]/70 mr-2">You've added a post to</h4>
          <div
            onClick={() => seamNavigator.navigateTo(`/collection/${selectedChannel.id}`, selectedChannel)}
            className="flex flex-row items-center justify-center cursor-pointer w-auto max-w-[50%] truncate"
          >
            <span className="no-underline">{selectedChannel.get("emoji")}</span>
            <h4 className="underline truncate w-full">{selectedChannel.get("name")}</h4>
          </div>
        </div>
      )
    }

    const handlePostCompletion = async (newPostInChannel) => {
      const permission = await CheckNotificationPermission();
      // Check if state.selectedBlockType and state.selectedBlockType.type are not null or undefined
      if (state.selectedBlockType && state.selectedBlockType.type) {
        QuestManager.completeMiniappQuest(tags, state.selectedBlockType.type, account);
      } else {
        console.error("selectedBlockType or its type property is null or undefined");
      }

      window.emitter.emit("SEAM_EVENT_POST_SUCCESSFUL", newPostInChannel);
      completion(newPostInChannel);
      dispatch({ type: "postCompleted" });

      try {
        const toastContent = <PostToast channel={channel} />;

        window.emitter.emit("SEAM_EVENT_ADDED_TO_COLLECTION", toastContent);
      } catch (error) {
        console.error("Error optimstically updating with new savedPostInChannel", error);
      }

      if (permission === 'default') {
        setDialogOpen(true);
      }
    };

    SeamPostRepository.createPost({
      author: account,
      tags: tags,
      channel: channelToPostInto,
      channelCount: 0,
      text: replaceMentionsInText(state.description),
      blockData: state.selectedBlockData,
      mentions: processedMentions,
      aspectRatio: aspectRatio,
      isAdminPost: isAdminPost,
      setRecentlyUpdatedChannel: setRecentlyUpdatedChannel,
    }, handlePostCompletion)

    mixpanel.track("composer post");

    dismiss();
  };

  function dismiss() {
    dispatch({ type: "dismiss" });
    modal.current?.dismiss();
  }

  const chooseBlock = (blockType) => {
    dispatch({ type: "chooseBlock", payload: blockType });
  };

  const pageEditCompletion = (data) => {
    window.emitter.emit("SEAM_EVENT_ADD_BLOCK", data);
    completion(data);
    dismiss();
  };

  const editBlockStep = () => (
    <div className="h-full w-auto" style={{ boxSizing: "border-box" }}>
      <div className="px-4" style={{ position: BlockFactory.doesBlockEditFullscreen(state.selectedBlockType?.type) ? "absolute" : "relative", paddingBottom: 16, width: "100%", boxSizing: 'border-box' }}>
        <SeamHeaderBar
          leftComponent={<ChevronLeft color="black" />}
          rightComponent={<X color="black" />}
          centerComponent={BlockFactory.doesBlockEditFullscreen(state.selectedBlockType?.type) ? null : <h3>{state.selectedBlockType?.displayName}</h3>}
          leftAction={() => {
            if (window.confirm("Are you sure you want to go back? Your pending post will be lost.")) {
              dispatch({ type: "handleGoBack" })
            }
          }}
          rightAction={dismiss}
        />
      </div>
      <BlockSelectorModal
        selectedBlockType={state.selectedBlockType.type}
        initialBlockData={state.selectedBlockData?.data ?? {}}
        setSelectedBlockData={(data) => {
          if (pageEditorComposerMode) {
            pageEditCompletion(data);
            return;
          }
          if (commentCompletionOverride) {
            commentCompletionOverride(data);
            dismiss();
            return;
          }
          dispatch({ type: "finishBlockEditing", payload: data });
        }}
      />
    </div>
  );

  const renderBlockPreview = (blockData) => {
    if (!blockData) return <div>No Block Data</div>;
    return (
      <div
        className="flex flex-row justify-center items-center overflow-x-hidden min-w-full"
        ref={blockPreviewRef}
      >
        {BlockFactory.getFeedComponent(blockData, () => { })}
      </div>
    );
  };

  const processedProfilePhoto = getProcessedProfilePictureURL(account?.get("profilePhoto"))

  const toggleChannelSelection = (channel) => {
    setSelectedChannel(channel);
  };

  useEffect(() => {
    if (!selectedChannel) {
      // Set the initial channel if it's not set, prioritizing `channel` or `recentlyUpdatedChannel`
      const defaultChannel = channel ?? recentlyUpdatedChannel
      setSelectedChannel(defaultChannel);
      setRecentlyUpdatedChannel(defaultChannel);
    }
  }, [channel, recentlyUpdatedChannel, userCreatedChannels, account, selectedChannel]);
  
  const renderChannelDropdown = () => {
    if (!selectedChannel && userCreatedChannels.length > 0) {
      setSelectedChannel(userCreatedChannels[0]);
      return null; // Wait for `selectedChannel` to set before rendering
    }
    if (!selectedChannel) {
      const defaultChannel = SeamChannelRepository.emptyChannel({
        creator: account,
        name: "New Collection",
        emoji: "😊",
        headerImage: "",
      });
      setSelectedChannel(defaultChannel);
      setRecentlyUpdatedChannel(defaultChannel);
    }

    return (
      <div
        className="flex w-auto items-center space-x-2"
        onClick={() => { isExpanded ? toggleChannelSelection(selectedChannel) : toggleDropdown(); }}
      >
        <div
          className={`inline-flex px-4 py-2 cursor-pointer border border-seam-gray rounded-full items-center justify-center shrink-0 bg-seam-blue text-white`}
        >
          <Typography variant="h3" className="">{selectedChannel?.get("emoji")} {selectedChannel?.get("name")}</Typography>
        </div>
        <div onClick={toggleDropdown} className="cursor-pointer">
          {isExpanded ? <ExpandLess /> : <ExpandMore />}
        </div>
      </div>
    );
  };

  const renderFullChannelList = () => {
    if (!isExpanded) return null;

    return (
      <>
        {userCreatedChannels.map((channel) => {
          if (channel.id === selectedChannel.id) return null; // Skip already shown channel

          return (
            <div
              key={channel.id}
              className={`px-4 py-2 w-auto inline-flex items-center justify-center cursor-pointer border border-seam-gray rounded-full ${isExpanded && 'mt-4'} bg-white text-seam-black`}
              onClick={() => toggleChannelSelection(channel)}
            >
              <Typography variant="h3" className="w-auto text-center">{channel.get("emoji")} {channel.get("name")}</Typography>
            </div>
          );
        })}
      </>
    );
  };

  const toggleDropdown = () => {
    setIsExpanded(!isExpanded);
  };

  const previewBlockStep = () => (
    <div className="flex flex-col h-full mx-4 my-0">
      <div className="pb-4 sticky top-0 bg-white z-10">
        <SeamHeaderBar
          leftComponent={<ChevronLeft color="black" />}
          rightComponent={<X color="black" />}
          centerComponent={<h3>{state.selectedBlockType?.displayName}</h3>}
          leftAction={() => {
            dispatch({ type: "handleGoBack" });
          }}
          rightAction={dismiss}
        />
      </div>
      <div className="flex-grow overflow-x-hidden flex items-center hide-scrollbar justify-center">
        {state.selectedBlockData != {} &&
          renderBlockPreview(state.selectedBlockData)}
      </div>
      <div
        className="p-4 bg-[#FCFCFC] w-auto rounded-[20px] border-2 space-y-4 border-b-0 border-seam-gray"
        style={{
          marginLeft: "-1rem",
          marginRight: "-1rem",
          marginBottom: "env(safe-area-inset-bottom)",
        }}
      >
        <div className="flex flex-row justify-center items-center">
          {/* Profile Pic and Text Input */}
          <div className="mr-4">
            <Avatar
              className="rounded-full h-8 w-8"
              src={processedProfilePhoto}
              alt="Profile"
            />
          </div>
          <div className="w-full">
            <MentionSearch
              textValue={state.description}
              mentionList={state.mentionList}
              onChange={(_, newValue) =>
                dispatch({ type: "setDescription", payload: newValue })
              }
              addMention={addMention}
              placeholder="Add a description"
              onSubmit={() => {}}
            />
          </div>
        </div>
        <Divider className="" />
        <div className="flex flex-row w-auto">
          <h3 className="pb-2 flex-1">Collecting into:</h3>
          <div className="flex flex-col w-auto items-end">
            {renderChannelDropdown()}
            {renderFullChannelList()}
          </div>
        </div>

        {/* Admin Post Option */}
        {isAdmin && (
          <div className="flex items-center mt-2 mb-2">
            <input
              type="checkbox"
              id="adminPostCheckbox"
              checked={isAdminPost}
              onChange={(e) => setIsAdminPost(e.target.checked)}
              className="mr-2"
            />
            <label htmlFor="adminPostCheckbox">Post to Admin Feed</label>
          </div>
        )}

        {/* Post Button */}
        <Button
          onClick={handleCreatePost}
          fullWidth
          variant="contained"
          color="primary"
          className="h-[60px] justify-center items-center flex rounded-[8px] bg-seam-blue"
        >
          {state.isPosting ? <CircularProgress color="inherit" /> : <h3 className="text-seam-white">Post</h3>}
        </Button>
      </div>
    </div>
  );

  const unlockMiniappStep = () => {
    const cost = 30;
    return (
      <div className="h-full w-auto px-4">
        <SeamHeaderBar
          leftComponent={<ChevronLeft color="black" />}
          rightComponent={<X color="black" />}
          centerComponent={<h4>{"Unlock " + state.selectedBlockType?.displayName + " Miniapp"}</h4>}
          leftAction={() => {
            dispatch({ type: "handleGoBack" });
          }}
          rightAction={dismiss}
        />
        <SeamComposerUnlockMiniAppStep
          block={state.selectedBlockType}
          cost={cost}
          cancelAction={() => {
            dispatch({ type: "handleGoBack" });
          }}
          confirmAction={() => {
            Parse.Cloud.run("unlockMiniappWithSplits", { cost: cost, accountId: account.id, type: state.selectedBlockType.type, pointSplits: state.selectedBlockType.createdBy }).then((account) => {
              mixpanel.track("composer unlock miniapp", {
                "Miniapp Type": state.selectedBlockType.type,
              })
              setAccount(account);
            }).catch((error) => {
              alert("Failed to unlock miniapp: " + error.message);
            })
            dispatch({ type: "successUnlock", payload: state.selectedBlockType });
            setTimeout(() => {
              dispatch({ type: "chooseBlock", payload: state.selectedBlockType });
            }, 3000);
          }}
        />
      </div>
    )
  };

  const successUnlockStep = () => (
    <div className="h-full flex flex-col justify-center items-center px-4 bg-seam-green">
      <h1 className="text-center">Sent!</h1>
      <p className="text-center pt-4">{"You can now make your first post using the " + state.selectedBlockType?.displayName + " miniapp"} </p>
    </div>
  );

  const renderContent = () => {
    switch (state.composerStep) {
      case "selectBlock":
        return SeamComposerChooseBlockStep({ account, dismiss, chooseBlock, composerTitle, postingMiniappsOnly, unlockableMiniappsOnly, pageEditCompletion, unlockAction: (block) => dispatch({ type: "unlockBlock", payload: block }) });
      case "editBlock":
        return editBlockStep();
      case "previewPost":
        return previewBlockStep();
      case "unlock":
        return unlockMiniappStep();
      case "successUnlock":
        return successUnlockStep();
      default:
        return null;
    }
  };

  const isEditBlockStep = state.composerStep === "editBlock";
  const scrollY = !(isEditBlockStep && ipadsAndMobiles);

  return (
    <div className={`h-full w-full ${classes.noScrollBar}`}>
      <div
        className={`cursor-pointer h-full w-full flex flex-col items-center justify-center ${classes.noScrollBar}`}
        onClick={(e) => {
          e.preventDefault();
          dispatch({ type: "setIsOpen", payload: true });
        }}
        id="open-modal"
        expand="block"
      >
        {!hidePlusIcon &&
          <div className="flex flex-row space-x-4 items-center justify-center">
            <div className="bg-seam-pink rounded-full flex items-center justify-center h-6 w-6"><AddRounded className="w-[20px] h-[20px] self-center text-[#FEFEFE] hover:text-[#FEFEFE]" /></div>
            {desktopComposer && <Typography variant="h3" className={`text-center hover:underline text-${color}`}>New Post</Typography>}
          </div>
        }
      </div>
      {state.isOpen && <IonModal
        className={`composer-modal ${isMobile ? "" : "h-screen custom-modal-fullscreen"}`}
        ref={modal}
        isOpen={state.isOpen}
        canDismiss={true}
        showBackdrop={true}
        onDidDismiss={() => {
          dispatch({ type: "dismiss" });
        }}
        style={{ marginTop: "env(safe-area-inset-top)" }}
      >
        <IonContent
          className={`h-full ${classes.noScrollBar}`}
          scrollY={scrollY}
        >
          <div className={`flex flex-col justify-between h-full ${classes.noScrollBar}`}>
            {renderContent()}
          </div>
        </IonContent>
      </IonModal>}
      {!disableDialog && (
        <NotificationReminderDialog
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
        />
      )}
    </div>
  );
}

export default SeamComposer;
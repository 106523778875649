import Parse from 'parse';
import LoadingComponent from "./components/LoadingComponent";
import MyStuffPage from "./MyStuffPage";
import HomePage from "./Routes/HomePage";
import { Redirect } from "react-router-dom"
import GroupPage from "./Groups/GroupPage";
import Inbox from "./Inbox";
import MobileQuestsPage from "./MobileQuestsPage";
import { useSeamUser } from "./utils/SeamUserContext";
import SearchPage from "./Discover/SearchPage";
import NotificationsPage from "./NotificationsPage";
import ErrorPage from "./ErrorPage";
import { useMobile } from './utils/MobileContext';
import FirstTimeUnlock from './components/FirstTimeUnlock';
import { Seo } from './utils/Seo';
import LandingPage from './LandingPage';
import WelcomeFromWebCreatedAccountPage from './components/WelcomeFromWebCreatedAccountPage';

export enum SeamPageType {
  Home,
  MyStuff,
  Admin,
  Content,
  Post,
  Comment,
  Profile,
  Group,
  Inbox,
  Quests,
  Search,
  Notifications,
  Landing
}

interface InternalPageProps {
  type: SeamPageType;
}

// Protects Internal pages from unauthorized users, making them sign in or complete onboarding
// Hosts the header so we only have to implement that in one place
export default function InternalPage(
  { type }: InternalPageProps
) {
  const isAuthenticated = Parse.User.current() != undefined
  const { account, isLoadingAccount } = useSeamUser();
  const { isMobileApp } = useMobile();

  if (account == undefined && isLoadingAccount) {
    return <LoadingComponent />
  }

  if (!isAuthenticated || account == undefined) {
    if (isMobileApp) {
      return <Redirect exact path="/" to={account != undefined ? "/homeTab" : "/login"} />
    }
    return <LandingPage />
  }

  const pageForType = () => {
    switch (type) {
      case SeamPageType.Landing: { return isMobileApp ? <Redirect exact path="/" to={account != undefined ? "/profileTab" : "/login"} /> : <Redirect exact path="/" to={account != undefined ? `/user/${account.get("profileId")}` : "/login"} /> }
      case SeamPageType.Home: return <HomePage />
      case SeamPageType.MyStuff: return <MyStuffPage />
      case SeamPageType.Group: return <GroupPage />
      case SeamPageType.Inbox: return <Inbox />
      case SeamPageType.Quests: return <MobileQuestsPage />
      case SeamPageType.Search: return <SearchPage />
      case SeamPageType.Notifications: return <NotificationsPage />
      default: return <ErrorPage />
    }
  }

  return (
    <>
      <Seo
        title={"Seam Social"}
        description={"Create & Discover miniapps on Seam"}
        author={"@seam_xyz"}
        imageURL={"https://www.seam.so/seam-logo.png"}
        url={"https://www.seam.so/"}
      />
      {pageForType()}
    </>
  );
}
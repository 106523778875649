import React, { useEffect, useState } from "react";
import { Responsive as ResponsiveGridLayout } from "react-grid-layout";
import { withSize } from "react-sizeme";
import Parse from "parse";
import { useSeamNavigator } from "../Navigation/SeamNavigatorContext";
import { Typography } from "@mui/material";
import { LibraryAddOutlined } from '@mui/icons-material';
import SeamChannelRepository from '../Channels/SeamChannelRepository';
import { useSeamUser } from "../utils/SeamUserContext";
import CollectionItem from "./CollectionItem";

function CollectionsFeed({ size: { width }, creatorAccount, profileCustomizations, darkMode, itemLimit = 0}) {
  const [channels, setChannels] = useState([]);
  const { account, setRecentlyUpdatedChannel } = useSeamUser();
  const seamNavigator = useSeamNavigator();

  useEffect(() => {
    if (!creatorAccount) return;
    const query = new Parse.Query("Channel");
    query.equalTo("creator", creatorAccount);
    query.equalTo("isDeleted", false);
    query.descending("createdAt");
    if (itemLimit > 0) {
      query.limit(itemLimit);
    }
    query.find().then((results) => {
      setChannels(results);
    });
  }, [creatorAccount]);

  useEffect(() => {
    const handleCollectionCreated = (event) => {
      setChannels((prevChannels) => {
        const exists = prevChannels.some(channel => channel.id === event.collection.id);
        if (!exists) {
          return [event.collection, ...prevChannels];
        } else {
          return prevChannels.map(channel => channel.id === event.collection.id ? event.collection : channel);
        }
      });
    };

    const handleCollectionDeleted = (event) => {
      setChannels((prevChannels) =>
        prevChannels.filter((channel) => channel !== event.collection)
      );
    };

    window.emitter.on("SEAM_EVENT_CREATED_COLLECTION", handleCollectionCreated);
    window.emitter.on("SEAM_EVENT_DELETED_COLLECTION", handleCollectionDeleted);

    return () => {
      window.emitter.off("SEAM_EVENT_CREATED_COLLECTION", handleCollectionCreated);
      window.emitter.off("SEAM_EVENT_DELETED_COLLECTION", handleCollectionDeleted);
    };
  }, []);

  const bgGradientStart = profileCustomizations?.get("color1") || "#FFFFFF";
  const bgGradientEnd = profileCustomizations?.get("color2") || "#FFFFFF";
  const textColor = darkMode ? 'white' : 'black';
  const noBackgroundImage = !profileCustomizations?.get("backgroundImage");
  const defaultBackground = noBackgroundImage && bgGradientStart === "#FFFFFF" && bgGradientEnd === "#FFFFFF"

  const handleAddCollection = () => {
    const channel = SeamChannelRepository.emptyChannel({
      creator: creatorAccount,
      name: "New Collection",
      emoji: "😊",
      headerImage: "",
    })
    setRecentlyUpdatedChannel(channel);
    seamNavigator.navigateTo(`/collection/${channel?.id}`, channel);
  };

  const layout = [];

  if (account?.id === creatorAccount?.id) {
    layout.push({ i: "add", x: 0, y: 0, w: 1, h: 1 });
  }

  const startIndex = account?.id === creatorAccount?.id ? 1 : 0;

  channels.forEach((item, index) => {
    layout.push({
      i: index.toString(),
      x: (index + startIndex) % 2,
      y: Math.floor((index + startIndex) / 2),
      w: 1,
      h: 1,
    });
  });

  layout.push({ i: "empty", x: 0, y: Math.floor((channels.length + startIndex) / 2) + 1, w: 2, h: 1 });

  return (
    <div className="h-auto z-10 w-full flex mt-4 items-start justify-start"> 
      <ResponsiveGridLayout
        className="layout hide-scrollbar z-10 h-auto w-full"
        layouts={{ lg: layout }}
        breakpoints={{ lg: 768, xxs: 0 }}
        cols={{ lg: 2, xxs: 2 }}
        rowHeight={width / 1.9}
        width={width}
        isDraggable={false}
        isResizable={false}
        measureBeforeMount={true}
        margin={[32, 16]}
      >
        {account?.id === creatorAccount?.id && (
          <div
            key="add"
            onClick={handleAddCollection}
            className="widget z-10 relative cursor-pointer flex flex-col h-full rounded-[12px] overflow-hidden"
          >
            <div className={`absolute inset-0 flex flex-row items-center mb-[58px] space-x-2 rounded-[12px] justify-center`}>
              <LibraryAddOutlined className={`text-${textColor} h-4 w-4`} />
              <Typography variant="h3" className={`text-${textColor}`}>New Collection</Typography>
            </div>
            <div
              className={`border border-${textColor}`}
              style={{
                backgroundColor: defaultBackground ? null : textColor,
                opacity: defaultBackground ? 1 : 0.2,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                width: '100%',
                paddingTop: '100%',
                borderRadius: '12px',
                flexShrink: 0,
                boxSizing: 'border-box',
              }}
            >
              {/* The background image container */}
            </div>
          </div>
        )}
        {channels.map((channel, index) => {
          const itemKey = index.toString();

          return (
            <div key={itemKey}>
              <CollectionItem
                channel={channel}
                creatorAccount={creatorAccount}
                darkMode={darkMode}
                defaultBackground={defaultBackground}
              />
            </div>
          );
        })}

        {/* Empty Div for Bottom Space */}
        {itemLimit === 0 && <div key="empty" className="widget" style={{ width: '100%', height: '100%' }} />}

      </ResponsiveGridLayout>
    </div>
  );
}

export default withSize({ refreshMode: "debounce", refreshRate: 60 })(
  CollectionsFeed
);
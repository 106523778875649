import React, { useEffect, useState } from 'react';
import { useMobile } from '../utils/MobileContext';
import { useSeamUser } from '../utils/SeamUserContext';
import FlipNumbers from 'react-flip-numbers';
import SeamPink from '../assets/SeamPink.png';
import { Typography } from '@mui/material';

const SeamPointBanner = () => {
  const { account } = useSeamUser();
  const { isMobile, isMobileApp } = useMobile();
  const [seamPoints, setSeamPoints] = useState(0);
  const [greeting, setGreeting] = useState('Good morning');

  useEffect(() => {
    if (account) {
      const points = account.get("sp");
      setSeamPoints(points);
    }
  }, [account]);

  useEffect(() => {
    const hour = new Date().getHours();
    if (hour < 12) {
      setGreeting('Good morning');
    } else if (hour < 18) {
      setGreeting('Good afternoon');
    } else {
      setGreeting('Good evening');
    }
  }, []);

  return (
    <div className="w-full h-[170px] bg-seam-blue rounded-[20px] my-4">
      <div className="pt-4 pl-4 w-full h-auto items-start justify-between flex flex-col">
        <div className="mb-[48px]">
          <img src={SeamPink} className="max-w-[50px]"/>
          <Typography variant="h4" className="text-seam-white">Miniapp Marketplace</Typography>
        </div>
        <div className="w-full">
          <Typography variant="h4" className="text-seam-white/50">{greeting} {account.get("name")}!</Typography>
          <div className="flex flex-row text-end items-between justify-between text-center w-full h-auto">
            <Typography variant="h2" className="text-seam-white text-center">Seam Points</Typography>
            <div className="flex flex-row w-auto h-full space-x-2 mr-[32px]">
              <FlipNumbers
                height={20}
                width={20}
                numberClassName='text-seam-white flex items-center justify-center text-center'
                play
                delay={1}
                perspective={100}
                numbers={seamPoints.toString()}
              />
              <img className="h-[30px] w-[30px]" src="https://upcdn.io/W142hWW/raw/Seam%20Assets/Seam%20Point.png"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SeamPointBanner;
import React, { createContext, useState, useEffect, useContext, ReactNode } from 'react';
import { useIonRouter } from '@ionic/react';
import { Capacitor } from '@capacitor/core';
import mixpanel from 'mixpanel-browser';
import { FirebaseAnalytics } from "@capacitor-community/firebase-analytics";
import { useLocation } from 'react-router';

interface SeamNavigatorContextType {
  navigateTo: (path: string, context?: Parse.Object) => void;
  getNavigationPath: (path: string) => string;
  goBack: () => void;
  canGoBack: () => boolean;
  contextObject?: Parse.Object;
  referralCode?: string | null;
  setReferralCode: (code: string | null) => void;
}

interface SeamNavigatorProviderProps {
  children: ReactNode;
}

export const SeamNavigatorContext = createContext<SeamNavigatorContextType>({
  navigateTo: () => { },
  getNavigationPath: (path: string) => path,
  goBack: () => { },
  canGoBack: () => false,
  contextObject: undefined,
  referralCode: undefined,
  setReferralCode: () => {},
});

export const SeamNavigatorProvider: React.FC<SeamNavigatorProviderProps> = ({ children }) => {
  const platform = Capacitor.getPlatform();
  const isMobile = platform === "ios" || platform === "android";
  const router = useIonRouter();
  const location = useLocation();
  // when navigating to a place we already have the object for (post detail page, channels), we can store it so we don't need to fetch it again
  const [contextObject, setContextObject] = useState<Parse.Object | undefined>(undefined)
  const [referralCode, setReferralCode] = useState<string | null>(null);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const ref = params.get("ref");
    if (ref) {
      setReferralCode(ref);
    }
  }, [location.search]);
  
  // this is all needed so that mobile stays on its current tab when navigating
  const navigateTo = (path: string, context?: Parse.Object) => {
    setContextObject(context) 

    let navigationPath = getNavigationPath(path);
    if (referralCode) {
      const separator = navigationPath.includes('?') ? '&' : '?';
      navigationPath += `${separator}ref=${encodeURIComponent(referralCode)}`;
    }
  
    mixpanel.track("navigated", { location: navigationPath });
    FirebaseAnalytics.logEvent({ name: "navigated", params: { location: navigationPath } });
    router.push(navigationPath);
  };

  const getNavigationPath = (path: string) => {
    const slashedPath = path.startsWith("/") ? path : "/" + path;
    if (!isMobile) {
      return slashedPath;
    }

    const currentTab = getTab();
    const navigationPath = `${currentTab}${slashedPath}`
    return navigationPath;
  }

  const getTab = () => {
    let oldPath = router.routeInfo.pathname;
    const regex = /\/(\w+Tab)/; // matches "/homeTab" or "/inboxTab" or "/notificationsTab" or "/profileTab"
    let match = oldPath.match(regex);
    let currentTab = match ? match[0] : "";
    return currentTab
  }

const goBack = () => {
  if (canGoBack()) {
    router.goBack();
  }
}

  const canGoBack = () => {
    let oldPath = router.routeInfo.pathname;
    if (!isMobile) {
      return false; // Always false for non-mobile platforms
    }
    // Paths where 'go back' is not allowed
    const rootPaths = ["/homeTab", "/inboxTab", "/notificationsTab", "/profileTab", "/home"];
    return !rootPaths.includes(oldPath); // Returns true if it's not one of the root paths
  }

  return (
    <SeamNavigatorContext.Provider value={{
      navigateTo,
      getNavigationPath,
      goBack,
      canGoBack,
      contextObject,
      referralCode,
      setReferralCode,
    }}>
      {children}
    </SeamNavigatorContext.Provider>
  );
};

export const useSeamNavigator = () => useContext(SeamNavigatorContext);
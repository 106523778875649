export type BadgeType = {
  name: string
  displayTitle: string
  description: string
  imageURL: string
}

export type BadgeEarned = {
  date: Date
  type: string
}

export const BadgeTypes: { [key: string]: BadgeType } = {
  beta: {
    name: "beta",
    displayTitle: "Seam Beta",
    description: "Join Seam before March 2024",
    imageURL: "https://firebasestorage.googleapis.com/v0/b/seam-static-assets/o/betaBadge.png?alt=media",
  },
  miniappBuilder: {
    name: "miniappBuilder",
    displayTitle: "Miniapp Builder",
    description: "Build a Seam Miniapp",
    imageURL: "https://firebasestorage.googleapis.com/v0/b/seam-static-assets/o/builderBadge.png?alt=media",
  },
  exterminator: {
    name: "exterminator",
    displayTitle: "Bug Basher",
    description: "Report a bug",
    imageURL: "https://firebasestorage.googleapis.com/v0/b/seam-static-assets/o/bugBadge.png?alt=media",
  },
  og: {
    name: "og",
    displayTitle: "OG 🇺🇸🇨🇦",
    description: "Join Seam's iOS launch in the US and Canada",
    imageURL: "https://firebasestorage.googleapis.com/v0/b/seam-static-assets/o/ogBadge.png?alt=media",
  },
  powerUser: {
    name: "powerUser",
    displayTitle: "Power User",
    description: "Earn the recognition of the Seam team",
    imageURL: "https://firebasestorage.googleapis.com/v0/b/seam-static-assets/o/powerBadge.png?alt=media",
  },
  MiniappParticip_2024: {
    name: "miniappParticip_2024",
    displayTitle: "2024 Miniapp Challenge Participant",
    description: "Submitted a miniapp in Seam's summer 2024 Miniapp Challenge",
    imageURL: "https://firebasestorage.googleapis.com/v0/b/seam-static-assets/o/2024_miniapp_chal_particip.png?alt=media",
  },
  MiniappFirst_2024: {
    name: "miniappFirst_2024",
    displayTitle: "2024 Miniapp Challenge First Place",
    description: "Submitted a miniapp that won first place in Seam's summer 2024 Miniapp Challenge",
    imageURL: "https://firebasestorage.googleapis.com/v0/b/seam-static-assets/o/2024_miniapp_chal_first.png?alt=media",
  },
  MiniappSecond_2024: {
    name: "miniappSecond_2024",
    displayTitle: "2024 Miniapp Challenge Second Place", 
    description: "Submitted a miniapp that won second place in Seam's summer 2024 Miniapp Challenge",
    imageURL: "https://firebasestorage.googleapis.com/v0/b/seam-static-assets/o/2024_miniapp_chal_second.png?alt=media",
  },
  MiniappQuality_2024: {
    name: "miniappQuality_2024",
    displayTitle: "2024 Miniapp Challenge Best Quality",
    description: "Submitted a miniapp that won Best Quality in Seam's summer 2024 Miniapp Challenge",
    imageURL: "https://firebasestorage.googleapis.com/v0/b/seam-static-assets/o/2024_miniapp_chal_quality.png?alt=media",
  },
  MiniappDesign_2024: {
    name: "miniappDesign_2024",
    displayTitle: "2024 Miniapp Challenge Best Design", 
    description: "Submitted a miniapp that won Best Design in Seam's summer 2024 Miniapp Challenge",
    imageURL: "https://firebasestorage.googleapis.com/v0/b/seam-static-assets/o/2024_miniapp_chal_design.png?alt=media",
  },
  MiniappCreative_2024: {
    name: "miniappCreative_2024",
    displayTitle: "2024 Miniapp Challenge Most Creative", 
    description: "Submitted a miniapp that won Most Creative in Seam's summer 2024 Miniapp Challenge", 
    imageURL: "https://firebasestorage.googleapis.com/v0/b/seam-static-assets/o/2024_miniapp_chal_creative.png?alt=media",
  },
  SCADFirst_2024: {
    name: "scadFirst_2024",
    displayTitle: "2024 SCADPro x Seam First Place Winner",
    description: "Submitted designs for a new miniapp that won First Place in the SCADPro x Seam 2024 Challenge",
    imageURL: "https://firebasestorage.googleapis.com/v0/b/seam-static-assets/o/2024_SCAD_1stPlace.png?alt=media",
  },
  SCADSecond_2024: {
    name: "scadSecond_2024",
    displayTitle: "2024 SCADPro x Seam Second Place Winner",
    description: "Submitted designs for a new miniapp that won Second Place in the SCADPro x Seam 2024 Challenge",
    imageURL: "https://firebasestorage.googleapis.com/v0/b/seam-static-assets/o/2024_SCAD_2ndPlace.png?alt=media",
  },
  SCADThird_2024: {
    name: "scadThird_2024",
    displayTitle: "2024 SCADPro x Seam Third Place Winner",
    description: "Submitted designs for a new miniapp that won Third Place in the SCADPro x Seam 2024 Challenge",
    imageURL: "https://firebasestorage.googleapis.com/v0/b/seam-static-assets/o/2024_SCAD_3rdPlace.png?alt=media",
  },
  SCADViral_2024: {
    name: "scadViral_2024",
    displayTitle: "2024 SCADPro x Seam Most Likely to go Viral Winner",
    description: "Submitted designs for a new miniapp that won Most Likely to go Viral in the SCADPro x Seam 2024 Challenge",
    imageURL: "https://firebasestorage.googleapis.com/v0/b/seam-static-assets/o/2024_SCAD_Viral.png?alt=media",
  },
  SCADParticip_2024: {
    name: "scadParticip_2024",
    displayTitle: "2024 SCADPro x Seam Miniapp Design Challenge Participant",
    description: "Participated in the SCADPro x Seam 2024 Miniapp Design Challenge",
    imageURL: "https://firebasestorage.googleapis.com/v0/b/seam-static-assets/o/2024_SCAD_Participant.png?alt=media",
  },
};
import { IonPage, IonContent } from "@ionic/react";
import Header from '../Navigation/Header';
import DesktopSidebarWrapper from './DesktopSidebarWrapper';
import { useMobile } from '../utils/MobileContext';
import { useSeamNavigator } from '../Navigation/SeamNavigatorContext';
import { ChevronLeft } from 'react-feather';
import { useHistory } from 'react-router';

const ClaimGate = () => {
  const { isMobileApp } = useMobile();
  const seamNavigator = useSeamNavigator();
  const history = useHistory();
  const leftIcon = isMobileApp ? null : <ChevronLeft color="black" className="py-2 px-6 cursor-pointer" />;
  const leftAction = isMobileApp ? () => { } : () => history.go(-1);

  return (
    <IonPage>
      <IonContent fullscreen={true} scrollY={false} scrollX={false}>
        <Header
          isActivePage={true}
          leftIcon={leftIcon}
          rightIcon={null}
          leftAction={leftAction}
        />
        <DesktopSidebarWrapper>
          <div className="w-full h-full flex flex-row items-center justify-start">
            <div className="w-auto max-w-[530px] ml-[10%] h-auto flex flex-col text-start">
              <h1 className="py-2 text-seam-black text-start">404 Nothing to see here!</h1>
              <h3 className="py-2 text-seam-black text-start">We're sorry, the page you requested could not be found. Please go back to the home page.</h3>
              <div onClick={() => seamNavigator.navigateTo("/home")} className="w-full h-[44px] mt-4 bg-seam-blue rounded-[8px] py-2 flex items-center justify-center cursor-pointer">
                <h3 className="text-seam-white text-center">Back Home</h3>
              </div>
            </div>
          </div>
        </DesktopSidebarWrapper>
      </IonContent>
    </IonPage>
  )
}

export default ClaimGate;
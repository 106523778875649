import mixpanel from "mixpanel-browser";
import Parse from 'parse';

const sendFriendRequestPushNotification = (account, creatorAccount) => {
  Parse.Cloud.run("sendPushNotification", {
    notifiers: [creatorAccount.get("userId")],
    description: `${account.get("name")} has sent you a friend request.`,
    url: `/user/${account.get("profileId")}`
  }).then((message) => {
    // TODO: add to mobile badge counter
  }).catch((error) => {
    // Handle error if needed
  });
};

export const createFriendRequest = (account, creatorAccount, refreshAccount) => {
  const saveObject = async () => {
    const currentUser = Parse.User.current()
    if (currentUser === undefined) {
      alert("You must be logged in to request a connection!");
      return
    }

    if (account === undefined) {
      alert("You haven't created an account yet! Try logging out and logging back in again.");
      return
    }

    const PartialConnection = Parse.Object.extend("PartialConnection");
    const partialConnection = new PartialConnection();
    partialConnection.set("initiator", account)
    partialConnection.set("recipient", creatorAccount)

    const connectionACL = new Parse.ACL();
    connectionACL.setPublicReadAccess(true);
    // only the two users can change the friend status
    connectionACL.setWriteAccess(currentUser.id, true);
    connectionACL.setWriteAccess(creatorAccount.get("userId"), true);
    partialConnection.setACL(connectionACL);

    partialConnection.save().then(
      (newPartialConnection) => {
        mixpanel.track("friend requested")
        // optimistically follow all their channels
        Parse.Cloud.run("followAllChannelsForAccount", { account: account.id, accountToFollow: creatorAccount.id}).then(() => {
          refreshAccount();
        });
        // Send friend request push notification
        sendFriendRequestPushNotification(account, creatorAccount);
      },
      (error) => {
        alert("Failed to create connection: " + error.message);
      }
    );
  };
  saveObject();
}
import { Avatar, AvatarGroup } from '@mui/material';
import React, { useEffect, useState } from 'react';
import UnlockArrow from '../assets/icons/UnlockArrow.svg';
import { ArrowRight } from 'react-feather';
import Parse from 'parse';

function SeamComposerUnlockMiniAppStep({ block, cost, cancelAction, confirmAction }) {
  const [creatorProfilePhotos, setCreatorProfilePhotos] = useState([]);

  useEffect(() => {
    const fetchProfilePhoto = async (name) => {
      const Account = Parse.Object.extend("Account");
      const query = new Parse.Query(Account);
      query.equalTo("profileId", name);
      try {
        const account = await query.first();
        if (account) {
          setCreatorProfilePhotos(prevPhotos => [...prevPhotos, account.get("profilePhoto")]);
        }
      } catch (error) {
        console.error("Error fetching profile photo:", error);
      }
    };

    const devNames = block.createdBy.map((split) => split.username);
    devNames.forEach((name) => fetchProfilePhoto(name));
  }, [block.createdBy]);

  const createdBy = block.createdBy.map((split) => "@" + split.username).join(", ");

  return (
    <div style={{ height: 'calc(100% - 110px)' }} className="flex flex-col items-center justify-between bg-white p-4">
      <div className="flex flex-col items-center justify-center my-6">
        <div className="mb-6">
          <h3 style={{ fontWeight: 700 }} className="text-seam-black">🔒  Not in Library</h3>
        </div>
        <img src={block.icon} className="h-[94px]" />
        <h2 style={{ marginTop: '24px' }} className="text-3xl font-semibold">Unlock {block?.displayName}</h2>
        <h3 style={{ marginTop: '16px' }} className="text-gray-500 text-center">{block?.displayDescription}</h3>
        <p style={{ marginTop: '24px' }} className="text-gray-500">Created by: <span className="text-black">{createdBy}</span></p>
        <img src={UnlockArrow} className="" />
        <div className="mt-4 flex items-center bg-seam-green rounded-full space-x-2 px-4 py-2">
          <span className="flex flex-row items-center justify-center text-lg font-medium">{"-" + cost} <img className="w-[38px] h-[38px] ml-1" src="https://upcdn.io/W142hWW/raw/Seam%20Assets/Seam%20Point.png" /></span>
          <div className={`flex items-center justify-center w-6 h-6 rounded-full bg-black`}>
            <ArrowRight color="white" size={16} />
          </div>
          <AvatarGroup>
            {creatorProfilePhotos.map((photo, index) => (
              <Avatar key={index} src={photo} />
            ))}
          </AvatarGroup>
        </div>
        <p style={{ marginTop: '24px' }} className="text-gray-500 text-center">Unlocking miniapps sends Seam Points to the builder of the miniapp.</p>
        <div className="flex w-full h-[56px]">
          <button onClick={confirmAction} className="w-full bg-blue-600 text-white font-bold py-2 rounded">Send</button>
        </div>
      </div>
    </div>
  );
}

export default SeamComposerUnlockMiniAppStep;

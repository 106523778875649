import Parse from 'parse';
import { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Modal, Card, Button, Box, Typography } from "@mui/material";
import { Divider } from "@mui/material";
import ConnectionsFeed from "./Discover/ConnectionsFeed";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Colors } from "./utils/colors";
import CreateNewCardModal from "./CreateCardModal";
import CardList from './components/CardList';
import { useSeamUser } from './utils/SeamUserContext';

const useStyles = makeStyles((theme) => ({
  tabs: {
    '& .MuiTabs-indicator': {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: Colors.SEAM_GRAY_100,
    },
    '& .MuiTabs-indicatorSpan': {
      maxWidth: 40,
      width: '100%',
      backgroundColor: Colors.SEAM_GRAY_100,
    },
  },
  tab: {
    fontFamily: "Public Sans",
    fontWeight: 'bolder',
    color: Colors.SEAM_GRAY_600,
    '&.Mui-selected': {
      color: Colors.SEAM_GRAY_100,
    },
  },
  saveButton: {
    backgroundColor: Colors.SEAM_BLUE_400,
    fontFamily: "Public Sans",
    color: "#FEFEFE",
    fontSize: "16px",
    fontWeight: "400",
    textTransform: "none",
    height: "48px",
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: Colors.SEAM_BLUE_600,
      boxShadow: 'none',
      color: "#FEFEFE"
    },
  },
}));

interface MyStuffPageProps {

}

export default function MyStuffPage(
  { }: MyStuffPageProps
) {
  const user = Parse.User.current()
  const { account } = useSeamUser();
  const classes = useStyles();
  const breakpoint = 620
  const [currentAccountChannels, setCurrentAccountChannels] = useState<Parse.Object[]>([])
  const [currentTab, setCurrentTab] = useState(0);
  const [isCreatingNewCard, setCreatingNewCard] = useState(false);

  const Account = Parse.Object.extend("Account");
  Parse.Object.registerSubclass("Account", Account);
  const Channels = Parse.Object.extend("Channels");
  Parse.Object.registerSubclass("Channels", Channels);

  // Get all the account's channels
  useEffect(() => {
    if (account == undefined) { return }
    const query = new Parse.Query(Channels)
    query.equalTo("accountId", account)
    query.find().then((results) => {
      setCurrentAccountChannels(results)
    })
  }, [account]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  return (
    <Grid container spacing={2} padding={5} style={{ justifyContent: "center", height: 'calc(100%-64px', }}>
      <Box sx={{ maxWidth: '600px', minWidth: '300px', flexGrow: 1, maxHeight: 'calc(100% - 64px)' }}>
        <Divider/>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs className={classes.tabs} variant="fullWidth" value={currentTab} onChange={handleChange} aria-label="basic tabs example">
            <Tab className={classes.tab} label="Pages" {...a11yProps(0)} />
            <Tab className={classes.tab} label="Friends" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={currentTab} index={0}>
          {CardList(user?.id)}
          <Button
          style={{
            backgroundColor: 'black',
            color: 'white',
            borderRadius: '24px',
            width: '100%',
            maxWidth: '100%',
            height: 'auto',
            fontFamily: 'Public Sans',
          }}
          onClick={() => setCreatingNewCard(true)}>
          Create New Page
        </Button>
        </TabPanel>
        <TabPanel value={currentTab} index={1}>
          <ConnectionsFeed includeGroupAdd={false} channels={currentAccountChannels} creatorAccount={account}/>
        </TabPanel>
        <div style={{ display: 'flex', justifyContent: 'center', padding: 8 }}>
      </div>
      </Box>
      {renderNewCardModal(isCreatingNewCard, setCreatingNewCard)}
    </Grid>
  )
}

function renderNewCardModal(isCreatingNewCard: boolean, setCreatingNewCard: React.Dispatch<React.SetStateAction<boolean>>) {
  return (
    <Modal
      open={isCreatingNewCard}
      onClose={() => setCreatingNewCard(false)}
      style={{ fontSize: "16px", fontWeight: "500" }}
    >
      <Card
        style={{
          marginTop: "10px",
          borderRadius: "1rem",
          padding: "15px",
          width: "700px",
          flex: "column",
          position: 'absolute',
          top: '10%',
          left: '50%',
          transform: 'translate(-50%, 0)',
        }}
      >
        <CreateNewCardModal title={"Create New Card"} closeModal={() => setCreatingNewCard(false)} />
      </Card>
    </Modal>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0, pb: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
import { IonHeader } from "@ionic/react";
import SeamComposer from "../Composer/SeamComposer";
import { ChevronLeft, Share as ShareIcon } from "react-feather";
import { Share } from '@capacitor/share';
import SeamPageThemeModal from "./SeamPageThemeModal";
import { useSwiper } from 'swiper/react';
import { useEffect, useState } from "react";
import { useMobile } from "../utils/MobileContext";
import { Avatar } from "@mui/material";
import { Smartphone, Monitor } from "react-feather";
import { useHistory } from "react-router";
import { useSeamNavigator } from "./SeamNavigatorContext";

export default function SeamPageEditHeader({
  hasWriteAccess,
  currentCard,
  creatorAccount,
  isInEditMode,
  isActive
}) {
  const { isMobile, isMobileApp } = useMobile();
  const [mode, setMode] = useState('desktop'); 
  const history = useHistory();
  const seamNavigator = useSeamNavigator();

  useEffect(() => {
    if (!swiper || !creatorAccount) return;
    window.emitter.on("SEAM_EVENT_TOGGLE_MODE", (mode) => {
      if (mode === "edit") {
        swiper.allowTouchMove = false
      } else {
        swiper.allowTouchMove = true
      }
    });
  }, []);

  const swiper = useSwiper();

  async function shareSheetForPage() {
    await Share.share({
      title: 'See cool stuff',
      url: 'https://seam.so/' + currentCard?.get("name"),
      dialogTitle: 'Share with buddies',
    });
  }

  const deleteCurrentCard = () => {
    if (window.confirm("Do you really want to delete this page? This action cannot be undone.")) {
      currentCard.destroy().then(
        () => {
          alert("Page deleted successfully.");
          seamNavigator.navigateTo("/home"); 
        },
        (error) => {
          alert("Failed to delete the page: " + error.message);
        }
      );
    }
  }

  const composerButton = (
    <div className="flex justify-center items-center gap-2 px-6 text-white rounded-full bg-[#ea3bf7] w-6 cursor-pointer">
      {isActive && <SeamComposer
        composerTitle={"Add a block to " + currentCard?.get('title')}
        communityName={currentCard?.get('title')}
        communityId={currentCard?.get("name")}
        currentCard={currentCard}
        completion={() => {
          window.emitter.emit("SEAM_EVENT_TOGGLE_MODE", "edit")
        }}
        postingMiniappsOnly={false}
      />}
    </div>
  )

  useEffect(() => {
    const handleModeToggle = (mode) => {
      if (mode === "edit") {
        swiper.allowTouchMove = false;
      } else {
        swiper.allowTouchMove = true;
      }
      window.emitter.on("SEAM_EVENT_TOGGLE_MODE", handleModeToggle);
      return () => window.emitter.off("SEAM_EVENT_TOGGLE_MODE", handleModeToggle);
    }
  }, [swiper]);

  const toggleMode = () => {
    const newMode = mode === 'desktop' ? 'mobile' : 'desktop';
    setMode(newMode);
    window.emitter.emit("SEAM_EVENT_TOGGLE_MOBILE", newMode);
  };

  const iconStyle = "h-5 w-5 transition-transform duration-300";
  const activeIconStyle = "scale-150 text-seam-pink";
  const inactiveIconStyle = "text-seam-black";

  const ModeSwitch = () => (
    <button
      onClick={toggleMode}
      className="flex items-center bg-[#efefef] rounded-full p-1"
    >
      <div className={`flex justify-center items-center w-full space-x-4 px-4`}>
        <Monitor className={`${iconStyle} ${mode === 'desktop' ? activeIconStyle : inactiveIconStyle}`} />
        <Smartphone className={`${iconStyle} ${mode === 'mobile' ? activeIconStyle : inactiveIconStyle}`} />
      </div>
    </button>
  );
  
  const modeButton = <ModeSwitch mode={mode} setMode={setMode} />;

  const editModeHeader = (
    <div className="flex items-center justify-between bg-clear p-4 max-w-screen mx-auto">
      <div className="flex">
        <div onClick={() => { window.emitter.emit("SEAM_EVENT_PUBLISH", {}) }} className="flex justify-center items-center gap-2 py-2 px-4 text-black rounded-full bg-seam-green mr-2 cursor-pointer">
          Save
        </div>
        <div onClick={() => {
          window.emitter.emit("SEAM_EVENT_CLEAR_PAGE_EDITS", null)
          window.emitter.emit("SEAM_EVENT_TOGGLE_MODE", "view")
        }}
          className="flex justify-center items-center gap-2 px-4 text-black rounded-full bg-[#efefef] cursor-pointer">
          Discard
        </div>
      </div>

      {!isMobile && 
        <div className="">
          <div className="flex flex-row px-6 py-4 h-full w-full items-center justify-center rounded-[8px] bg-seam-green">
            <h3 className="text-center font-bold text-seam-black">
              Edit Mode
            </h3>
          </div>
        </div>}

      <div className="flex space-x-2">
        {!isMobile && modeButton} 
        <SeamPageThemeModal currentCard={currentCard} deleteCurrentCard={deleteCurrentCard}/>
        {composerButton}
      </div>
    </div>
  )

  const pageTitle = (
    isMobile ?
      <h4 onClick={() => seamNavigator.navigateTo("/user/" + creatorAccount.get("profileId"), creatorAccount)} className="text-center font-bold text-seam-white whitespace-nowrap overflow-hidden text-ellipsis mx-4 cursor-pointer" style={{ flexGrow: 1, flexShrink: 1, minWidth: 'min-content', textAlign: 'center', maxWidth: '30vw' }}>
        {"@" + creatorAccount?.get("profileId")}
      </h4>
      : <div onClick={() => seamNavigator.navigateTo("/user/" + creatorAccount.get("profileId", creatorAccount))} className={`flex flex-row px-6 py-4 h-full w-auto items-center justify-center rounded-[8px] bg-white cursor-pointer`}>
        <Avatar src={creatorAccount.get("profilePhoto")} alt="Profile Picture" className="w-[28px] h-[28px] mr-2" />
        <h3 className="text-start font-bold text-seam-black whitespace-nowrap overflow-hidden text-ellipsis mx-4" style={{ flexShrink: 1, minWidth: 'min-content', maxWidth: '30vw' }}>
          {"@" + creatorAccount?.get("profileId")}
        </h3>
      </div>
  )

  const viewModeHeader = (
    <div className="flex items-center justify-between bg-clear p-4 max-w-screen mx-auto">
      <div className="flex">
        {isMobileApp &&
          <div onClick={() => { swiper.slidePrev() }} className="flex justify-center items-center gap-2 py-2 px-6 text-white rounded-full bg-[#efefef] mr-2">
            <ChevronLeft color="black" />
          </div>
        }
        {hasWriteAccess &&
          <div onClick={() => {
            window.emitter.emit("SEAM_EVENT_TOGGLE_MODE", "edit")
          }}
            className="flex justify-center items-center gap-2 px-4 py-2 text-black rounded-full bg-[#efefef] cursor-pointer">
            Edit
          </div>
        }
      </div>

      {pageTitle}

      <div className="flex">
        {isMobileApp && 
          <div onClick={() => { shareSheetForPage() }} className="flex justify-center items-center gap-2 py-2 px-2 text-white rounded-full bg-[#efefef] mr-2">
            <ShareIcon color="black" />
          </div>
        }
        {hasWriteAccess && composerButton}
      </div>
    </div>
  );

  return (
    <div className="relative w-full">
      <IonHeader className="w-full border-b-[2px] border-seam-black/[5%] ion-no-border">
        {isInEditMode ? editModeHeader : viewModeHeader}
      </IonHeader>
    </div>
  );
}

import { makeStyles } from '@mui/styles';
import { List, ListItem, ListItemAvatar, Avatar, ListItemText, IconButton } from '@mui/material';
import { getProcessedProfilePictureURL } from './helpers/ImageTransformers';
import { useSeamNavigator } from '../Navigation/SeamNavigatorContext';
import GroupBanner from '../assets/GroupBanner.svg'
import { formatPostDate } from '../utils/formatPostDate';
import InfiniteScroll from 'react-infinite-scroll-component';

const useStyles = makeStyles({
  groupItem: {
    width: '95%',
    fontSize: '16px',
    fontFamily: "Public Sans",
    display: 'flex',
    alignItems: 'center',
    paddingInline: 0,
    '&:hover': {
      cursor: 'pointer',
    }
  },
  groupList: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
});

const GroupsList = ({ groups, groupMemberDetails }) => {
  const seamNavigator = useSeamNavigator();

  const tapGroup = (group) => {
    const groupDetails = groupMemberDetails[group.id];
    if (groupDetails && groupDetails.name !== "Unknown User") {
      seamNavigator.navigateTo("space/" + group.id);
    } else {
      console.log("User not found, navigation cancelled.")
    }
  }

  const defaultImage = GroupBanner;

  return (
    <InfiniteScroll
    dataLength={groups.length}
    next={() => { }}
    //style={{ display: 'flex', flexDirection: 'column-reverse' }}
    hasMore={false}
    loader={<h4>Loading...</h4>}
    height={window.innerHeight - 200}
    >
      {groups.map((group, index) => (
        <li
          key={index}
          className="w-full mb-4 flex items-start cursor-pointer hover:bg-gray-100 rounded-md"
          onClick={() => tapGroup(group)}
        >
          <img
            className="h-[72px] min-w-[130px] w-[130px] mr-3 rounded-[8px] object-cover"
            src={group.get("headerPhoto") || defaultImage}
            alt={`${groupMemberDetails[group.id]?.name || "Unknown User"}'s group image`}
          />
          <div className="w-full flex flex-col">
            <span className="text-lg font-sans font-medium w-full min-w-0">
              <p className="truncate text-clip m-0 w-full min-w-0">
                {`${groupMemberDetails[group.id]?.name || "Unknown User"}`}
              </p>
            </span>
              <span className="text-sm font-sans text-gray-600">
                {`${formatPostDate(group.get("updatedAt"))}`}
              </span>
              <span className="text-sm font-sans">
                {`${group.get("Members").length} Members`}
              </span>
          </div>
        </li>
      ))}
      <div style={{ height: 100 }} />
    </InfiniteScroll>
  )
}

export default GroupsList;

import { makeStyles } from "@mui/styles";
import { useTheme } from '@mui/material/styles';
import Card from "@mui/material/Card";
import { Edit2, Trash2 } from 'react-feather';
import LazyLoad from 'react-lazyload';
import { Skeleton } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  body: {
    width: "100%",
    height: "100%",
    flexGrow: 1,
  }
}));

export default function Widget(props) {
  const { height, id, onRemoveItem, onEditItem, isInEditMode, blockType, blockComponent } = props
  const theme = useTheme();
  const classes = useStyles();

  const displayBar = isInEditMode
  const editableBlock = blockType !== "internal: connections" && blockType !== "internal: cards"

  return (
    <Card key={id} className={classes.root} style={{ boxShadow: theme.blockOptions.dropShadow, borderRadius: theme.blockOptions.cornerRadius, border: theme.blockOptions.border }}>
      {displayBar &&
        <div className="flex flex-row space-x-2">
          {editableBlock &&
            <div className="flex justify-center items-center p-2 rounded-full border border-light-gray bg-white"
              onClick={() => onEditItem(id)} onTouchStart={() => onEditItem(id)}>
              <Edit2 color="black" size={16} />
            </div>}
          <div className="flex justify-center items-center p-2 rounded-full border border-light-gray bg-white"
            onClick={() => onRemoveItem(id)} onTouchStart={() => onRemoveItem(id)}>
            <Trash2 color="black" size={16} />
          </div>
        </div>
      }
      <LazyLoad className={classes.body}
        overflow={true}
        height={height}
        offset={200} once
        placeholder={<Skeleton variant="rectangular" />}>
        {blockComponent}
      </LazyLoad>
    </Card>
  );
}
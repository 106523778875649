import React, { useEffect, useState } from 'react';
import Parse from 'parse';
import { Avatar, Typography } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import animatedLogo from './assets/Seam_Anim_03_PINK.json';
import logo from './assets/logo.svg';
import mixpanel from 'mixpanel-browser';
import OnboardingTutorial from './OnboardingTutorial';
import { useSeamUser } from './utils/SeamUserContext';
import { IonContent, IonPage } from '@ionic/react';
import { loadFont } from './Block-SDK/src/blocks/utils/Fonts';
import { South } from '@mui/icons-material';
import CollectionsFeed from './Profile/CollectionsFeed';
import { getProcessedProfilePictureURL } from './components/helpers/ImageTransformers';
import { useMobile } from './utils/MobileContext';
import { useSeamNavigator } from './Navigation/SeamNavigatorContext';
import MobileOnboardingTutorial from './MobileOnboarding/MobileOnboardingTutorial';
import { Img } from 'react-image';

export default function LandingPage() {
  const { account, isLoadingAccount, isNewUser } = useSeamUser();
  const history = useHistory();
  const {isMobile, isMobileApp, isMobileWeb} = useMobile();
  const [isLoading, setIsLoading] = useState(false);
  const [fontLoaded, setFontLoaded] = useState(false);
  const [referrer, setReferrer] = useState<Parse.Object | null>(null);
  const seamNavigator = useSeamNavigator();
  const location = useLocation();
  const [accounts, setAccounts] = useState<Parse.Object<Parse.Attributes>[]> ([]);
  // account object ids for featured profiles
  const objectIds = ["4rLij9f29Z", "H0mdhpm1neXSW7QKNhIDYBeP", "8eoo0zLhWv"];
  const channelPics = [
    "https://firebasestorage.googleapis.com/v0/b/seam-social.appspot.com/o/channelHeader%2FNKgNIdt28ceaeXutusjvH_512x512?alt=media&token=cc372b30-a6d4-4978-9053-43b75c1d67f5", 
    "https://firebasestorage.googleapis.com/v0/b/seam-static-assets/o/Rectangle_6052.png?alt=media", 
    "https://firebasestorage.googleapis.com:443/v0/b/seam-social.appspot.com/o/profilePhotos%2FbSe40pz6p9QAOZVQGWL5F?alt=media&token=e7fdd3f8-7aaf-4433-855a-6f12b55fa519", 
    "https://firebasestorage.googleapis.com/v0/b/seam-social.appspot.com/o/profilePhotos%2FLpv1h-peY0PaRqfRlll8j?alt=media&token=699030cb-45e4-4b01-b918-470e38537a52", 
    "https://firebasestorage.googleapis.com/v0/b/seam-social.appspot.com/o/profilePhotos%2FufduHO0t177JPj485Oo1M?alt=media&token=d78f0493-a601-41f0-a278-52003c670c49", 
  ]
  let header = (
    <>
    CREATE &<br />
    CUSTOMIZE<br />
    PROFILES<br />
    </>
  )

  // Get the referral code, and if necessary, find the name of the referrer to customize the CTA
  const params = new URLSearchParams(location.search);
  const referredBy = params.get("ref");
  const hasReferral = referredBy != null

  useEffect(() => {
    if (hasReferral) {
      const query = new Parse.Query("Account");
      query.equalTo("referralCode", referredBy);
      query.first().then((result) => {
        if (result != undefined) {
          setReferrer(result);
          setAccounts(prevAccounts => {
            const filteredAccounts = prevAccounts.filter(account => account.id !== result.id);
            return [result, ...filteredAccounts];
          });
        }
      });
    }
  }, [hasReferral, referredBy]);

  useEffect(() => {
    loadFont("Silkscreen").then(() => {
      setFontLoaded(true);
    })
  }, []);

  useEffect(() => {
    window.emitter.on('SEAM_EVENT_IS_LOADING', (loading) => {
      setIsLoading(loading);
    });

    mixpanel.track('iOS Landing Page Viewed');
  }, []);

  useEffect(() => {
    if (account && Parse.User.current()) {
      history.replace(`/user/${account.get("profileId")}`);
    }
  }, [account, history]);

  useEffect(() => {
    const Account = Parse.Object.extend("Account");
    const query = new Parse.Query(Account);
    query.containedIn("objectId", objectIds);
    query.include("profilePhoto");
    query.find()
    .then((results) => {
      if (referrer) {
        // Remove referrer if it's already in the results
        const filteredResults = results.filter(account => account.id !== referrer.id);
        setAccounts([referrer, ...filteredResults]);
      } else {
        setAccounts(results);
      }
    })
      .catch((error) => {
        console.error('Error fetching accounts:', error);
      });
  }, [referrer]);

  if (isNewUser)  {
    return <OnboardingTutorial referredBy={referrer} />;
  }

  if (!fontLoaded) {
    return null; 
  }

  return (
    <IonPage>
      <IonContent className="hide-scrollbar" fullscreen={true} scrollY={true}>
        <div className="flex flex-col w-auto h-full items-center hide-scrollbar">
          <div
            className="flex items-center justify-center w-full h-auto px-6 pt-[24px]"
          >
            <img src={logo} className="h-[44px]" alt="Logo" />
          </div>
          <div className={`mb-[32px] h-auto flex flex-col items-center justify-start ${isMobile ? 'mt-4 w-auto' : 'mt-[46px]'}`}>
            <h1
              className="mb-4 w-full"
              style={{
                fontFamily: 'Silkscreen, sans- dserif',
                background: 'linear-gradient(124deg, #1500FF 3.96%, #ED50BE 49.19%, #FFC800 91.78%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                backgroundClip: 'text',
                color: 'transparent',
                textAlign: 'center',
                lineHeight: '1.2',
                fontSize: isMobile ? '3rem' : '70px',
              }}
            >
              {header}
            </h1>  
            <Typography variant={isMobile ? 'h3' : 'h2'} className="text-center text-seam-black py-4">Make microblogs to find friends in your fandoms</Typography>   
            <div className="flex py-2 flex-row items-center h-auto w-auto justify-center space-x-[-10px]">
              <Img loader={<div className="imageSkeleton my-6 h-[80px] w-[80px] rotate-[-20deg] bg-gray-400 rounded-[4px]" />} src={channelPics[0]} className="my-6 h-[80px] w-[80px] rotate-[-20deg] bg-gray-400 rounded-[4px]"></Img>
              <Img loader={<div className="imageSkeleton my-6 h-[80px] w-[80px] rotate-[20deg] rounded-[4px]" />} src={channelPics[1]} className="my-6 h-[100px] w-[100px] rounded-[4px]"></Img>
              <Img loader={<div className="imageSkeleton my-6 h-[80px] w-[80px] rotate-[20deg] bg-gray-400 rounded-[4px]" />} src={channelPics[2]} className="my-6 h-[80px] w-[80px] rotate-[20deg] bg-gray-400 rounded-[4px]"></Img>
              <Img loader={<div className="imageSkeleton my-6 h-[80px] w-[80px] rotate-[-20deg] bg-gray-400 rounded-[4px]" />} src={channelPics[3]} className="my-6 h-[80px] w-[80px] rotate-[-20deg] bg-gray-400 rounded-[4px]"></Img>
              <Img loader={<div className="imageSkeleton my-6 h-[80px] w-[80px] rotate-[-20deg] bg-gray-400 rounded-[4px]" />} src={channelPics[4]} className="my-6 h-[80px] w-[80px] rotate-[-20deg] bg-gray-400 rounded-[4px]"></Img>
            </div>
            <div className="my-4 flex flex-row items-center justify-center w-full h-auto">
              {referrer ? (
                <div onClick={() => seamNavigator.navigateTo(`user/${referrer.get('profileId')}`)} className="flex w-auto h-auto px-3 py-2 items-center justify-center rounded-[28px] border border-black/[20%] cursor-pointer">
                  <Typography variant="h3" className="text-center text-[#86868A]">{isMobileApp ? 'Swipe' : 'Scroll'} to explore what&nbsp;</Typography>
                  <Avatar className="h-[22px] w-[22px] self-center" src={referrer.get("profilePhoto")} />
                  <Typography variant="h3" className="text-seam-blue text-center truncate">&nbsp;{referrer.get("profileId")}</Typography>
                  <Typography variant="h3" className="text-center text-[#86868A]">&nbsp;collects</Typography>
                </div>
              ) : (
                <div className="flex w-auto h-auto px-3 py-2 items-center justify-center rounded-[28px] border border-black/[20%]">
                  <Typography variant="h3" className="text-center text-[#86868A]">{isMobileApp ? 'Swipe' : 'Scroll'} to explore what's&nbsp;</Typography>
                  <Typography variant="h3" className="text-center text-[#0051E8]">Trending on Seam</Typography>
                </div>
              )            
              }
            </div>
            <South className="text-[#FFCB3F] h-[68px] w-auto place-self-center"/>
            <div className="w-full h-full mt-[28px] hide-scrollbar flex flex-col items-center justify-center pb-[48px]">
              {accounts.map((creatorAccount) => {
                const profilePhoto = creatorAccount.get("profilePhoto");
                const profilePhotoUrl = getProcessedProfilePictureURL(profilePhoto);
                  
                return (
                  <div className="mb-[32px] w-full max-w-[640px]" key={creatorAccount.id}>
                    <div onClick={() => seamNavigator.navigateTo(`user/${creatorAccount.get('profileId')}`)} className="flex flex-row items-center justify-center w-auto h-auto space-x-1 mx-[30px] cursor-pointer">
                      <Typography variant="h3" className="text-black/[40%] py-[10px] text-center truncate">by:&nbsp;</Typography>
                      <Avatar className="h-[22px] w-[22px] self-center" src={profilePhotoUrl} />
                      <Typography variant="h3" className="text-seam-blue text-center truncate">{creatorAccount.get("profileId")}</Typography>
                    </div>
                    <CollectionsFeed key={creatorAccount.id} creatorAccount={creatorAccount} profileCustomizations={undefined} darkMode={undefined} itemLimit={4} />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
}
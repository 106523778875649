import { makeStyles } from "@mui/styles";
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import { Colors } from "../utils/colors"
import { Avatar } from "@mui/material";

const useStyles = makeStyles({
  cardTitle: {
    fontFamily: "Public Sans",
    fontWeight: 700,
    color: 'black',
  },
  cardSubtitle: {
    textAlign: "left",
    maxWidth: '100%',
    fontSize: 14,
    fontWeight: 300,
    color: 'black',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  listItemText: {
    maxWidth: 'calc(100% - 60px)',
    overflow: 'hidden',
  },
  cardItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    width: "95%",
    fontSize: "16px",
    color: Colors.SEAM_GRAY_100,
    cursor: "pointer",
    backgroundColor: "#EFEFEF",
    borderRadius: 24,
    marginBottom: '16px',
    marginLeft: '8px',
    marginRight: '8px',
    display: 'flex',
    alignItems: 'center',
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1)",
    border: '1px solid rgba(0, 0, 0, 0.1)',
    '&:hover': {
      backgroundColor: "#DADADA",
      boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)", 
    }
  },
  thumbnailBar: {
    width: "100%",
    height: "8px",
    display: "flex"
  },
  itemBackground: {
    marginTop: 16,
    backgroundColor: "white"
  }
});

export default function ProfileItem(props) {
  const { account, index, tapAction } = props
  const classes = useStyles();

  function randomColor() {
    var keys = Object.keys(Colors);
    return Colors[keys[keys.length * Math.random() << 0]];
  }

  const getAvatarUrl = (account) => {
    const profilePhoto = account.get("profilePhoto");
    return profilePhoto;
  };

  return (
    <div className={classes.itemBackground} key={index}>
      <ListItem
        alignItems="flex-start"
        className={classes.cardItem}
        onClick={() => {
          tapAction(account, index)
        }}
      >
        <Avatar
          src={`${getAvatarUrl(account)}?w=64&h=64&fit=crop&auto=format`}
          style={{ height: 48, width: 48, marginRight: '12px',}}
        />
        <ListItemText
          classes={{ primary: classes.cardTitle, secondary: classes.cardSubtitle }}
          primary={account.get("profileId") ?? account.get("name")}
          secondary={account.get("bio") ?? "No description"} 
          className={classes.listItemText}
          />
      </ListItem>
    </div>
  )
}
import React, { useEffect, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import Parse from 'parse';
import SeamPointBanner from './components/SeamPointBanner';
import { formatPostDate } from './utils/formatPostDate';
import { ArrowRight, ArrowLeft } from "react-feather";
import { useMobile } from './utils/MobileContext';
import { Avatar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSeamNavigator } from './Navigation/SeamNavigatorContext';
import { LoadingSkeleton } from './components/LoadingSkeleton';

const useStyles = makeStyles({
  noScrollBar: {
    "&::-webkit-scrollbar": {
      display: "none"
    },
    "-ms-overflow-style": "none", /* IE and Edge */
    "scrollbar-width": "none", /* Firefox */
  },
});

const Transactions = () => {
  const [transactions, setTransactions] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { isMobile, isMobileApp } = useMobile();
  const classes = useStyles();
  const seamNavigator = useSeamNavigator();

  const fetchTransactions = async () => {
    const query = new Parse.Query("PointTransaction");
    query.include("account");
    query.include("recipient");
    query.limit(50);
    query.descending("createdAt");

    try {
      const results = await query.find();
      setTransactions(results);
    } catch (error) {
      console.error("Error fetching transactions:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTransactions();
  }, []);

  const Header = ({ context }: any) => {
    return (
      <div className="mt-4 px-4">
        <SeamPointBanner />
      </div>
    )
  }

  const Footer = ({ context }: any) => {
    const { isLoading, isMobileApp } = context;
    return (
      isLoading ? (
        <div className={`w-full h-full ${!isMobileApp && "max-w-[720px] flex flex-col justify-center mx-auto"}`}>
          {LoadingSkeleton()}
        </div>
      ) : null
    );
  };

  const context = { isMobile, isMobileApp, isLoading }

  return (
    <div className={`w-full h-full flex flex-col items-center justify-start ${classes.noScrollBar}`}>
      <Virtuoso
        style={{ height: window.innerHeight - 132, width: '100%' }}
        className={classes.noScrollBar}
        data={transactions}
        itemContent={(index: number, transaction: any) => {
          const account = transaction.get("account");
          const recipient = transaction.get("recipient");
          const delta = transaction.get("delta");
          const reason = transaction.get("reason");
          const createdAt = transaction.get("createdAt");
          const isReceived = delta > 0;

          const recipientProfilePhoto = recipient?.get("profilePhoto") || "https://upcdn.io/W142hWW/raw/uploads/2023/11/20/seam%20logo%20blue-5ohK.jpeg";
          const recipientProfileId = recipient?.get("profileId") || "seam";

          if (isLoading) {
            <div className={`w-full h-full flex flex-col`}>
              {LoadingSkeleton()}
            </div>
          }
          else if (transactions.length === 0) {
            return (
              <div className="px-6 mx-6 flex flex-col items-center justify-start w-full max-w-[400px] h-full space-y-4 my-6">
                <div className="flex justify-center items-center rounded-full bg-[#efefef] p-6 border-2 border-seam-black/[5%]">
                  <h1 className="">🔔</h1>
                </div>
                <h2 className="text-seam-black text-center">No Transactions</h2>
                <h3 className="text-seam-black/[33%] text-center">Transactions will show up here.</h3>
              </div>
            );
          } else {
            return (
              <div className="flex items-center px-4 py-4 border-b border-gray-500/20">
                <div className="flex items-center h-[40px]">
                  <div className={`flex items-center justify-center w-[40px] h-[40px] rounded-full ${isReceived ? 'bg-seam-green' : 'bg-black'}`}>
                    {isReceived ? (
                      <ArrowLeft color="black" size={24} />
                    ) : (
                      <ArrowRight color="white" size={24} />
                    )}
                  </div>
                  <Avatar
                    src={recipientProfilePhoto}
                    alt="Profile"
                    className="w-[40px] h-[40px] ml-2"
                  />
                </div>
                <div className="ml-4 flex-1">
                  <div className="flex items-center">
                    <div>
                      <div className={`text-seam-black w-full leading-[16px] font-normal`}>
                        <span className={`${isMobileApp ? 'text-[12px] leading-[16px]' : 'text-[16px] leading-[24px]'} flex w-full rounded`}>
                          {isReceived ? 
                          `You got` : 
                          `You sent`}
                          &nbsp;
                          {isReceived ? `+${delta}` : `${delta}`} 
                          &nbsp;
                          <img className={`${isMobileApp ? 'h-[16px] w-[16px]' : 'h-[24px] w-[24px]'}`} src="https://upcdn.io/W142hWW/raw/Seam%20Assets/Seam%20Point.png"/>
                          &nbsp;
                          {isReceived ? `from` : `to`}
                          &nbsp;
                          <div className="cursor-pointer hover:underline" onClick={() => {seamNavigator.navigateTo(`user/${recipientProfileId}`, recipient)}}>
                            @{recipientProfileId}
                          </div>
                        </span>
                      </div>
                      <div className={`text-gray-500 ${isMobileApp ? 'text-[12px] leading-[16px]' : 'text-[16px] leading-[24px]'}`}>{reason}</div>
                      <div className={`text-gray-400 ${isMobileApp ? 'text-[12px] leading-[16px]' : 'text-[16px] leading-[24px]'}`}>{formatPostDate(createdAt)}</div>
                    </div>
                  </div>
                </div>
              </div>
            );
          }
        }}
        components={{
          Header,
          Footer,
        }}
        context={context}
      />
    </div>
  );
};

export default Transactions;
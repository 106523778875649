import { useRef } from "react";
import { Avatar, Stack, Typography } from "@mui/material";
import UiKitLinkify from "../../Comment/Linkify";
import BlockFactory from '../../Block-SDK/src/blocks/BlockFactory';
import FeedItemOptions from "../../Discover/FeedItemOptions";
import { formatPostDate } from "../../utils/formatPostDate";
import SeamUserItem from "../../components/SeamUserItem";
import { useSeamNavigator } from "../../Navigation/SeamNavigatorContext";
import * as Sentry from "@sentry/react";
import mixpanel from "mixpanel-browser";
import { useSeamUser } from "../../utils/SeamUserContext";
import { BlockTypes } from "../../Block-SDK/src/blocks/types";
import Parse from 'parse';
import FeedErrorBoundary from "../../components/FeedErrorBoundary";
import { getProcessedProfilePictureURL } from "../../components/helpers/ImageTransformers";

export default function SeamUserFeedItem(props) {
  const { post, collection, postInCollection, isOnPostDetailPage, isOnChannelPage, takingScreenshot = false, darkMode } = props;
  const seamNavigator = useSeamNavigator();
  const postData = post.get("postData");
  const { account } = useSeamUser();
  const ownerAccount = post?.get("author");
  const displayName = collection?.get("emoji") + " " + collection?.get("name");
  const authorAccount = post?.get("author");
  const ref = useRef(null);
  const collector = postInCollection?.get('collector');
  const isSelfCollected = isOnPostDetailPage || !collector || (collector?.get("profileId") === ownerAccount?.get("profileId"));
  const textColor = darkMode ? 'white' : 'black';
  const blueTextColor = darkMode ? 'text-seam-dark-blue' : 'text-seam-blue';

  let blockType, printableBlockName;
  if (postData.blockData !== undefined) {
    blockType = postData.blockData.type;
    printableBlockName = BlockFactory.getPrintableBlockName(postData.blockData);
  } else {
    blockType = "Text";
    printableBlockName = "Text";
  }

  const update = (data) => {
    Parse.Cloud.run("updatePostModel", { postId: post.id, updatedData: data })
  }

  const blockData = postData.blockData;

  const SeamItemHeader = () => {
    const user = isSelfCollected ? authorAccount : (collector || authorAccount);
    const actionString = isSelfCollected ? 'posted' : 'collected';
    const processedAvatar = user && getProcessedProfilePictureURL(user.get("profilePhoto"));

    const onSubtitleClick = () => {
      const isMiniappOwned = account?.get("unlockedMiniapps")?.includes(blockType);
      const isDefaultMiniapp = BlockTypes[blockType].createdBy === "seam";
      if (isMiniappOwned || isDefaultMiniapp) {
        window.emitter.emit("SEAM_POST_BLOCKTYPE_CLICK", blockType);
      } else {
        window.emitter.emit("SEAM_POST_BLOCKTYPE_UNLOCK", blockType);
      }
      mixpanel.track("block attribution clicked");
    };  

    return (
      <div className="w-full flex space-x-1 flex-row h-full items-center justify-start truncate">
        <div onClick={() => seamNavigator.navigateTo(`/user/${user?.get("profileId")}`)} className="flex flex-row space-x-1 items-center justify-center cursor-pointer w-auto h-auto">
          <Avatar src={processedAvatar} className="w-4 h-4" />
          <Typography variant="h4" className={`${blueTextColor} underline underline-offset-2 text-center overflow-visible`}>{collection ? collector?.get("profileId") : authorAccount?.get('profileId')}</Typography>  
        </div>
        <h4 className={`text-${textColor} text-opacity-[40%] w-auto`}>{actionString}</h4>
        <h4 className={`text-${textColor} text-opacity-[40%]`}>{collection && " to "}</h4>
        {collection && 
          <div onClick={() => seamNavigator.navigateTo(`/collection/${collection.id}`)} className="cursor-pointer w-auto h-full truncate">
            <h4 className={`${blueTextColor} underline underline-offset-2 w-auto truncate`}>{displayName}</h4>
          </div>
        }
        <h4 className={`text-${textColor} text-opacity-[40%]`}>
          · {formatPostDate(collection ? postInCollection?.createdAt : post.createdAt)}
        </h4>
      </div>
    )
  }

  const SeamItemText = () => {
    return (
      <div className={`${!isSelfCollected && "ml-1"} flex flex-row items-center justify-start w-auto h-auto`}>
        <h4 className="text-black text-base text-start font-normal w-full h-auto leading-5">
          <UiKitLinkify>
            <span className={`text-${textColor}`}>
              {!isSelfCollected &&
                <span onClick={() => seamNavigator.navigateTo(`/user/${ownerAccount?.get("profileId")}`)} className="text-seam-blue underline underline-offset-2 cursor-pointer hover:underline mr-1">
                  {ownerAccount?.get("profileId")}
                </span>
              }
              {postData.text}   
              {!isSelfCollected &&
                <span className={`text-${textColor} text-opacity-[40%]`}>
                  &nbsp;· {formatPostDate(post.createdAt)}
                </span>
              }
            </span>    
          </UiKitLinkify>
        </h4>
      </div>
    )
  }

  const SeamItemSubtitle = () => {
    const processedAvatar = getProcessedProfilePictureURL(ownerAccount?.get("profilePhoto"));

    return (
      <>
      <div className="w-auto h-full flex flex-col items-dy justify-start">
        <div className="text-start flex flex-row self-start w-auto h-full">
          <div onClick={() => seamNavigator.navigateTo(`/user/${ownerAccount?.get("profileId")}`)}className="flex flex-row w-auto space-x-1">
            <Avatar src={processedAvatar} className="w-4 h-4" />
          </div>
        </div>
      </div>
      </>
    ) 
  }

  try {
    return (
      <div className="space-y-2 flex flex-col items-center justify-center w-auto h-full" >
        <div className="flex flex-col items-start justify-center w-full h-full">
          <SeamItemHeader />
          {!isSelfCollected && <div className="h-[8px]"></div>}
          <div className="flex flex-row items-start w-full h-full justify-start space-x-2">
            <div className={`pl-[10px] flex flex-row h-full w-auto items-start justify-start ${!isSelfCollected ? `border-l-2 border-${textColor} border-opacity-[10%] ml-[7px]` : "ml-[10px]"}`}>
              {!isSelfCollected && <SeamItemSubtitle />}
              {<SeamItemText />}
            </div>
          </div>
        </div>
        {blockData !== undefined &&
          <div className="flex items-center justify-center w-full h-auto rounded-[16px] overflow-clip" ref={ref}>
            <FeedErrorBoundary>
              {BlockFactory.getFeedComponent(blockData, update)}
            </FeedErrorBoundary>
          </div>}
      </div>
    );
  } catch (error) {
    console.log(error);
    console.log("unable to display post " + post.id);
    console.log(post);
    Sentry.captureException("feed is crashing!!!");
    return <></>;
  }
}
import React from 'react';
import Linkify from "linkify-react";
import "linkify-plugin-mention";

const options = {
  formatHref: {
    mention: function (href, type) {
      return '/user/' + href.substring(1);
    }
  }
};

const UiKitLinkify = ({children}) => (
  <Linkify options={options}>{children}</Linkify>
);

export default UiKitLinkify;
import { useState, useEffect } from "react";
import Card from '@mui/material/Card';
import Modal from '@mui/material/Modal';
import { nanoid } from 'nanoid'
import { Responsive as ResponsiveGridLayout } from "react-grid-layout"; // needed to support mobile toggle;
import { withSize } from "react-sizeme";
import Widget from "./Widget";
import EmptyState from "./components/EmptyState";
import { ThemeProvider } from "@mui/material";
import { ThemeTypes } from "./themes/ThemeTypes";
import BlockFactory from './Block-SDK/src/blocks/BlockFactory'
import InternalBlockFactory from './InternalBlocks/InternalBlockFactory'
import mixpanel from "mixpanel-browser";
import { useMobile } from "./utils/MobileContext";
import SizeAwareComponent from './utils/SizeAwareComponent'
import * as Sentry from "@sentry/react";
import WebFont from "webfontloader";
import Parse from "parse";
import { useSeamUser } from "./utils/SeamUserContext";

// initially based on https://codesandbox.io/s/5nevr
function Content({ size: { width }, data, creatorAccount, hasWriteAccess, isInEditMode }) {
  const CardData = Parse.Object.extend("Card");
  const loadedBlocks = data.get("blocks")
  const loadedTheme = data.get("theme")

  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [isPublishing, setIsPublishing] = useState(null);
  const [isMobileMode, setIsMobileMode] = useState(false); // toggleable mobile status for editing cards
  const [blocks, setBlocks] = useState(loadedBlocks);
  const [blockEditHistory, setBlockEditHistory] = useState(() => new Set()); // blocks edited since the last publish
  const [themeName, setThemeName] = useState((loadedTheme in ThemeTypes ? data.get("theme") : "Blueprint"))
  const { isMobile } = useMobile();
  const { account } = useSeamUser();

  const [layouts, setLayouts] = useState(JSON.parse(data.get("layout")));
  const [isEditingBlock, setIsEditingBlock] = useState(-1) // number is the index of the block being edited

  const onLayoutChange = (_, allLayouts) => {
    setLayouts(allLayouts);
  };

  useEffect(() => {
    // subscribe to events when mounting
    window.emitter.on('SEAM_EVENT_TOGGLE_MOBILE', (deviceType) => setIsMobileMode(deviceType === "mobile"))
    window.emitter.on('SEAM_EVENT_PUBLISH', (newData) => setIsPublishing(newData))
    window.emitter.on('SEAM_EVENT_CHANGE_THEME', (theme) => {
      setThemeName(theme)
      WebFont.load({
        google: {
          families: [ThemeTypes[theme].theme.typography.fontFamily]
        }
      });
    })
    window.emitter.on("SEAM_EVENT_ADD_BLOCK", (block) => {
      addSpecificItem(block)
    })
    window.emitter.on("SEAM_EVENT_CLEAR_PAGE_EDITS", () => {
      setBlockEditHistory(new Set())
      setHasUnsavedChanges(false)
      setBlocks(loadedBlocks)
      setThemeName(loadedTheme)
    })
  }, [])

  useEffect(() => {
    if (isPublishing != null) {
      onLayoutSave(isPublishing)
    }
  }, [isPublishing])

  // thanks to https://dev.to/eons/detect-page-refresh-tab-close-and-route-change-with-react-router-v5-3pd
  // prevent navigation when there are unsaved changes
  window.onbeforeunload = (event) => {
    if (!hasUnsavedChanges) { return }
    const e = event || window.event;
    e.preventDefault()
    if (e) {
      e.returnValue = '';
    }

    return '';
  }

  const onLayoutSave = (newData) => {
    const jsonValue = JSON.stringify(layouts)
    let savedBlockData = {
      objectId: data.id,
      layout: jsonValue,
      blocks: blocks,
      theme: themeName
    }
    const newPayload = Object.assign({}, newData, savedBlockData)

    let cardData = new CardData()
    cardData.save(newPayload).then((card) => {
      window.emitter.emit("SEAM_EVENT_PUBLISH_SUCCESSFUL", null)
      setHasUnsavedChanges(false)
      setIsPublishing(null)
      setBlockEditHistory(new Set())
      mixpanel.track("card saved")
    },
      (error) => {
        alert("You don't have write access for this card");
        Sentry.captureException(error.message)
        setIsPublishing(null)
        console.log(error.message)
      },
    );
  };

  // incoming blockUUID
  const onRemoveItem = (itemId) => {
    mixpanel.people.increment("blocks deleted")
    setBlocks(blocks.filter((model) => model.uuid !== itemId));
    setBlockEditHistory(prev => {
      const next = new Set(prev);
      next.delete(itemId);
      return next;
    });
    setHasUnsavedChanges(true)
  };
  const onEditItem = (itemId) => {
    const indexOfEditedBlock = blocks.findIndex(element => element.uuid === itemId)
    setIsEditingBlock(indexOfEditedBlock)
  };
  const onAddItem = (blockName) => {
    const newBlockId = nanoid(6)
    const newBlock = {
      type: blockName,
      data: {},
      uuid: newBlockId
    }
    addSpecificItem(newBlock)
  };
  const addSpecificItem = (newBlock) => {
    setBlocks(prevBlocks => [...prevBlocks, newBlock]);
    setBlockEditHistory(prev => new Set(prev).add(newBlock.uuid))
    mixpanel.people.increment("blocks added")
    setHasUnsavedChanges(true)
  }

  const getBlockFeedComponent = (model) => {
    return model.type.startsWith("internal:")
      ? InternalBlockFactory.getBlock(model, ThemeTypes[themeName].theme, creatorAccount, account, data, data.get("creator")).render() // Special Blocks on Profiles
      : BlockFactory.getFeedComponent(model, () => {}) // All Public Blocks
  }

  const getBlockComposerComponent = (model) => {
    return model.type.startsWith("internal:")
      ? InternalBlockFactory.getBlock(model, ThemeTypes[themeName].theme, creatorAccount, account, data, data.get("creator")).renderEditModal() // Special Blocks on Profiles
      : BlockFactory.getComposerComponent({model, done: saveBlockData}) // All Public Blocks
  }

  const renderBlock = (model) => {
    let block = getBlockFeedComponent(model)
    let minHeight = InternalBlockFactory.getMinHeightForBlock(model)
    let minWidth = InternalBlockFactory.getMinWidthForBlock(model)

    return (
      <SizeAwareComponent
        key={model.uuid}
        className="widget"
        data-grid={{ w: minWidth * 2, h: minHeight * 2, x: 4, y: -1, minH: minHeight, minW: minWidth }}
        style={themeForBlocks}
      >
        <Widget id={model.uuid}
          onRemoveItem={onRemoveItem}
          onEditItem={onEditItem}
          isInEditMode={isInEditMode}
          blockType={model.type}
          blockComponent={block}>
        </Widget>
      </SizeAwareComponent>
    )
  }

  const renderBlockEditModal = () => {
    if (isEditingBlock === -1) { return }
    const model = blocks[isEditingBlock]
    const block = getBlockComposerComponent(model)
    const titleString = "Editing Block"
    return (
      <Modal
        open={isEditingBlock !== -1}
        footer={null}
        onClose={() => setIsEditingBlock(-1)}
        style={{
          overflow: 'scroll',
          display: 'block',
          fontSize: "16px",
          fontWeight: "500"
        }}
      >
        <Card
          style={{
            marginTop: "10px",
            borderRadius: "1rem",
            padding: "15px",
            width: isMobile ? "95%" : "500px",
            position: 'absolute',
            top: '10%',
            left: '50%',
            transform: 'translate(-50%, 0)',
          }}
        >
          {titleString}
          {block}
        </Card>
      </Modal>
    );
  }

  const saveBlockData = (data) => {
    setIsEditingBlock(-1)
    let blocksCopy = blocks
    let previousBlockData = loadedBlocks[isEditingBlock]
    let didUpdateBlock = JSON.stringify(data) !== JSON.stringify(previousBlockData)
    if (didUpdateBlock) {
      blocksCopy[isEditingBlock] = data
      setBlocks(blocksCopy)
      setBlockEditHistory(prev => new Set(prev).add(data.uuid))
      setHasUnsavedChanges(true)
    }
  }

  const isEmpty = (blocks.length === 0)
  const muiTheme = ThemeTypes[themeName].theme
  const bg = muiTheme.palette.background.default
  const toggledMobileWidth = isMobileMode ? 340 : width
  const outOfBoundsMobileToggleStyle = { width: "100%", display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: "rgba(0, 0, 0, 0.25)" }
  // TODO: centering the grid in the center of the screen on larger monitors is broken; it right aligns.
  // spend more time investigating why the largeScreenCenteringStyle adds weird padding and shoves everything to the right side
  // for now, just stretch even if the screen size is really large
  const containerStyle = isMobileMode ? outOfBoundsMobileToggleStyle : (width > 2000 ? {} : {})
  const inBoundsMobileToggleStyle = isMobileMode ? { width: toggledMobileWidth, height: "100%", backgroundColor: bg } : {}

  const themeForBlocks = { fontFamily: muiTheme.typography.fontFamily }

  return (
    <div>
      {isEmpty && <EmptyState onAddBlock={onAddItem} isInEditMode={isInEditMode} canEdit={hasWriteAccess} />}
      <ThemeProvider theme={muiTheme}>
        <div style={containerStyle}>
          {!isEmpty &&
            <div style={inBoundsMobileToggleStyle}>
              <ResponsiveGridLayout
                className="layout"
                layouts={layouts}
                breakpoints={{ lg: 768, xxs: 0 }}
                cols={{ lg: 12, xxs: 2 }}
                rowHeight={5}
                autosize={false}
                width={toggledMobileWidth}
                onLayoutChange={onLayoutChange}
                resizeHandles={['se']}
                isDraggable={isInEditMode}
                isResizable={isInEditMode}
                measureBeforeMount={true}
              >
                {blocks.map((model) => (
                  renderBlock(model)
                ))}
              </ResponsiveGridLayout>
            </div>}
        </div>
      </ThemeProvider>
      {renderBlockEditModal()}
    </div>
  );
}

export default withSize({ refreshMode: "debounce", refreshRate: 60 })(Content);

import { Colors } from "./utils/colors"

export default function ErrorPage() {
  return (
    <div style={{backgroundColor: Colors.SEAM_BLUE_400, width: "100%", display: "flex", flexDirection: "column", padding: 24}}>
      <h1 style={{ color: "white", bottom: 10, left: 10, fontFamily: "VT323", lineHeight: '1em' }}> Yikes, Seam doesn't support this browser yet. </h1>
      <br></br>
      <h2 style={{ color: "white", bottom: 10, left: 10, fontFamily: "VT323" }}> For the best experience, use Chrome (not incognito). You can email us at <u>hello@getseam.xyz</u></h2>
    </div>
  )
}
import InternalBlock from "./InternalBlock"
import { BlockModel } from '../Block-SDK/src/blocks/types'
import InternalConnections from './InternalConnections'

export default class InternalConnectionsBlock extends InternalBlock {
  render() {
    return InternalConnections(this.model, this.theme, this.creatorAccount)
  }

  renderEditModal(done: (data: BlockModel) => void) {
    // Internal blocks can't be edited
    console.error("Internal blocks can't be edited!")
    return (
      <></>
    )
  }

  renderErrorState() {
    return (
      <h1>Error: Internal Block</h1>
    )
  }
}
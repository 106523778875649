import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import { SubscribeToNotifications } from './NotificationRegistrationHelpers';
import { useSeamUser } from '../utils/SeamUserContext';
import NotificationsIcon from '@mui/icons-material/Notifications';

const NotificationReminderDialog = ({ open, onClose }) => {
  const { account } = useSeamUser();

  const handleAllowClick = async () => {
    if (!account) return;
    await SubscribeToNotifications(account);
    onClose();
  };

  return (
    <Dialog   
      sx={{ 
        // background: '#FFF',
        '& .MuiPaper-root': {
          background: '#FFF',
          borderRadius: '20px',
          borderWidth: '1px',
          borderColor: 'rgba(0, 0, 0, 0.05)',
          boxShadow: 'none',
        },
        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
        }
      }}
      className="rounded-[20px] border-2 border-gray-400/20" 
      open={open} 
      onClose={onClose} 
      maxWidth="xs" 
      fullWidth
    >
      <DialogContent className="flex items-center justify-center flex-col space-y-4" style={{ textAlign: 'center', padding: '16px' }}>
        <div className="rounded-full border-seam-pink border-2 w-[70px] h-[70px] self-center flex text-center items-center justify-center">
          <Typography className="text-[40px]">🔔</Typography>
        </div>
        <h4 className="text-[24px] font-normal">Turn on notifications</h4>
        <h4 className="text-[16px] font-normal leading-[24px]">
          Get notifications for new messages, friend requests, and replies.
        </h4>
        <div className="flex flex-row items-center justify-center space-x-4 w-full">
          <div className="rounded-[8px] border-[2px] border-seam-black/5 w-full">
            <Button onClick={onClose} className="py-6 w-full text-seam-black bg-seam-white">
              Skip
            </Button>
          </div>
          <Button onClick={handleAllowClick} className="py-6 w-full ml-4 text-seam-white bg-seam-pink rounded-[8px]">
            Allow
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default NotificationReminderDialog;
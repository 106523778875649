import { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Parse from 'parse';
import InfiniteScroll from "react-infinite-scroll-component";
import Divider from '@mui/material/Divider';
import { Colors } from "../utils/colors"
import Typography from "@mui/material/Typography";
import CardItem from "./CardItem";
import { useEffect } from "react";
import mixpanel from "mixpanel-browser";
import { useSeamNavigator } from "../Navigation/SeamNavigatorContext";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
  },
  cardTitle: {
    fontFamily: "Public Sans",
    fontWeight: 700,
    color: Colors.SEAM_GRAY_100
  },
  cardSubtitle: {
    fontFamily: "Public Sans",
    textAlign: "start",
    fontSize: 14,
    fontWeight: 700
  },
  emptyState: {
    textAlign: "left",
    fontSize: 14,
    fontWeight: 300,
    color: Colors.SEAM_GRAY_600
  },
});

export default function CardList(userId) {
  const classes = useStyles();
  const seamNavigator = useSeamNavigator();
  const Card = Parse.Object.extend("Card");
  Parse.Object.registerSubclass("Card", Card);
  const [data, setData] = useState([])

  const isOwner = Parse.User.current()?.id == userId
  const possessive = isOwner ? "Your" : "Their"
  const pronoun = isOwner ? "you" : "they"

  useEffect(() => {
    if (!userId) return;

    const query = new Parse.Query(Card);
    query.equalTo("creator", userId);
    
    query.find().then((results) => {
      setData(results);
      console.log("cards: " + data);
      console.log("user: " + userId);
    }).catch(error => {
      console.error("Error in query:", error);
    });
  }, [userId]);


  const deleteCard = (selectedCard, index) => {
    if (window.confirm("Do you really want to delete " + selectedCard.get("title") + "? There's no going back!")) {
      selectedCard.destroy().then(
        (card) => {
          alert("Success - it's gone.")
          data.splice(index, 1);
          mixpanel.track("deleted page", {card: selectedCard.get("title")})
        },
        (error) => {
          alert("Failure - wasn't able to delete because " + error)
        }
      );
    }
  }

  const hasNoCards = (userId != null && data.length == 0)

  return (
    <div className={classes.root}>
      <h2 style={{ textAlign: "left", fontFamily:"Unbounded", fontWeight: "bold", margin: 16,}}>{possessive + " Pages"}</h2>
      <Divider />
      <InfiniteScroll
        dataLength={data.length}
        next={() => { }}
        hasMore={false}
        loader={<h4>Loading...</h4>}
      >
        {data.map((card, index) => (
          <CardItem key={index} card={card} index={index} canDeleteCard={isOwner && card.get('type') != 'profile'} deleteCard={(card, index) => deleteCard(card, index)} tapAction={(card, index) => {
            seamNavigator.navigateTo(card.get("name"))
            mixpanel.track("navigated", {location: card.get("name")})
          }} />
        ))}
      </InfiniteScroll>
      {hasNoCards && <Typography className={classes.emptyState}>{"Nothing here - " + pronoun + " haven't made any pages yet"}</Typography>}
    </div>
  )
}
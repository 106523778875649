import React, { useState, useEffect } from "react";
import Parse from "parse";
import { makeStyles } from "@mui/styles";
import InfiniteScroll from "react-infinite-scroll-component";
import FeedItem from "./FeedItem";
import { useSeamUser } from "../utils/SeamUserContext";
import { LoadingSkeleton } from "../components/LoadingSkeleton";

const useStyles = makeStyles({
  itemBackground: {
    backgroundColor: "white",
    paddingTop: 16,
    marginTop: 12
  },
});

export default function AdminReportedPostsFeed({ height }) {
  const POSTS_PER_PAGE = 10;
  const classes = useStyles();
  const { account } = useSeamUser();
  const [loadedPosts, setLoadedPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    loadMore();
  }, []);

  useEffect(() => {
    // optimistically remove deleted posts
    window.emitter.on("SEAM_EVENT_DELETE_POST", (post) => {
      setLoadedPosts(prevPosts => prevPosts.filter(prevPost => prevPost.id != post));
    });
  }, []);

  async function loadMore() {
    const query = new Parse.Query("Post");
    query.notEqualTo("reported", []);
    query.notEqualTo("isDeleted", true);
    query.descending("createdAt");
    query.include("author");
    query.limit(POSTS_PER_PAGE);
    query.skip(loadedPosts.length);
    const results = await query.find();

    setLoadedPosts(prevPosts => [...prevPosts, ...results]);

    if (results.length < POSTS_PER_PAGE) {
      setHasMore(false);
    }
    setLoading(false);
  }

  return (
    <InfiniteScroll
      dataLength={loadedPosts.length} xf
      next={loadMore}
      hasMore={hasMore}
      height={height}
    >
      {!loading && loadedPosts.map((post) => {
        return (
          <div key={post.id} style={{ width: "100%", paddingTop: 12 }}>
            <FeedItem post={post} channel={undefined} />
          </div>
        );
      })}
      {loading && LoadingSkeleton()}
    </InfiniteScroll>
  )
}
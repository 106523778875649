import React, { memo, useState, useEffect, useRef } from 'react';
import SeamPostRepository from '../../Post/SeamPostRepository';
import mixpanel from 'mixpanel-browser';
import { FavoriteOutlined, FavoriteBorder, LibraryAddOutlined } from '@mui/icons-material';
import { debounce } from 'lodash';
import { MessageSquare } from 'react-feather';
import { ReactComponent as StitchIcon } from "../../assets/icons/stitchIcon.svg"
import BlockFactory from '../../Block-SDK/src/blocks/BlockFactory';
import SeamPillButton from '../../Block-SDK/src/components/SeamPillButton';
import { useSeamUser } from '../../utils/SeamUserContext';
import { useSeamNavigator } from '../../Navigation/SeamNavigatorContext';
import { IonContent, IonModal } from '@ionic/react';
import { useMobile } from '../../utils/MobileContext';
import SeamShareBottomSheet from './SeamShareBottomSheet';
import SeamCollectingBottomSheet from './SeamCollectingBottomSheet';
import FeedItemOptions from '../../Discover/FeedItemOptions';
import Parse from 'parse';

const SeamEngagementBar = ({
  post,
  collection,
  postInChannel,
  isOnPostDetailPage,
  isOnChannelPage,
  darkMode,
}) => {
  const modal = useRef(null);
  const commentCount = post.get("commentCount") === 0 ? "" : post.get("commentCount");
  const totalReactions = post.get("reactionCount")
  const seamNavigator = useSeamNavigator();
  const { account, login } = useSeamUser();
  const { isMobile } = useMobile();
  const [selectedReaction, setSelectedReaction] = useState(null);
  const [isReacting, setIsReacting] = useState(false);
  const [isSharing, setIsSharing] = useState(false);
  const [isCollecting, setIsCollecting] = useState(false);
  const postInChannelCount = post.get("channelCount") || 0;
  const textColor = darkMode ? 'white' : 'black';

  // for displaying printable block names for attribution
  const getBlockModel = (post) => {
    if (post.get("dataType") === 3 || post.get("dataType") === 0) {
      return post?.get("postData")?.blockData;
    }
  }

  let blockTypeDisplay = null;
  const blockModel = getBlockModel(post);

  if (blockModel && blockModel.type) {
    blockTypeDisplay = `${BlockFactory.getPrintableBlockName(blockModel)} Block`;
  }

  const handleCommentButtonClick = () => {
    if (!account) {
      login();
    } else if (isOnPostDetailPage) {
      // Open comment composer on PostDetailPage
      // toggleComposeBar();
    } else {
      // Navigate to PostDetailPage on feed
      seamNavigator.navigateTo(`post/${post.id}`, post);
      mixpanel.track("comments open");
    }
  };

  // Debounced function to handle like button taps
  const debouncedReactToPost = debounce((post, reactionKey, account, currentReaction) => {
    if (!isReacting) {
      setIsReacting(true);
      const onSuccess = (reaction) => {
        setSelectedReaction(reaction);
        setIsReacting(false);
      };

      SeamPostRepository.reactToPost(post, reactionKey, account, currentReaction, onSuccess);
    }
  }, 300);

  const tapLikeButton = async (event) => {
    if (!account) { 
      login() 
    } else {
      setSelectedReaction(selectedReaction);
      debouncedReactToPost(post, 'Like', account, selectedReaction);
    }
  }

  const tapShareButton = async (event) => {
    if (!account) {
      login()
    } else {
      mixpanel.track("share clicked")
      setIsSharing(true);
    }
  }
  
  const tapOptionsButton = async (event) => {
    if (!account) {
      event.preventDefault();
      login()
    } else {
      // do as normal
    }
  }
 
  const tapChannelButton = async (event) => {
    if (!account) {
      event.preventDefault();
      login()
    } else {
      mixpanel.track("add to channel button clicked");
      setIsCollecting(true);
    } 
  }

  useEffect(() => {
    async function fetchCurrentReaction() {
      const currentReaction = await SeamPostRepository.getReaction(post, account)
      setSelectedReaction(currentReaction)
    }

    fetchCurrentReaction()
  }, [])

  return (
    <div>
      <div className="flex items-center py-1">
        <div className="flex space-x-2">
          <SeamPillButton
            icon={<MessageSquare className={`text-${textColor} h-4 w-4`} />}
            label={commentCount > 0 ? <h4 className={`text-${textColor}`}>{commentCount}</h4> : null}
            darkMode={darkMode}
            onClick={handleCommentButtonClick}
            isOnChannelPage={isOnChannelPage}
          />
          <SeamPillButton
            icon={selectedReaction ? <FavoriteOutlined className="text-seam-red h-4 w-4" /> : <FavoriteBorder className={`text-${textColor} h-4 w-4`} />}
            label={totalReactions > 0 ? <h4 className={`text-${textColor}`}>{totalReactions}</h4> : null}
            darkMode={darkMode}
            onClick={tapLikeButton}
            isOnChannelPage={isOnChannelPage}
          />
          <SeamPillButton
            icon={<StitchIcon className={`text-${textColor} h-4 w-4`} />}
            label={null}
            darkMode={darkMode}
            onClick={tapShareButton}
            isOnChannelPage={isOnChannelPage}
          />
          <SeamPillButton 
            icon={<FeedItemOptions post={post} collection={collection} postInCollection={postInChannel} ownerAccount={post?.get("author")} isOnChannelPage={isOnChannelPage} />}
            label={null}
            darkMode={darkMode}
            onClick={tapOptionsButton}
            isOnChannelPage={isOnChannelPage}
          />
        </div>
        {/* This spacer div will grow and push the subsequent elements to the end */}
        <div className="flex-grow"></div>
        <div className="flex space-x-2">
          <SeamPillButton 
            icon={<LibraryAddOutlined className={`text-${textColor} h-4 w-4`} />}
            label={null}
            darkMode={darkMode}
            onClick={tapChannelButton}
            isOnChannelPage={isOnChannelPage}
          />
        </div>
      </div>
      {(isSharing || isCollecting) && (
        <IonModal
          ref={modal}
          isOpen={isSharing || isCollecting}
          initialBreakpoint={isSharing ? 0.3 : 1}
          breakpoints={isSharing ? [0, 0.3] : [0, 1]}
          canDismiss={true}
          showBackdrop={true}
          onDidDismiss={() => {
            setIsSharing(false);
            setIsCollecting(false);
          }}
        >
          <div style={{marginBottom: 'env(safe-area-inset-bottom)'}} className="flex flex-col justify-center w-auto h-full my-6 px-4">
            {isSharing && <SeamShareBottomSheet type="post" object={post} />}
            {isCollecting && <SeamCollectingBottomSheet post={post} postInChannel={postInChannel} modalRef={modal} />}
          </div>
        </IonModal>
      )}
    </div>
  );
};

export default memo(SeamEngagementBar);
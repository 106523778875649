import Parse from 'parse';
import { usePrivy, useLogin } from '@privy-io/react-auth';
import SeamAuthenticator from "../Authentication/SeamAuthenticator";
import { useSeamNavigator } from '../Navigation/SeamNavigatorContext';

function MobileLoginComponent() {
  const { logout, authenticated } = usePrivy();
  const seamNavigator = useSeamNavigator();
  const { login } = useLogin({
    onComplete: (user, isNewUser, wasAlreadyAuthenticated) => {
      // Handle login through SeamAuthenticator and emit global login success event
      SeamAuthenticator.handlePrivyLogin(user, isNewUser).then(() => {
      });
    },
    onError: (error) => {
      console.log(error);
    }
  });

  const handlePrivyAuth = async () => {
    if (authenticated) {
      logout()
    } else {
      login()
    }
  }

  return (
    <div className="flex flex-col p-4 h-full justify-between">
      <button
        className="w-full h-16 bg-white text-black rounded-[8px]"
        onClick={() => { handlePrivyAuth() }}
      >
        Let's Goooooo!!
      </button>
    </div>
  );
}

export default MobileLoginComponent;
import LeftBannerNav from "../Navigation/LeftBannerNav";
import { useMobile } from "../utils/MobileContext";
import { useSeamUser } from "../utils/SeamUserContext";
import SearchDropdown from "../Discover/SearchDropdown";
import useSearchReducer from "../utils/useSearchReducer";
import React, { useState, useRef, useEffect } from "react";
import { IonHeader, IonSearchbar, IonToolbar } from "@ionic/react";
import { searchCircle } from 'ionicons/icons';
import { SearchOff } from "@mui/icons-material";

export default function DesktopSidebarWrapper({showDesktopHeader, leftIcon, centerIcon, rightIcon, navSection, darkMode, children }) {
  const { isMobileApp, isMobile } = useMobile();
  const { account } = useSeamUser();
  const [state, dispatch] = useSearchReducer(); 
  const [searchTerm, setSearchTerm] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const searchbarRef = useRef(null);

  useEffect(() => {
    const handleToggleSearchBar = () => {
      dispatch({ type: 'TOGGLE_SEARCH_BAR' });
    };
  
    // Add event listener for the search bar toggle event
    window.emitter.on('SEAM_EVENT_TOGGLE_SEARCH', handleToggleSearchBar);
  
    return () => {
      // Clean up the listener when the component is unmounted
      window.emitter.off('SEAM_EVENT_TOGGLE_SEARCH', handleToggleSearchBar);
    };
  }, []);

  const handleInput = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    dispatch({ type: 'UPDATE_SEARCH_STRING', payload: value });
  };

  const handleSearchClear = () => {
    setSearchTerm('');
    setShowDropdown(false);
  };
  
  const onSearchSubmit = (searchString) => {
    dispatch({ type: "UPDATE_SEARCH_STRING", payload: searchString });
  };

  if (isMobileApp || isMobile ) return children;
  const hasIcon = leftIcon || centerIcon || rightIcon;

  return (
    <div className="flex z-10 flex-row bg-transparent justify-between w-screen max-w-[1440px] mx-auto h-full">
      <div className="max-w-[270px] z-10 w-full h-full bg-transparent items-center border-r border-seam-black/[5%]">
        {account && <LeftBannerNav navSection={navSection} searchActive={state.showSearchBar} darkMode={darkMode}/>}
      </div>
      {/* center content */}
      <div className="flex flex-col items-center bg-transparent justify-start w-full h-auto">
      {showDesktopHeader && 
        <div className="p-0 m-0 z-10 border-b border-seam-black/[5%] bg-transparent flex flex-col w-full h-auto max-h-[64px]">
          {state.showSearchBar ? (
            <>
              <div ref={searchbarRef} className="relative self-center p-0 my-0 w-full max-w-[720px] relative">
                <IonSearchbar
                  onIonCancel={() => dispatch({ type: 'TOGGLE_SEARCH_BAR' })}
                  onIonChange={(e) => onSearchSubmit(e.detail.value)}
                  onIonInput={handleInput}
                  onIonClear={handleSearchClear}
                  showCancelButton="focus"
                  placeholder="Search Seam..."
                  debounce={300}
                  className="homeSearch"
                />
                {searchTerm && (
                  <div className="absolute z-11 w-full flex items-center justify-center">
                    <SearchDropdown searchTerm={searchTerm} />
                  </div>
                )}
              </div>
            </>
          ) : (
            <div className={`flex flex-row items-center justify-between w-full h-auto ${hasIcon && 'py-2'}`}>
              {leftIcon}
              <div style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)', }} className="flex w-auto shrink truncate" >
                {centerIcon ? centerIcon : <></>}
              </div>
              {rightIcon ? rightIcon : <div></div>}
            </div>
          )}
        </div>
      }
        <div className="flex flex-co bg-transparent justify-center items-center w-full max-w-[720px] h-full">
          {children}
        </div>
      </div>
      <div className="max-w-[270px] z-10 w-full bg-transparent h-full border-l border-seam-black/[5%]"></div>
    </div>
  );
}
import React, { useState, useCallback, useEffect } from 'react';
import { Paper, InputBase, Avatar, Typography, Divider } from "@mui/material";
import { useMobile } from '../utils/MobileContext';
import { Search } from 'react-feather';
import { makeStyles } from "@mui/styles";
import { debounce } from 'lodash';
import Parse from 'parse';
import mixpanel from 'mixpanel-browser';
import { useSeamNavigator } from '../Navigation/SeamNavigatorContext';

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    position: 'absolute',
    top: '50%',
    left: props.isMobile ? '50%' : 'auto',
    transform: 'translate(-104%, -50%)',
    zIndex: 1000,
    width: props.isMobile ? '60vw' : '',
  }),
  searchRoot: (props) => ({
    padding: '6px 4px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    borderRadius: props.isFocused ? '24px 24px 0px 0px' : '24px',
    backgroundColor: '#EFEFEF',
    fontFamily: 'Public Sans, sans-serif',
    marginRight: '16px',
    boxShadow: '',
    position: 'relative',
  }),
  input: {
    marginLeft: '16px',
  },
  iconButton: {
    padding: 6,
  },
  resultItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px 16px',
    backgroundColor: '#f9f9f9',
    '&:hover': {
      backgroundColor: '#e6e6e6',
    },
  },
  resultText: {
    marginLeft: '10px',
    fontFamily: 'Public Sans, sans-serif',
    color: 'black',
    fontSize: '16px',
    flexGrow: 1,
    overflow: 'hidden',
    marginLeft: '10px',
  },
  username: {
    fontWeight: 'bold',
    fontFamily: 'Public Sans, sans-serif',
    fontSize: '16px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
  },
  dropdown: {
    position: 'absolute',
    zIndex: 1,
    backgroundColor: theme.palette.background.paper,
    minHeight: '330px',
    border: '2px solid black',
    width: '100%',
    top: '100%',
    left: 0,
  },
  emptyState: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '330px',
    fontFamily: 'Public Sans, sans-serif',
    fontStyle: 'normal'
  },
  hoverBox: {
    padding: '0 16px',
  }
}));

const SearchBar = ({ setShowSearchBar, showSearchBar, onTapAction, prompt, hideSearchIcon, dropdownWidth }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState({ users: [], tags: [] });
  const { isMobile } = useMobile();
  const [isFocused, setIsFocused] = useState(false);
  const classes = useStyles({ isFocused, isMobile });
  const seamNavigator = useSeamNavigator();;
  const promptText = prompt ? prompt : "Search";

  async function handleSearch(query) {
    if (query.length === 0) {
      setSearchResults({ users: [], tags: [] });
      return;
    }

    try {
      const results = await Parse.Cloud.run('combinedSearch', { searchTerm: query });
      setSearchResults(results);
    } catch (error) {
      console.error('Error during search:', error);
    }
  }

  const debouncedHandleSearch = useCallback(debounce(handleSearch, 300), []);

  useEffect(() => {
    debouncedHandleSearch(searchTerm);  // Call the debounced function here
  }, [searchTerm, debouncedHandleSearch]);

  const handleBlur = (e) => {
    // Check if the relatedTarget is inside the dropdown
    if (e.relatedTarget && e.relatedTarget.closest(`.${classes.dropdown}`)) {
      return;
    }

    // Delay hiding the dropdown to allow for click event 
    setTimeout(() => {
      setIsFocused(false);
    }, 150);
  };

  const handleClickInside = (e) => {
    e.stopPropagation(); // Prevents the click 
  };

  const handleTapAction = (username) => {
    if (onTapAction !== undefined) {
      onTapAction(username);
      return;
    }

    const url = `user/${username}`;
    seamNavigator.navigateTo(url)
  }

  return (
    <div onClick={handleClickInside} className={classes.root}>
      <Paper component="form" className={classes.searchRoot} style={{ boxShadow: 'none' }}>
        <InputBase
          className={classes.input}
          placeholder={promptText}
          inputProps={{ 'aria-label': 'Search' }}
          value={searchTerm}
          onFocus={() => setIsFocused(true)}
          onBlur={handleBlur}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        {!hideSearchIcon && (
          <Search size={36} style={{ cursor: 'pointer' }} className={classes.iconButton} aria-label="search" onClick={() => setShowSearchBar(false)} />
        )}
        {isFocused && (
          <div className={classes.dropdown} style={{ width: dropdownWidth }}>
            {searchResults.users.length > 0 && (
              <>
                <Typography variant="h6" style={{ padding: '10px' }}>Users</Typography>
                {searchResults.users.map((user) => (
                  <div key={user.username} onClick={() => { mixpanel.track("Used search bar"); handleTapAction(user.username) }}>
                    <div className={classes.resultItem}>
                      <Avatar alt={user.name} src={user.profilePhoto} />
                      <div className={classes.resultText}>
                        <Typography variant="subtitle2" className={classes.username}>@{user.username}</Typography>
                        <Typography variant="body2">{user.name}</Typography>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}

            {searchResults.tags.length > 0 && (
              <>
                <Divider style={{ margin: '10px 0' }} />
                <Typography variant="h6" style={{ padding: '10px' }}>Tags</Typography>
                {searchResults.tags.map((tagName) => (
                  <div className="max-w-[420px]" key={tagName} onClick={() => seamNavigator.navigateTo(`search/${tagName}`)}>
                    <div className="flex items-center justify-between max-w-[420px] truncate whitespace-nowrap">
                      <Typography variant="subtitle2 whitespace-nowrap max-w-[420px]"># {tagName}</Typography>
                    </div>
                  </div>
                ))}
              </>
            )}

            {searchResults.users.length === 0 && searchResults.tags.length === 0 && (
              <div className={classes.emptyState}>
                No results :(
              </div>
            )}
          </div>
        )}

      </Paper>
    </div>
  );
};

export default SearchBar;

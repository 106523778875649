import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import { Capacitor } from "@capacitor/core";

interface IMobileContext {
  isMobile: boolean;
  isMobileApp: boolean;
  isMobileWeb: boolean;
}

const MobileContext = createContext<IMobileContext>({ isMobile: false, isMobileApp: false, isMobileWeb: false });

export const useMobile = () => useContext(MobileContext);

interface MobileProviderProps {
  children: ReactNode;
}

export const MobileProvider: React.FC<MobileProviderProps> = ({ children }) => {
  const [isMobile, setIsMobile] = useState(false);
  const [isMobileApp, setIsMobileApp] = useState(false);
  const [isMobileWeb, setIsMobileWeb] = useState(false);

  useEffect(() => {
    // Check if running on a native platform (iOS/Android)
    const isNativeMobile = Capacitor.getPlatform() === "ios" || Capacitor.getPlatform() === "android";
    setIsMobileApp(isNativeMobile);

    // Update state based on window width (catches mobile web)
    const updateMobileState = () => {
      setIsMobile(window.innerWidth < 768);
      setIsMobileWeb(window.innerWidth < 768 && !isNativeMobile);
    };

    updateMobileState(); // Initial check
    window.addEventListener('resize', updateMobileState);

    // Cleanup
    return () => window.removeEventListener('resize', updateMobileState);
  }, []);

  return (
    <MobileContext.Provider value={{ isMobile, isMobileApp, isMobileWeb }}>
      {children}
    </MobileContext.Provider>
  );
};

import { useCallback } from 'react';
import { usePrivy, useLogin, getAccessToken } from '@privy-io/react-auth';
import Parse from 'parse'

import * as Sentry from "@sentry/react";
import mixpanel from 'mixpanel-browser';
/*
Auth Service [Privy] --- {id, token} ---> Seam Authenticator ---> Backend Auth Adapter ---> Logged in Parse User
                                                                                |--> backfillUserAuth -^
*/

export default class SeamAuthenticator {
  static handlePrivyLogin = async (user, isNewUser) => {
    console.log("handling privy login")
    const handleError = (error) => {
      console.log("login error: " + error)
      Sentry.captureException(error)
      alert("We weren't able to log you in -- email hello@getseam.xyz for help.")
    }

    const authToken = await getAccessToken();
    const authData = {
      privyAuth: {
        id: user.id,
        token: authToken
      },
    }
    const provider = {
      authenticate(options) {
        options.success(this, {
          id: user.id,
          token: authToken
        })
      },
      getAuthType() {
        return 'privyAuth'
      },
    }

    const walletAddr = user.wallet?.address?.toLowerCase()
    const email = user.email?.address

    Parse.User.logInWith(provider, authData).then((loggedInUser) => {
      loggedInUser.addAllUnique('accounts', [walletAddr]);
      loggedInUser.set('ethAddress', walletAddr);
      loggedInUser.set('phone', user.phone?.number)
      loggedInUser.set('email', email)
      loggedInUser.set('provider', 'privyAuth')
      loggedInUser.save().then((user) => {
        window.emitter.emit("SEAM_EVENT_LOGIN_SUCCESS");
        mixpanel.track("login success")
      }, (error) => {
        handleError(error)
      })
    }, (error) => {
      handleError(error)
    })
  }
}

// exporting this as a hook so that it can use the hooks of Parse and Privy
// it can't live in the static class, as it breaks the rules of hooks
export const useSeamAuthenticationLogOut = () => {
  const { logout: privyLogOut } = usePrivy();

  const logOut = useCallback(async () => {
    let _ = await privyLogOut()
    let __ = await Parse.User.logOut()
  }, []);

  return {
    logOut
  };
};
import React, { useState } from 'react';
import { Button, IconButton } from '@mui/material';
import GifIcon from '@mui/icons-material/Gif';
import CloseIcon from '@mui/icons-material/Close';
import ReactGiphySearchbox from 'react-giphy-searchbox';
import { useMobile } from '../utils/MobileContext';

const GiphyButtonComponent = ({ onUpdate }) => {
  const [open, setOpen] = useState(false);
  const { isMobileApp } = useMobile();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleGifSelect = (item) => {
    onUpdate(item.images.original.url);
    handleClose();
  };

  return (
    <div className="border-2 border-gray-400/20 rounded-full w-auto relative">
      <Button
        onClick={handleClickOpen}
        className={`flex flex-col items-center justify-center rounded-full border-2 border-black bg-white hover:bg-gray-100 p-0 gap-0.5 ${isMobileApp ? 'w-[64px] h-[64px]' : 'w-[82px] h-[82px]'}`}
      >
        <GifIcon className="text-gray-400" style={{ fontSize: '36px' }} />
        <h4 className="text-gray-400 text-center">GIF</h4>
      </Button>

      {/* Custom Modal */}
      {open && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
          <div className="bg-white rounded-lg shadow-lg w-auto max-w-[400px] relative max-h-[80vh] overflow-hidden">
            {/* Header */}
            <div className="flex justify-between bg-seam-white pt-2 pl-2">
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </div>
            
            {/* Giphy Searchbox Container with scrollable content */}
            <div className="p-4 overflow-y-auto w-auto h-full">
              <ReactGiphySearchbox
                apiKey={process.env.REACT_APP_GIPHY_KEY}
                gifListHeight="400px"
                onSelect={handleGifSelect}
                masonryConfig={[
                  { columns: 2, imageWidth: 130, gutter: 5 },
                  { mq: '400px', columns: 2, imageWidth: 130, gutter: 5 }
                ]}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default GiphyButtonComponent;
import ComposerMiniAppPhoto from '../Block-SDK/src/Composer/ComposerMiniAppPhoto';
import { BlockTypes } from '../Block-SDK/src/blocks/types';

const SeamComposerPinnedBar = ({ chooseBlock }) => {
  return (
    <div>
      <h3>Pinned</h3>
      <div className="flex flex-row justify-between mt-4 mb-4 overflow-x-auto">
        <div onClick={() => chooseBlock(BlockTypes["image"])}>
          <ComposerMiniAppPhoto block={BlockTypes["image"]} size={60} />
        </div>
        <div onClick={() => chooseBlock(BlockTypes["Music"])}>
          <ComposerMiniAppPhoto block={BlockTypes["Music"]} size={60} />
        </div>
        <div onClick={() => chooseBlock(BlockTypes["video"])}>
          <ComposerMiniAppPhoto block={BlockTypes["video"]} size={60} />
        </div>
        <div onClick={() => chooseBlock(BlockTypes["Link Bookmark"])}>
          <ComposerMiniAppPhoto block={BlockTypes["Link Bookmark"]} size={60} />
        </div>
        <div onClick={() => chooseBlock(BlockTypes["giphy"])}>
          <ComposerMiniAppPhoto block={BlockTypes["giphy"]} size={60} />
        </div>
      </div>
    </div>
  );
};

export default SeamComposerPinnedBar;
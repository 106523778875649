import ComposerMiniAppPhoto from "../Block-SDK/src/Composer/ComposerMiniAppPhoto";

// add miniapps here that we want to highlight and tag as "New"
const newBlockTypes = ["ReviewThings", "MemeGen"];

function ComposerMiniAppItem({ block, isUnlocked, cost, isAffordable, selectAction, unlockAction }) {
  if (!block) {
    return null;
  }

  const createdBy = block.createdBy.map((split) => "@" + split.username).join(", ");
  
  const actionButton = (title, color, textColor, action) => {
    return (
      <button onClick={() => { action() }} className={`mt-2 bg-${color} py-3 rounded-lg text-${textColor}`} style={{ width: "100%" }}>
        {title}
      </button>
    );
  }
  
  const isNewBlock = newBlockTypes.includes(block.type);

  return (
    <div className="flex flex-col items-start w-full">
      <div className="flex flex-row items-start pb-2 justify-between w-full">
        <div className="flex flex-row h-full w-full items-start">
          <ComposerMiniAppPhoto block={block} size={60} />
          <div className="flex flex-col justify-center items-start ml-2">
            <div className="flex items-center">
              <h3 className='align-top'>{block?.displayName ?? "New Miniapp"}</h3>
              {isNewBlock && (
                <span className="ml-2 px-2 py-1 bg-seam-black text-white text-xs rounded-full">New</span>
              )}
            </div>
            <h4 className="text-[#86868A] w-full">{block?.displayDescription ?? "Update your app to get the new miniapp for the quest!"}</h4>
            <h4 className="mt-2 mb-2 text-gray">
              Created by: <span className="text-black">{createdBy}</span>
            </h4>
          </div>
      </div>
        {!isUnlocked && (
          <div className="flex w-auto whitespace-nowrap items-center text-seam-black text-sm mr-1">
            {cost}
            <span>&nbsp;</span>
            <img className="w-[14px] h-[14px]" src="https://upcdn.io/W142hWW/raw/Seam%20Assets/Seam%20Point.png"/>
            <span role="img" aria-label="lock">🔒</span>
          </div>
        )}
      </div>
      {!isUnlocked && isAffordable && actionButton("Add to library", "seam-blue", "white", unlockAction)}
      {!isUnlocked && !isAffordable && actionButton("Not enough SP", "seam-gray", "black", () => { })}
      {isUnlocked && actionButton("Use Miniapp", "seam-pink", "white", selectAction)}
    </div>
  );
}

export default ComposerMiniAppItem;
import { Capacitor } from '@capacitor/core';
import { FirebaseMessaging } from "@capacitor-firebase/messaging";
import QuestManager from '../Quests/QuestManager';
import { QuestTypes } from '../Quests/QuestTypes';
import mixpanel from 'mixpanel-browser';
import Parse from 'parse';

export const CheckNotificationPermission = async () => {
  const platform = Capacitor.getPlatform();

  if (platform === "ios") {
    const status = await FirebaseMessaging.checkPermissions();
    return status.receive;
  } else if (platform === "web") {
    if (!('Notification' in window)) {
      return 'unsupported';
    }
    if (Notification.permission === 'denied') {
      return 'denied';
    }
    if (Notification.permission === 'granted') {
      return 'granted';
    }
    return 'default';
  }
  return 'unsupported';
};

export const SubscribeToNotifications = async (account: Parse.Object) => {
  const platform = Capacitor.getPlatform();

  const permission = await CheckNotificationPermission();
  if (permission === 'denied' || permission === 'unsupported') {
    alert("Notifications are disabled or denied in your browser.");
    return;
  }

  if (platform === "ios") {
    const requestStatus = await FirebaseMessaging.requestPermissions();
    if (requestStatus.receive !== 'granted') {
      alert("Notifications are disabled or denied in your browser.");
      return;
    }

    const { token } = await FirebaseMessaging.getToken();
    if (token && account) {
      let sub = await SendAppleTokenToServer(token, account);
      return sub;
    }
  } else if (platform === "web") {
    if (typeof window.Notification.requestPermission === 'function') {
      const result = await window.Notification.requestPermission();
      if (result === "granted") {
        const registration = await navigator.serviceWorker.ready;
        if (registration) {
          const subscription = await registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: urlBase64ToUint8Array(process.env.REACT_APP_VAPID_PUBLIC_KEY || "")
          });
          let sub = await SendSubscriptionToServer(subscription);
          return sub;
        }
      }
    }
  }
};

export const UnsubscribeFromNotifications = async () => {
  const platform = Capacitor.getPlatform();

  if (platform === "ios") {
    const AppleSubscription = Parse.Object.extend("AppleSubscription");
    const query = new Parse.Query(AppleSubscription);
    query.equalTo("userId", Parse.User.current()?.id);
    const subscription = await query.first();
    if (subscription) {
      await subscription.destroy();
      await FirebaseMessaging.deleteToken();
    }
  } else if (platform === "web") {
    const browserName = getBrowserName();
    const Subscription = Parse.Object.extend("Subscription");
    const query = new Parse.Query(Subscription);
    query.equalTo("user", Parse.User.current()?.id);
    query.equalTo("browser", browserName);
    const subscription = await query.first();
    if (subscription) {
      await subscription.destroy();
    }
  }
}

function urlBase64ToUint8Array(base64String: string) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, '+')
    .replace(/_/g, '/');

  const rawData = atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

const SendAppleTokenToServer = async (token: string, account: Parse.Object | undefined) => {
  const currentUser = Parse.User.current();
  if (!currentUser) { return; }
  const userId = currentUser.id;

  const AppleSubscription = Parse.Object.extend("AppleSubscription");
  const query = new Parse.Query(AppleSubscription);
  query.equalTo("userId", userId);
  query.equalTo("token", token);

  try {
    const existingSubscription = await query.first();
    if (existingSubscription) {
      return existingSubscription;
    }

    const subscription = new AppleSubscription();
    subscription.set("userId", userId);
    subscription.set("token", token);

    await subscription.save();
    if (account) {
      QuestManager.completeQuest(QuestTypes.notifications, 1, account);
    }
    mixpanel.track("Enabled push notifications");
    return subscription;
  } catch (error) {
    console.error("Failed to save subscription on server:", error);
  }
}

const SendSubscriptionToServer = async (subscription: PushSubscription) => {
  const currentUser = Parse.User.current();
  const Subscription = Parse.Object.extend("Subscription");
  const Account = Parse.Object.extend("Account");
  const browserName = getBrowserName();
  if (!currentUser) {
    console.error("No user is currently logged in.");
    return;
  }

  const accountQuery = new Parse.Query(Account);
  accountQuery.equalTo("userId", currentUser.id);
  const account = await accountQuery.first();

  if (!account) {
    console.error("No account found for the current user.");
    return;
  }

  const userIdString = account.get("userId");
  const subscriptionQuery = new Parse.Query(Subscription);
  subscriptionQuery.equalTo("user", userIdString);
  subscriptionQuery.equalTo("browser", browserName);

  const existingSubscriptions = await subscriptionQuery.find();
  for (const sub of existingSubscriptions) {
    await sub.destroy();
  }

  const userSubscription = new Subscription();
  userSubscription.set("user", userIdString);
  const subscriptionJson = JSON.stringify(subscription);
  userSubscription.set("data", subscriptionJson);
  userSubscription.set("browser", browserName);
  userSubscription.set("badgeCount", 0);
  try {
    await userSubscription.save();
    QuestManager.completeQuest(QuestTypes.notifications, 1, account);
    mixpanel.track("Enabled push notifications");
    return userSubscription;
  } catch (error) {
    console.error("Failed to save subscription on server:", error);
  }
};

export const getBrowserName = () => {
  const userAgent = navigator.userAgent;
  let browserName = "Unknown";

  const browsers = [
    ["Chrome", "Chrome"],
    ["Safari", "Safari"],
    ["Firefox", "Firefox"],
    ["MSIE", "Internet Explorer"],
    ["Trident/", "Internet Explorer"],
    ["Edg", "Edge"],
    ["OPR", "Opera"],
  ];

  for (const [identifier, name] of browsers) {
    if (userAgent.includes(identifier)) {
      browserName = name;
      break;
    }
  }

  if (/Mobi|Android/i.test(userAgent)) {
    browserName += " Mobile";
  }

  return browserName;
};

export type QuestType = {
  name: string
  displayTitle: string
  description: string
  totalSteps: number
  value: number
}

export const QuestTypes: { [key: string]: QuestType } = {
  notifications: {
    name: "notifications", 
    displayTitle: "🔔 Notifications",
    description: "Turn on your notifications",
    totalSteps: 1,
    value: 30,
  },
  connections: {
    name: "connections",
    displayTitle: "👯‍♀️ Friends",
    description: "Add your first 5 friends",
    totalSteps: 5,
    value: 50,
  },
  referrals: {
    name: "referrals", 
    displayTitle: "Referrals", 
    description: "Refer 5 friends to join Seam",
    totalSteps: 1,
    value: 50,
  },
  collections: {
    name: "collections",
    displayTitle: "Collections",
    description: "Follow 25 collections", 
    totalSteps: 1, 
    value: 50,
  },
}
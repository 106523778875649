import { makeStyles } from "@mui/styles";
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import { Colors } from "../utils/colors"
import { Avatar } from "@mui/material";

const useStyles = makeStyles({
  cardTitle: {
    fontFamily: "Public Sans",
    fontWeight: 700,
    color: 'black',
  },
  cardSubtitle: {
    textAlign: "left",
    maxWidth: '100%',
    fontSize: 14,
    fontWeight: 300,
    color: 'black',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  listItemText: {
    maxWidth: 'calc(100% - 60px)',
    overflow: 'hidden',
  },
  cardItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    width: "95%",
    fontSize: "16px",
    color: Colors.SEAM_GRAY_100,
    cursor: "pointer",
    backgroundColor: "#EFEFEF",
    borderRadius: 24,
    marginBottom: '16px',
    marginLeft: '8px',
    marginRight: '8px',
    display: 'flex',
    alignItems: 'center',
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1)",
    border: '1px solid rgba(0, 0, 0, 0.1)',
    '&:hover': {
      backgroundColor: "#DADADA",
      boxShadow: "0 6px 12px rgba(0, 0, 0, 0.15)", 
    }
  },
  thumbnailBar: {
    width: "100%",
    height: "8px",
    display: "flex"
  },
  itemBackground: {
    marginTop: 16,
    backgroundColor: "white"
  }
});

export default function CardItem(props) {
  const { card, index, canDeleteCard, deleteCard, tapAction } = props
  const classes = useStyles();

  function randomColor() {
    var keys = Object.keys(Colors);
    return Colors[keys[keys.length * Math.random() << 0]];
  }

  const getAvatarUrl = (card) => {
    const blocks = card.get("blocks");
    if (blocks) {
      const profileBlock = blocks.find(block => block.type === "internal: profile" || block.type === "profile");
      return profileBlock ? profileBlock.data.imageURL : null;
    }
    return null;
  };

  return (
    <div className={classes.itemBackground} key={index}>
      <ListItem
        alignItems="flex-start"
        className={classes.cardItem}
        onClick={() => {
          tapAction(card, index)
        }}
        secondaryAction={!canDeleteCard ? null :
          <IconButton edge="end" onClick={(event) => {
            event.stopPropagation();
            deleteCard(card);
          }}>
            <DeleteIcon />
          </IconButton>
        }
      >
        <Avatar
          src={`${getAvatarUrl(card)}?w=64&h=64&fit=crop&auto=format`}
          style={{ height: 48, width: 48, marginRight: '12px',}}

        />
        <ListItemText
          classes={{ primary: classes.cardTitle, secondary: classes.cardSubtitle }}
          primary={card.get("title") ?? card.get("name")}
          secondary={card.get("description") ?? "No description"} 
          className={classes.listItemText}
          />
      </ListItem>
    </div>
  )
}
import ChannelStats from '../components/ChannelStats';
import ImageUploadComponent from '../components/ImageUploadComponent';
import { TagFacesOutlined, } from '@mui/icons-material';
import { Avatar, Typography, Skeleton, Stack } from '@mui/material';
import { useSeamNavigator } from '../Navigation/SeamNavigatorContext';
import SeamStar from "../assets/seam-star.png";
import { getProcessedChannelPhotoURL } from '../components/helpers/ImageTransformers';
import { Img } from 'react-image';
import { useSeamUser } from '../utils/SeamUserContext';
import { useState } from 'react';

const ChannelFeedHeader = ({ currentChannel, updateChannel, loading, openModal, darkMode, takingScreenshot }) => {
  const { account } = useSeamUser();
  const seamNavigator = useSeamNavigator();
  const channelName = currentChannel?.get("name") || "Collection Name";
  const [channelPicture, setChannelPicture] = useState(getProcessedChannelPhotoURL(currentChannel?.get("headerImage")) || SeamStar);
  const selectedEmoji = currentChannel?.get("emoji") || '';
  const isChannelCreator = currentChannel?.get("creator")?.id === account?.id;
  let textColor = darkMode ? 'white' : 'black';

  const ChannelNameInput = () => {
    return (
      <div className="flex items-center justify-center w-auto h-auto max-w-full cursor-pointer">
        <Typography variant="h2" className={`text-${textColor} ml-2 w-full text-center truncate`}>
          {channelName}
        </Typography>
      </div>
    );
  };

  const handleImageUpload = async (url) => {
    if (currentChannel) {
      currentChannel.set("headerImage", url);
      setChannelPicture(url); // needed to use the original photo while the downsized job is working on the backend
      updateChannel(currentChannel);
      const wasNew = currentChannel.isNew();
      await currentChannel.save();
      if (wasNew) {
        window.emitter.emit("SEAM_EVENT_CREATED_COLLECTION", { collection: currentChannel });
      }
    }
  };

  const ChannelFeedHeaderSkeleton = () => {
    return (
      <div className="w-full h-auto my-6">
        <div className="flex flex-col items-center space-y-6 justify-between w-full h-auto">
          <Skeleton variant="rectangular" width={90} height={90} className="rounded-[8px]" />

          <Stack direction="row" spacing={2} className="w-full max-w-[66%] items-center justify-center">
            <Skeleton variant="circular" width={30} height={30} />
            <Skeleton variant="text" width={150} height={40} />
          </Stack>

          <Skeleton variant="rectangular" width={200} height={40} />

          <Stack direction="row" spacing={1} alignItems="center">
            <Skeleton variant="text" width={80} height={20} />
            <Skeleton variant="circular" width={30} height={30} />
            <Skeleton variant="text" width={100} height={20} />
          </Stack>
        </div>
      </div>
    );
  };

  if (loading || !currentChannel) {
    return <ChannelFeedHeaderSkeleton />;
  }

  return (
    <div className="w-full h-auto my-6">
      <div className="flex flex-col items-center space-y-6 justify-between w-full h-auto">
        {/* Channel pic customization */}
        <div className="w-[90px] h-[90px] rounded-[8px]">
          {!isChannelCreator ? (
            <Img
              src={[currentChannel?.get("headerImage"), channelPicture]}
              className={`w-full h-full object-cover border-[1px] border-seam-${textColor} rounded-[8px]`}
            />
          ) : (
            <ImageUploadComponent
              fileTypes="image/*"
              basePath="channelHeader"
              onUpdate={handleImageUpload}
              backgroundImageUrl={channelPicture}
              fileName={currentChannel.id}
            />
          )}
        </div>

        {/* Emoji / channel name customization */}
        <div className="w-full max-w-[66%] relative flex flex-row items-center justify-center" onClick={() => {
          if (isChannelCreator) {
            openModal();
          }
        }}>
          <div
            className="rounded-full mr-2 flex items-center justify-center w-auto h-auto"
          >
            {selectedEmoji ? (
              <span style={{ fontSize: '24px' }}>{selectedEmoji}</span>
            ) : (
              <TagFacesOutlined className={`w-[10px] h-[10px] fill-seam-${textColor}`} />
            )}
          </div>
          <ChannelNameInput />
        </div>

        <ChannelStats
          currentChannel={currentChannel}
          handleModalOpen={() => openModal()}
          darkMode={darkMode}
          takingScreenshot={takingScreenshot}
        />

        <span className='flex flex-row items-center text-xs' onClick={() => { seamNavigator.navigateTo("/user/" + currentChannel?.get("creator")?.get("username"))}}>
          <Typography variant="h4" className={`text-${textColor} text-opacity-[40%]`}>Curated By: </Typography>
          <Avatar src={currentChannel?.get("creator")?.get("profilePhoto")} className="w-6 h-6 mx-2 cursor-pointer" />
          <Typography variant="h4" className={`text-${textColor} cursor-pointer underline underline-offset-2`}>{currentChannel?.get("creator")?.get("username")}</Typography>
        </span>
      </div>
    </div>
  );
};

export default ChannelFeedHeader;
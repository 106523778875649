import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button } from '@mui/material';
import { useState } from 'react';

function GroupNameEditDialog({ open, handleClose, handleSave, currentName }) {
  const [newName, setNewName] = useState(currentName);

  const handleNameChange = (event) => {
    setNewName(event.target.value);
  };

  const onSave = () => {
    handleSave(newName);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Edit Group Name</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Group Name"
          type="text"
          fullWidth
          variant="outlined"
          value={newName}
          onChange={handleNameChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={onSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
}

export default GroupNameEditDialog;
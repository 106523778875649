import InternalBlock from "./InternalBlock"
import { BlockModel } from '../Block-SDK/src/blocks/types'
import { Theme, Box, TextField, Button, Typography } from "@mui/material"
import TitleComponent from "../Block-SDK/src/blocks/utils/TitleComponent";
import Parse from 'parse';
import mixpanel from "mixpanel-browser";
import SeamUserFeed from "./WallBlock/SeamUserFeed";
import { useSeamNavigator } from "../Navigation/SeamNavigatorContext";

export default class InternalWallBlock extends InternalBlock {
  render(width?: string, height?: string) {
    let title = this.model.data["title"]

    return (
      <>
        {title && TitleComponent(title)}
        {InternalWall(this.model, this.theme, this.creatorAccount, this.account, this.currentCard, height)}
      </>
    )
  }

  renderEditModal(done: (data: BlockModel) => void) {
    const onFinish = (event: any) => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      let title = data.get('title') as string
      let prompt = data.get('prompt') as string
      this.model.data['title'] = title
      this.model.data['prompt'] = prompt
      done(this.model)
    };

    var prompt = this.model.data['prompt'] ?? "Write a post..."

    return (
      <Box
        component="form"
        onSubmit={onFinish}
        style={{}}
      >
        <TextField
          margin="normal"
          fullWidth
          id="title"
          label="Wall Title"
          name="title"
          defaultValue={this.model.data['title']}
        />
        <TextField
          margin="normal"
          fullWidth
          id="prompt"
          label="Wall Prompt"
          name="prompt"
          defaultValue={prompt === "" ? "Write a post..." : prompt}
        />
        <Button
          type="submit"
          variant="contained"
          className="save-modal-button"
          sx={{ mt: 3, mb: 2 }}
        >
          Save
        </Button>
      </Box>
    )
  }

  renderErrorState() {
    return (
      <h1>Error: Couldn't render the profile header.</h1>
    )
  }
}

function InternalWall(model: BlockModel, theme: Theme, creatorAccount: Parse.Object, account: Parse.Object, currentCard: Parse.Object, height?: string) {
  const isAuthenticated = Parse.User.current() !== undefined
  const seamNavigator = useSeamNavigator();

  const shouldBlur = !isAuthenticated || account === undefined
  const wrapperStyle = shouldBlur ? { filter: "blur(5px)" } : { height: "100%" }

  function onTap() {
    // if the person tapping add friend isn't logged into Seam yet, send them to the homepage (with a referral)
    mixpanel.track("tapped login from wall block")
    if (!isAuthenticated) {
      const url = '/?ref=' + creatorAccount?.get('referralCode');
      // when we are embedded, like in the pixels flow, we'll want to open in new window
      seamNavigator.navigateTo(url)
      return
    }
  }

  const LoginPrompt = () => {
    return (
      <div style={{ zIndex: 1, height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'fixed', padding: 12, flexDirection: "column" }}>
        <Typography style={{ fontFamily: "Public Sans", fontSize: 18, color: "#222044", textAlign: "center" }}> You must be logged in to see the posts on this wall block. </Typography>
        <Button variant="contained" onClick={() => { onTap() }} sx={{ backgroundColor: "#E4FF42", "&:hover": { backgroundColor: "#E4FF42" } }}>
          <Typography style={{ fontFamily: "VT323", fontSize: 24, color: "#222044" }}> Login to Seam </Typography>
        </Button>
      </div>
    )
  }

  return (
    <>
      {shouldBlur ? <LoginPrompt /> : null}
      <div style={wrapperStyle}>
        <div style={{ padding: 12, paddingBottom: 0, height: "100%" }}>
          {SeamUserFeed(currentCard, creatorAccount, "card", (
            <>
            </>
          ))}
        </div>
      </div>
    </>
  );
}
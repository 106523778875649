import { Avatar } from "@mui/material"
import { makeStyles } from "@mui/styles";
import IconsRow from '../Block-SDK/src/blocks/utils/IconsRow'

const useStyles = makeStyles({
  friendButton: {
    border: "1px solid",
    borderColor: "#CACACA",
    color: "black",
    width: "33%"
  },
});

export default function InternalProfile(model, theme, creatorAccount, account) {
  const classes = useStyles();

  let title = model.data["title"]
  let bio = model.data["bio"]
  const url = model.data["imageURL"]
  const eligibleForResize = !url.includes(".gif")
  let imageURLRaw = url + "?w=320&h=320"
  let imageURLProcessed = eligibleForResize ? imageURLRaw.replace('raw', 'image') : url;

  let icons = model.data['icons']

  let primaryColor = theme.palette.primary.main
  let secondaryColor = theme.palette.secondary.main
  let teritaryColor = theme.palette.info.main

  return (
    <div style={{
      display: "flex",
      height: "100%",
      width: "100%",
      flexDirection: "column",
      alignItems: "center",
      paddingLeft: "24px",
      paddingRight: "24px",
      backgroundColor: secondaryColor
    }}>
      {imageURLProcessed && <Avatar src={imageURLProcessed} style={{ width: "160px", height: "160px", marginTop: "10px" }}></Avatar>}
      <h2 style={{ textAlign: "center", marginTop: "16px", color: teritaryColor }}> {title} </h2>
      <h4 style={{ textAlign: "center", marginBottom: "16px", color: teritaryColor }}> {bio} </h4>
      <IconsRow icons={icons} color={teritaryColor} />
    </div>
  );
}

// this is the profile picture on the left side square

{/* <div style={{ height: "100%", width: "100%", backgroundColor: secondaryColor, display: "flex" }}>
      <Stack direction={"column"} style={{ backgroundColor: secondaryColor, margin: "32px" }}>
        <Stack direction={"row"} style={{paddingRight: "4px"}}>
          {imageURL && <img src={imageURL} style={{ width: "160px", height: "160px", border: `2px solid`, borderColor: teritaryColor }}></img>}
          <Stack direction={"column"} style={{ marginLeft: "16px" }}>
            <h2 style={{ marginTop: "16px", color: teritaryColor }}> {title} </h2>
            <h4 style={{ marginBottom: "16px", color: teritaryColor }}> {bio} </h4>
            <IconsRow icons={icons} color={teritaryColor} />
          </Stack>
        </Stack>
        <Stack direction={"row"} style={{ paddingTop: 10 }}>
          {!isSelfProfile &&
            <Button
              sx={{ color: teritaryColor, borderRadius: 0 }}
              style={{ border: "1px solid" }}
              size="large"
              onClick={() => { onTap() }}
              variant="outlined"
              startIcon={friendsIcon}> {friendsLabel}
            </Button>}
        </Stack>
      </Stack>
    </div> */}
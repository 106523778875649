import React from "react";
import { makeStyles } from "@mui/styles";
import { Layout } from "react-feather";
import Parse from 'parse';
import { Button } from "@mui/material";

const useStyles = makeStyles({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent:'center', 
    height: 'calc(100vh - 250px)'
  },
  spacer: {
    flexGrow: 1
  },
  body: {
    flexGrow: 1
  }
});

export default function EmptyState({ onAddBlock, isInEditMode, canEdit }) {
  const classes = useStyles();
  const isAuthenticated = Parse.User.current() != undefined
  const titleString = canEdit ? "Ahhh, we love a fresh start" : "🚧 Nothing to see here yet 🚧"
  const showEditingButton = isAuthenticated && canEdit

  return (
    <div className={classes.root}>
      <Layout size={48} />
      <h1>{titleString}</h1>
      {!isAuthenticated && <h3>Connect your wallet to see if you have edit access</h3>}
      {isInEditMode && showEditingButton && <Button onClick={() => {
        onAddBlock("profile")
      }}>Add a block</Button>}
      {!isInEditMode && showEditingButton && <Button onClick={() => {
        window.emitter.emit("SEAM_EVENT_TOGGLE_MODE", "edit")
      }}>Edit this Card</Button>}
    </div>
  );
}
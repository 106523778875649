import { BlockType } from "../Block-SDK/src/blocks/types"
import postIcon from "./icons/postIcon.png"

export const InternalBlockTypes: { [key: string]: BlockType } = {
  "internal: wall": {
    type: "internal: wall",
    displayName: "Wall ",
    displayDescription: "Create a wall that you and anyone can post on.",
    icon: postIcon,
    deprecated: true,
    doesBlockPost: false,
    doesBlockEdit: false,
    createdBy: [{username: "seam", split: 1}],
    fullscreenEdit: false,
  },
}
import ComposerMiniAppItem from "./ComposerMiniAppItem";
import SeamComposerPinnedBar from "./SeamComposerPinnedBar";
import { X } from "react-feather";
import { Divider } from "@mui/material";
import { IonList } from "@ionic/react";
import { nanoid } from "nanoid";
import { BlockTypes } from "../Block-SDK/src/blocks/types";
import { InternalBlockTypes } from "../InternalBlocks/InternalBlockTypes";
import SeamPointBanner from "../components/SeamPointBanner";

const SeamComposerChooseBlockStep = ({ account, dismiss, chooseBlock, composerTitle, postingMiniappsOnly, unlockableMiniappsOnly, pageEditCompletion, unlockAction }) => {
  let supportedExternalBlocks = Object.entries(BlockTypes).filter(
    (blockType) =>
      !blockType[1].deprecated &&
      (postingMiniappsOnly ? blockType[1].doesBlockPost : true) &&
      (unlockableMiniappsOnly ? blockType[1].createdBy[0].username !== "seam" : true)
  );
  let supportedBlocks = postingMiniappsOnly
    ? supportedExternalBlocks
    : [...supportedExternalBlocks, ...Object.entries(InternalBlockTypes)];

  const isMiniAppUnlocked = (block) => {
    const isMiniappOwned = account?.get("unlockedMiniapps")?.includes(block.type);
    const isDefaultMiniapp = block.createdBy[0].username === "seam";
    return isMiniappOwned || isDefaultMiniapp;
  };

  const isMiniAppAffordable = (block) => {
    const sp = account?.get("sp") ?? 0;
    return sp >= (block.unlockAmount || 30); // All miniapps cost 30 to unlock for now
  };

  const selectAction = (block) => {
    if (!isMiniAppUnlocked(block)) {
      unlockAction(block);
      return;
    }

    const canBlockEdit = block.doesBlockEdit;
    if (canBlockEdit) {
      chooseBlock(block);
    } else {
      const model = {
        type: block.type,
        data: {},
        uuid: nanoid(),
      };
      pageEditCompletion(model);
    }
  };

  return (
    <div className={"my-0 h-full px-4"}>
      <div className={"flex items-center justify-between bg-white"}>
        <div className="flex items-start flex-col mt-4">
          <h2>Create Something</h2>
          <h3>{composerTitle}</h3>
        </div>
        <div
          onClick={() => {
            dismiss();
          }}
          className="flex justify-center items-center gap-2 py-2 px-2 text-white rounded-full bg-[#efefef]"
        >
          <X color="black" />
        </div>
      </div>
      {account && <SeamPointBanner />}
      {!unlockableMiniappsOnly && <SeamComposerPinnedBar chooseBlock={chooseBlock} />}
      <Divider className="mt-4 mb-4" />
      <h3 className="mb-4">All miniapps</h3>
      <IonList>
        {supportedBlocks.map((block) => (
          <div className="my-4" id={block[1].type}>
            <ComposerMiniAppItem
              block={block[1]}
              isUnlocked={isMiniAppUnlocked(block[1])}
              isAffordable={isMiniAppAffordable(block[1])}
              const={30}
              selectAction={() => { selectAction(block[1]); }}
              unlockAction={() => { unlockAction(block[1]); }}
            />
            <Divider className="my-4" />
          </div>
        ))}
        <div style={{ height: "100px" }} />
      </IonList>
    </div>
  );
}

export default SeamComposerChooseBlockStep;
import React, { useState, useEffect, memo } from 'react';
import Parse from 'parse';
import { IonPage, IonContent, IonModal, useIonViewWillEnter, useIonViewWillLeave } from '@ionic/react';
import Header from '../Navigation/Header';
import { ChevronLeft, LibraryAddOutlined, PaletteOutlined, Search } from '@mui/icons-material';
import { Typography } from '@mui/material';
import ProfilePageHeader from './ProfilePageHeader';
import { useSeamUser } from '../utils/SeamUserContext';
import { useSeamNavigator } from '../Navigation/SeamNavigatorContext';
import { useHistory } from 'react-router';
import ProfileSettingsModal from './ProfileSettingsModal';
import CollectionsFeed from './CollectionsFeed';
import SeamChannelRepository from '../Channels/SeamChannelRepository';
import "./Profile.css"
import DesktopSidebarWrapper from '../components/DesktopSidebarWrapper';
import { useMobile } from '../utils/MobileContext';
import FriendRequestButton from '../InternalBlocks/FriendRequestButton';
import WelcomeFromWebCreatedAccountPage from '../components/WelcomeFromWebCreatedAccountPage';
import SeamPillButton from '../Block-SDK/src/components/SeamPillButton';
import { Seo } from '../utils/Seo';
import ClaimGate from '../components/ClaimGate';

const UserProfilePage = ({ username, takingScreenshot = false }) => {
  const { account, setRecentlyUpdatedChannel } = useSeamUser();
  const { isMobile, isMobileApp } = useMobile();
  const history = useHistory();
  const { contextObject, navigateTo } = useSeamNavigator();
  const [creatorAccount, setCreatorAccount] = useState(contextObject?.get("profileId") == username ? contextObject : undefined);
  const [isActivePage, setIsActivePage] = useState(true);
  const [showSettings, setShowSettings] = useState(false);
  const [profileCustomizations, setProfileCustomizations] = useState(undefined);
  const [isSelfProfile, setIsSelfProfile] = useState(username === account?.get("profileId"));
  const [userExists, setUserExists] = useState(null);

  useEffect(() => {
    const fetchCreatorAccount = async () => {
      const userQuery = new Parse.Query("Account");
      userQuery.equalTo("profileId", username);
      const user = await userQuery.first();
      setCreatorAccount(user);
      setUserExists(!!user); // Sets true if user exists, otherwise false
    };

    // Fetch account if username doesn't match existing profile
    if (username && username !== creatorAccount?.get("profileId")) {
      fetchCreatorAccount();
    }
    setIsSelfProfile(username === account?.get("profileId"));
  }, [username, account]);

  useEffect(() => {
    const fetchCreatorCustomizations = async () => {
      const profileCustomizationsQuery = new Parse.Query("ProfileCustomizations");
      profileCustomizationsQuery.equalTo("account", creatorAccount);
      const profileCustomizations = await profileCustomizationsQuery.first();
      setProfileCustomizations(profileCustomizations || null);
    }
    fetchCreatorCustomizations();
  }, [creatorAccount]);

  useIonViewWillEnter(() => {
    setIsActivePage(true);
  });

  useIonViewWillLeave(() => {
    setIsActivePage(false);
  });

  if (userExists === null) {
    return null;
  }

  // for desktop onboarding
  if (account && account.get("isOnboarded") !== true) {
    return <WelcomeFromWebCreatedAccountPage />
  }

  const leftAction = isMobileApp ? () => { } : () => history.go(-1);

  // Check for either the background image or the color gradient
  const backgroundImage = profileCustomizations?.get("backgroundImage");
  const color1 = profileCustomizations?.get("color1") || "#FAFAFA";
  const color2 = profileCustomizations?.get("color2") || "undefined";
  
  let darkMode = (profileCustomizations?.get("fontColor") === "white");

  let color = darkMode ? 'white' : 'black';
  let bgColor = darkMode ? 'black' : 'white';

  let backgroundStyle;
  if (backgroundImage) {
    backgroundStyle = { backgroundImage: `url(${backgroundImage})`, backgroundColor: "white" };
  } else if (color2 && color2 !== 'undefined' && color2 !== '') {
    backgroundStyle = { backgroundImage: `linear-gradient(to bottom, ${color1}, ${color2})` };
  } else {
    backgroundStyle = { backgroundColor: color1 };
  }

  const leftIcon = () => {
    if (takingScreenshot) return null;

    return (
      <>
        {isSelfProfile ? (
          <div onClick={() => setShowSettings(true)} className={`flex ${isMobile ? 'ml-4' : 'ml-[48px]'} cursor-pointer flex-row items-center justify-center space-x-2 py-2 px-2 bg-white bg-opacity-10 border-[1px] border-${bgColor} border-opacity-[10%] rounded-[24px]`}>
            <PaletteOutlined className={`fill-${color} w-4 h-4`} />
            <Typography variant="h4" className={`text-${color}`}>
              Customize
            </Typography>
          </div>
        ) : (
          <div className={`${isMobile ? 'ml-4' : 'ml-[48px]'}`}>
            <SeamPillButton
              icon={<ChevronLeft className={`text-${color} h-4 w-4`} />}
              label={null}
              darkMode={darkMode}
              onClick={() => {history.go(-1)}}
              isOnChannelPage={true}
            />
          </div>
        )}
      </>
    );
  }

  const rightIcon = () => {
    if (takingScreenshot) return null;

    return (
      <>
        {isSelfProfile && account ?
          <div 
            className={`flex ${isMobile ? 'mr-4' : 'mr-[48px]'} flex-row h-auto cursor-pointer items-center justify-center space-x-2 py-2 px-[20px] ${darkMode ? 'bg-[#0E51CE]' : 'bg-seam-blue'} rounded-[24px]`} 
            onClick={() => {
            const emptyChannel = SeamChannelRepository.emptyChannel({
              creator: account,
              name: "New Collection",
              emoji: "😊",
              headerImage: "",
            });
            setRecentlyUpdatedChannel(emptyChannel);
            const id = emptyChannel.id;
            navigateTo(`/collection/${id}`, emptyChannel);
          }}
          >
            <LibraryAddOutlined
              className="w-4 h-4 fill-white"
            />
            <Typography variant="h4" className="text-seam-white">
              New
            </Typography>
          </div>
          : <div className={`flex ${isMobile ? 'mr-4' : 'mr-[48px]'} flex-row cursor-pointer items-center justify-center space-x-2`}>
            <FriendRequestButton creatorAccount={creatorAccount} variant="small" darkMode={darkMode}/>
          </div>
        }
      </>
    )
  }

  const seoTitle = `${creatorAccount.get("profileId")}'s profile`;
  const seoDescription = `Join ${creatorAccount.get("profileId")} on Seam today!`;
  const seoImage = `https://storage.googleapis.com/seam-social-posts/profiles/${creatorAccount.get("profileId")}.jpg`;

  if (!userExists) {
    return <ClaimGate />; 
  }
  
  if (creatorAccount === undefined) {
    return <></>
  }

  return (
    <IonPage className="profilePage w-full h-full" style={{marginTop: 'calc(-1 * env(safe-area-inset-top))', height: 'calc(100vh + env(safe-area-inset-top)'}}>
      <Seo
        title={seoTitle}
        description={seoDescription}
        author={"@" + creatorAccount.get("profileId")}
        imageURL={seoImage}
        url={"https://www.seam.so/user/" + creatorAccount.get("profileId")}
      />
      <Header
        isActivePage={isActivePage}
        leftIcon={isMobile ? leftIcon() : null}
        rightIcon={isMobile ? rightIcon() : null}
        leftAction={() => { }}
        centerIcon={isMobileApp ? <></> : null}
        includePadding={true}
        darkMode={darkMode}
      />
      <IonContent fullscreen={true} scrollY={false}>
        <div className={`absolute top-0 w-full h-full`}
          style={{
            ...backgroundStyle,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            height: 'calc(100vh + env(safe-area-inset-top))',
            marginTop: 'calc(-1 * env(safe-area-inset-top))',
            zIndex: 0
          }}
        />
        <DesktopSidebarWrapper 
          showDesktopHeader={true} 
          leftIcon={leftIcon()}
          rightIcon={rightIcon()}
          navSection={"profile"}
          darkMode={darkMode}
        >
          <div className="w-full h-full z-0 flex flex-col items-start justify-start space-y-6">
            <div className={`w-full h-full z-0 flex flex-col items-start justify-start hide-scrollbar overflow-y-auto ${isMobile ? 'pt-[48px]' : 'pt-6 max-w-[720px]'}`}>
              <ProfilePageHeader creatorAccount={creatorAccount} profileCustomizations={profileCustomizations} isSelfProfile={isSelfProfile} darkMode={darkMode}/>
              <CollectionsFeed creatorAccount={creatorAccount} profileCustomizations={profileCustomizations} darkMode={darkMode}/>
            </div>
          </div>

          <IonModal
            isOpen={showSettings}
            onDidDismiss={() => setShowSettings(false)}
            swipeToClose={true}
            canDismiss={true}
            className={`h-full`}
            breakpoints={[0, 1]}
            initialBreakpoint={1}
          >
            <ProfileSettingsModal
              profileCustomizations={profileCustomizations}
              updateProfile={(updated) => { setProfileCustomizations(updated) }}
              close={() => setShowSettings(false)} />
          </IonModal>
        </DesktopSidebarWrapper>
      </IonContent>
    </IonPage>
  );
};

export default memo(UserProfilePage);
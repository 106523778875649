export const getProcessedProfilePictureURL = (rawProfileURL) => {
  const isFirebaseStorage = rawProfileURL?.includes("firebasestorage")
  const isBytescaleStorage = rawProfileURL?.includes("upcdn")
  if (isFirebaseStorage) {
    const regex = /\/([^\/?#]+)([^\/]*$)/;
    const modifiedUrl = rawProfileURL.replace(regex, (match, filename, rest) => {
      const modifiedFilename = `${filename}_64x64`;
      return `/${modifiedFilename}${rest}`;
    });

    return modifiedUrl
  } else if (isBytescaleStorage) {
    let imageURLRaw = rawProfileURL + "?w=160&h=160&q=70"
    return imageURLRaw.replace('raw', 'image')
  } else {
    return rawProfileURL
  }
}

export const getProcessedChannelPhotoURL = (rawChannelURL) => {
  if (rawChannelURL && rawChannelURL.includes("channelHeader")) { // all processed header images live in the firestore bucket with the channelHeader path
    return rawChannelURL.replace(/(.*\/)([^?]*)(\?alt=media.*)/, "$1$2_512x512$3");
  } else {
    return rawChannelURL // images uploaded earlier than 10/6/2024 are not processed; eventually remove this case
  }
}
import InternalBlock from "./InternalBlock"
import { Theme } from "@mui/material"
import { BlockModel } from '../Block-SDK/src/blocks/types'
import InternalButtonBlock from './InternalButtonBlock'
import InternalConnectionsBlock from './InternalConnectionsBlock'
import InternalGroupMembersBlock from "./InternalGroupMembersBlock"
import InternalProfileBlock from './InternalProfileBlock'
import InternalWallBlock from "./InternalWallBlock"

export default class InternalBlockFactory {
  // we need both the creator of the card's account and the viewer's account to know if they are friends
  static getBlock(
    model: BlockModel,
    theme: Theme,
    creatorAccount: Parse.Object,
    account: Parse.Object,
    currentCard: Parse.Object,
    creatorUserId: string): InternalBlock {
    model.data["userId"] = creatorUserId // setting this early allows for faster loading of cards block (don't have to wait for the whole creatorAccount)
    switch (model.type) {
      case "internal: cards":
      case "internal: channels": return new InternalButtonBlock(model, theme, creatorAccount, account, currentCard)
      case "internal: connections": return new InternalConnectionsBlock(model, theme, creatorAccount, account, currentCard)
      case "internal: groupMembers": return new InternalGroupMembersBlock(model, theme, creatorAccount, account, currentCard)
      case "internal: profile": return new InternalProfileBlock(model, theme, creatorAccount, account, currentCard)
      case "internal: wall": return new InternalWallBlock(model, theme, creatorAccount, account, currentCard)
      default: return new InternalButtonBlock(model, theme, creatorAccount, account, currentCard)
    }
  }

  static getPrintableBlockName(model: BlockModel): string {
    return "Internal Block"
  }

  static getMinHeightForBlock(model: BlockModel): number {
    const DEFAULT_HEIGHT = 10
    return DEFAULT_HEIGHT
  }

  static getMinWidthForBlock(model: BlockModel): number {
    const DEFAULT_WIDTH = 1
    return DEFAULT_WIDTH
  }
}

import UsernameTextField from "../components/UsernameTextField";
import { Stack, Divider, FormControlLabel, Checkbox, styled } from "@mui/material";
import React from "react";
import animatedLogo from '../assets/Seam_Shifty_Yellow.json';

const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
  color: '#000', // unchecked color
  '&.Mui-checked': {
    color: '#000', // checked color
  },
}));

function ProfileBlockPage({
  setValidUsername,
  setUsername,
  setDisplayName,
  username,
}) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animatedLogo,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div className="flex flex-col w-full h-full items-center justify-start">
      <div className="flex flex-col items-center justify-center space-y-4">
        <img 
          src="https://firebasestorage.googleapis.com/v0/b/seam-static-assets/o/BlackLogo.svg?alt=media"
          className="mt-4 h-[70px] w-[150px]"
        />
      </div>
      <UsernameTextField
        startingValue={username}
        onValidChange={(isValid) => setValidUsername(isValid)}
        onValueChange={(username) => {
          setUsername(username);
          setDisplayName(username);
        }}
      />
      <Divider style={{ margin: 0 }} />
    </div>
  );
}

export default ProfileBlockPage;
import { useContext } from "react";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { Popover, List, ListItem, ListItemText } from "@mui/material";
import { MoreHoriz } from "@mui/icons-material";
import * as Sentry from "@sentry/react";
import { SeamUserContext, useSeamUser } from "../utils/SeamUserContext";
import SeamPostRepository from "../Post/SeamPostRepository";
import SeamChannelRepository from "../Channels/SeamChannelRepository";
import mixpanel from "mixpanel-browser";
import { Clipboard } from "@capacitor/clipboard";

const useStyles = makeStyles({
  report: {
    color: "red"
  },
});

export default function FeedItemOptions(props) {
  const { post, collection, postInCollection, ownerAccount, isOnChannelPage } = props
  const { account } = useSeamUser();
  const { isAdmin } = useContext(SeamUserContext);
  const classes = useStyles();
  const isSelfPost = post?.get("author")?.id === account?.id
  const isProfileOwner = account?.id === ownerAccount?.id;
  const canDelete = isSelfPost || isProfileOwner
  const [anchorEl, setAnchorEl] = useState(null);
  const [postReported, setPostReported] = useState(false);
  const isSettingsOpen = Boolean(anchorEl);

  const handlePopOverClose = () => {
    setAnchorEl(null);
  };

  const handleCopyLink = async () => {
    const objectId = post.id;
    const url = `https://seam.so/post/${objectId}`;

    try {
      await Clipboard.write({
        string: url
      });
      alert("Link copied to clipboard!");
      mixpanel.track("share via link", { 'url': url });
    } catch (err) {
      console.error("Failed to copy link: ", err);
    }
    handlePopOverClose();
  };

  const postOptionsPopover = () => {
    return (
      <Popover
        id={"settings"}
        open={isSettingsOpen}
        anchorEl={anchorEl}
        onClose={handlePopOverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        <List>
          {isOnChannelPage && <ListItem
            button
            key={"channelDelete"}
            onClick={async () => {
              try {
                mixpanel.track("post_removed_from_channel");
                await SeamChannelRepository.removePostFromChannel(
                  post,
                  postInCollection,
                  () => {}
                );
            
                window.emitter.emit('SEAM_EVENT_DELETE_POST', post.id);
              } catch (error) {
                Sentry.captureException(error);
              }
              handlePopOverClose();
            }}
          >
            <ListItemText primary={"Remove from Collection"} />
          </ListItem>}
        {!isSelfPost && <ListItem
          button
          key={"flag"}
          onClick={async () => {
            setPostReported(true);
            try {
              mixpanel.track("post_reported")
              await SeamPostRepository.flag(account?.get("userId"), post)
            } catch (error) {
              Sentry.captureException(error)
            }
            window.alert("Thanks for reporting -- we'll take a look!")
            handlePopOverClose()
          }}>
          <ListItemText
            classes={{ primary: classes.report }}
            primary={"Report"}
          />
        </ListItem>}
        {canDelete && <ListItem
          button
          key={"delete"}
          onClick={async () => {
            try {
              await SeamPostRepository.deletePost(post)
            } catch (error) {
              console.log(error)
              Sentry.captureException(error)
            }
            handlePopOverClose()
          }}>
          <ListItemText
            classes={{ primary: classes.report }}
            primary={"Delete"}
          />
        </ListItem>}
        {!isSelfPost && <ListItem
          button
          key={"block"}
          onClick={async () => {
            if (window.confirm("Are you sure you want to block " + ownerAccount?.get("profileId") + "? \n\n You won't see their posts anymore.")) {
              try {
                mixpanel.track("user_blocked")
                account.addUnique("blockedUsers", ownerAccount)
                account.save()
                  .then((updatedAccount) => {
                    // The account was successfully updated
                    console.log('Blocked user added:', updatedAccount);
                    window.emitter.emit("SEAM_EVENT_DELETE_POST", post.id)
                    alert(ownerAccount?.get("profileId") + " has been blocked.")
                  }, (error) => {
                    // The save failed. Error is an instance of Parse.Error.
                    console.error('Error while adding blocked user:', error);
                  });
              } catch (error) {
                console.log(error)
                Sentry.captureException(error)
              }
              handlePopOverClose()
            }
          }}>
          <ListItemText
            classes={{ primary: classes.report }}
            primary={"Block User"}
          />
        </ListItem>}
        {isAdmin && <ListItem
          button
          key={"admin-delete"}
          onClick={async () => {
            try {
              SeamPostRepository.deletePost(post);
            } catch (error) {
              console.log(error)
            }
            handlePopOverClose()
          }}>
          <ListItemText
            classes={{ primary: classes.report }}
            primary={"[ADMIN]: DELETE"}
          />
        </ListItem>}
        {isAdmin && <ListItem
          button
          key={"admin-hide"}
          onClick={async () => {
            try {
              SeamPostRepository.hidePost(post);
            } catch (error) {
              console.log(error)
            }
            handlePopOverClose()
          }}>
          <ListItemText
            classes={{ primary: classes.report }}
            primary={"[ADMIN]: HIDE"}
          />
        </ListItem>}
        {isAdmin && <ListItem
          button
          key={"admin-nsfw"}
          onClick={async () => {
            try {
              SeamPostRepository.addNSFWTag(post);
            } catch (error) {
              console.log(error)
            }
            handlePopOverClose()
          }}>
          <ListItemText
            classes={{ primary: classes.report }}
            primary={"[ADMIN]: ADD NSFW TAG"}
          />
        </ListItem>}
      </List>
      </Popover >
    );
}

const optionsPopover = postOptionsPopover()
const textColor = isOnChannelPage ? `text-${collection.get("fontColor") || 'black'}` : "text-gray-500";

return (
  <>
    <div className={`flex items-center justify-center ${isOnChannelPage && `bg-transparent`}`} style={{ marginLeft: "auto" }} onClick={(event) => {
      if (!account) {event.preventDefault()}
      else setAnchorEl(event.currentTarget);
    }}>
      <MoreHoriz className={`${textColor} h-4 w-4`}/>
    </div>
    {optionsPopover}
  </>
)
}
import { makeStyles } from "@mui/styles";
import Parse from 'parse';
import useParseQuery from "../utils/useParseQuery";
import InfiniteScroll from "react-infinite-scroll-component";
import TemplateItem from "./TemplateItem"
import mixpanel from "mixpanel-browser";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row"
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column"
  },
  spacer: {
    flexGrow: 1
  },
  body: {
    padding: "0.5rem",
    flexGrow: 1
  },
  templateItem: {
    height: 30,
    width: "33%",
    border: "1px solid green",
    margin: 6,
    padding: 8
  }
});

export default function TemplateList(props) {
  const { onClick } = props
  const classes = useStyles();
  const Template = Parse.Object.extend("Template");
  Parse.Object.registerSubclass("Template", Template);

  const query = new Parse.Query("Template")
  query.limit(100)
  const { results, isLoading } = useParseQuery(query);
  const data = isLoading ? [] : results

  return (
    <div className={classes.root}>
      <h2>Choose a Template</h2>
      <h4>Use Seam to create a card for your proposal</h4>
      <InfiniteScroll
        dataLength={data.length}
        next={() => { }}
        style={{ display: 'flex', flexDirection: 'column-reverse' }} 
        hasMore={false}
        loader={<h4>Loading...</h4>}
      >
        {data.map((template, index) => (
          <TemplateItem
          title={template.get("name")}
          image={null}
          caption="" // TODO: add the creator when we have usernames to populate
          onClick={() => {
            mixpanel.track("create: chose template")
            onClick(template)
          }}
          key={template.id}
        /> 
        ))}
      </InfiniteScroll>
    </div>
  )
}
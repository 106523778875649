import React, { useState, useEffect, useRef } from 'react';
import { useMobile } from '../utils/MobileContext';
import GroupPageHeader from './GroupPageHeader';
import { useParams } from "react-router-dom";
import Parse from 'parse';
import { Plus, Search, Send } from 'react-feather';
import GroupNameEditDialog from './GroupNameEditDialog';
import GroupPhotoEditDialog from './GroupPhotoEditDialog';
import { useSeamUser } from '../utils/SeamUserContext';
import { IonPage, IonContent } from '@ionic/react';
import { useSeamNavigator } from '../Navigation/SeamNavigatorContext';
import Header from '../Navigation/Header';
import SeamComposer from '../Composer/SeamComposer';
import SeamUserFeed from '../InternalBlocks/WallBlock/SeamUserFeed';

const GroupPage = ({ }) => {
  const { isMobile, isMobileApp } = useMobile();
  const seamNavigator = useSeamNavigator();
  const { groupId } = useParams();
  const [currentGroup, setCurrentGroup] = useState(null);
  const [creatorAccount, setCreatorAccount] = useState(null);
  const [optimisticPost, setOptimisticPost] = useState(undefined);
  const [isComposerModalOpen, setIsComposerModalOpen] = useState(false);
  const [error, setError] = useState(null);

  const [isGroupNameEditOpen, setIsGroupNameEditOpen] = useState(false);
  const [isGroupPhotoEditOpen, setIsGroupPhotoEditOpen] = useState(false);

  const openGroupNameEdit = () => setIsGroupNameEditOpen(true);
  const closeGroupNameEdit = () => setIsGroupNameEditOpen(false);

  const openGroupPhotoEdit = () => setIsGroupPhotoEditOpen(true);
  const closeGroupPhotoEdit = () => setIsGroupPhotoEditOpen(false);

  const onEditGroup = (action) => {
    switch (action) {
      case 'editName':
        setIsGroupNameEditOpen(true);
        break;
      case 'editPhoto':
        setIsGroupPhotoEditOpen(true);
        break;
      case 'addMember':
        break;
      default:
        console.error('Unknown action:', action);
    }
  };

  const saveNewGroupName = async (newName) => {
    if (currentGroup) {
      currentGroup.set("name", newName);
      currentGroup.set("customHandle", true);
      try {
        await currentGroup.save();
        setCurrentGroup(currentGroup);
      } catch (error) {
        console.error("Error updating group name: ", error);
      }
    }
    closeGroupNameEdit();
  };

  const saveNewGroupPhoto = async (photoUrl) => {
    if (currentGroup && photoUrl) {
      currentGroup.set("headerPhoto", photoUrl);
      try {
        await currentGroup.save();
        setCurrentGroup(currentGroup);
      } catch (error) {
        console.error("Error updating group photo: ", error);
      }
    }
    closeGroupPhotoEdit();
  };

  useEffect(() => {
    const Group = Parse.Object.extend("Group");
    const query = new Parse.Query(Group);
    query.equalTo("objectId", groupId);

    query.first().then(async (group) => {
      setCurrentGroup(group);
    }).catch((error) => {
      console.error("Error fetching group: ", error);
    });
  }, [groupId]);

  const handlePostCompletion = (newPost) => {
    setIsComposerModalOpen(false)
    if (!newPost) return;

    setOptimisticPost(newPost)
  }

  return (
    <IonPage>
      <Header
        leftIcon={<Search color="black" className="py-2 px-6 cursor-pointer"/>}
        rightIcon={<SeamComposer
          composerTitle={"Post something to " + currentGroup?.get('name')}
          communityName={currentGroup?.get('name')}
          communityId={currentGroup?.get('name')}
          currentCard={currentGroup}
          completion={handlePostCompletion}
        />}
        leftAction={() => { }}
      />
      <IonContent>
        <div>
          {SeamUserFeed(currentGroup, undefined, "group", (
            <GroupPageHeader
              currentGroup={currentGroup}
              memberIds={currentGroup?.get("Members")}
              onEditGroup={onEditGroup}
            />
          ), optimisticPost)}
        </div>
        <GroupNameEditDialog
          open={isGroupNameEditOpen}
          handleClose={closeGroupNameEdit}
          handleSave={saveNewGroupName}
          currentName={currentGroup?.get("name")}
        />

        <GroupPhotoEditDialog
          open={isGroupPhotoEditOpen}
          handleClose={closeGroupPhotoEdit}
          handleSave={saveNewGroupPhoto}
        />
      </IonContent>
    </IonPage>
  );
};

export default GroupPage;
import React from "react";
import * as Sentry from "@sentry/react";

export default class FeedErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
    Sentry.captureException(error);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="text-center">
          <h2>Oh no, this miniapp crashed!</h2>
          <img className="py-4" src="https://firebasestorage.googleapis.com/v0/b/seam-static-assets/o/crushed_frankie.png?alt=media" alt="Crushed Frankie" style={{ width: '100%', maxWidth: '400px' }} />
          <a href="https://getseam.xyz/information-pages/bug-reports"><h4> File a bug for the miniapp creator here. </h4></a>
        </div>
      );
    }

    return this.props.children;
  }
}
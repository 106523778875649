import { makeStyles } from "@mui/styles";
import Parse from 'parse';
import { useContext, useEffect, useState } from "react";
import { Checkbox, Stack, Typography, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Divider, FormControlLabel } from "@mui/material";
import { SeamUserContext, useSeamUser } from "../utils/SeamUserContext";
import AdminReportedPostsFeed from "../Discover/AdminReportedPostsFeed";
import { BadgeTypes } from "../Badges/BadgeTypes";
import { BlockTypes } from "../Block-SDK/src/blocks/types";
import { X } from "react-feather";

const useStyles = makeStyles({
  root: {
    display: "flex",
    width: "100%",
    height: "100vh",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    overflow: "scroll",
    "&::-webkit-scrollbar": {
      display: "none"
    },
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column"
  },
  spacer: {
    flexGrow: 1
  },
  body: {
    padding: "0.5rem",
    flexGrow: 1
  },
  notifButton: {
    marginTop: 3,
    marginBottom: 2,
    marginLeft: 3,
    padding: "0.5rem",
    maxWidth: "10%",
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  }
});

const FetchNextQuests = () => {
  const [quests, setQuests] = useState<Parse.Object[]>([]);

  useEffect(() => {
    const fetchQuests = async () => {
      const query = new Parse.Query('DailyQuest');
      query.limit(25);
      query.descending('createdAt');
      const results = await query.find();
      setQuests(results);
    };

    fetchQuests();
  }, []);

  const deleteQuest = async (quest: Parse.Object) => {
    try {
      await quest.destroy();
      setQuests(quests.filter(q => q.id !== quest.id));
    } catch (error) {
      console.error('Failed to delete quest:', error);
    }
  };

  return (
    <div>
      <TableContainer>
        <Table>
          <TableBody>
            {quests.map((quest, index) => (
              <TableRow key={index}>
                <TableCell>{quest.get('BlockType')}</TableCell>
                <TableCell>{quest.get('description')}</TableCell>
                <TableCell>{quest.get('tag')}</TableCell>
                <TableCell>{quest.get('Date').toString()}</TableCell>
                <TableCell>
                  <button onClick={() => deleteQuest(quest)}><X /></button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default function Admin(
) {
  const isAuthenticated = Parse.User.current() != undefined
  const { account } = useSeamUser();
  const { isAdmin } = useContext(SeamUserContext);
  const classes = useStyles();

  const [newHandleIsPrivate, setNewHandleIsPrivate] = useState(false)
  const [newHandleIsGroup, setNewHandleIsGroup] = useState(false)
  const [showDeletedStuff, setShowDeletedStuff] = useState(false)

  const createNewCard = (newHandle: string) => {
    const saveObject = async () => {
      const Card = Parse.Object.extend("Card");
      const card = new Card();
      card.set("layout", "{\"lg\":[]}")
      card.set("name", newHandle)
      card.set("blocks", [])
      card.set("theme", "Blueprint")
      card.set("customHandle", true)
      card.set("isPrivate", newHandleIsPrivate)

      const cardACL = new Parse.ACL();
      cardACL.setPublicReadAccess(true);
      cardACL.setRoleWriteAccess("Seam Team", true);
      card.setACL(cardACL);

      card.save().then(
        (newCard: any) => {
          // Execute any logic that should take place after the object is saved.
          alert("Success!");
        },
        (error: any) => {
          alert("Failed to create new object, with error code: " + error.message);
        }
      );
    };
    saveObject();
  }

  const createNewGroup = () => {
    const saveObject = async () => {
      console.log("creating new group")
      const name = account?.get("name");
      const groupName = `${name}'s Private Space`
      const Group = Parse.Object.extend("Group");
      const group = new Group();
      group.set("name", groupName)
      group.set("Members", [account?.get("userId")])
      group.set("creator", account?.get("userId"))
      group.set("customHandle", false)

      group.save().then((newGroup: any) => {

      });
    }
    saveObject();
  }

  // const runBackfill = async () => {
  //   const response = await Parse.Cloud.run("genQuests")
  //   alert(response.message)
  // }

  const banUser = async (handle: string) => {
    Parse.Cloud.run("banUser", { handle: handle }).then((result) => {
      alert("Success, " + handle + " has been banned.")
    }).catch((error) => {
      alert("Failed to ban user: " + error.message)
    })
  }

  const renderBanForm = () => {
    if (!isAdmin) { return }

    const onFinish = (event: any) => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      let handle = data.get('title') as string
      if (window.confirm("Do you really want to ban " + handle + "? This will delete all their pages, posts, and comments.")) {
        banUser(handle)
      }
    };

    return (
      <div className={classes.flexColumn} style={{ paddingTop: "12px" }}>
        <h2>Ban User</h2>
        <Box
          component="form"
          onSubmit={onFinish}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="title"
            label="Handle to ban"
            name="title"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="error"
            style={{ backgroundColor: "firebrick", color: "white" }}
          >
            Ban
          </Button>
        </Box>
      </div>
    )
  }

  const renderSendNotifForm = () => {
    if (!isAdmin) { return }

    const onFinish = async (event: any) => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      let handle = data.get('title') as string
      let description = data.get('description') as string
      let title = "Message from Seam"
      let url = "/"

      // find the account of the handle
      const query = new Parse.Query("Account")
      query.equalTo("profileId", handle)
      let account = await query.first()
      if (!account) {
        alert("No account found for " + handle)
        return
      }

      if (window.confirm("Do you really want to send a notif to " + handle + "? It will notify them.")) {
        const Seam_Official_Account_Id = "HcOlXYbdUP"
        let res = await Parse.Cloud.run('sendNotification', { actorAccountId: Seam_Official_Account_Id, notifiers: [account.get("userId")], description, url, title });
        alert(res);
      }
    };

    return (
      <div className={classes.flexColumn} style={{ paddingTop: "12px" }}>
        <h2>Send Notification</h2>
        <Box
          component="form"
          onSubmit={onFinish}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="title"
            label="Handle to notify"
            name="title"
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="description"
            label="Notification Text"
            name="description"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            style={{ backgroundColor: "lavender", color: "darkslategray" }}
          >
            BLAST
          </Button>
        </Box>
      </div>
    )
  }

  const renderGiveBadgeForm = () => {
    if (!isAdmin) { return }

    const onFinish = async (event: any) => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      let handle = data.get('handle') as string
      let badgeName = data.get('badge') as string
      let badgeType = BadgeTypes[badgeName]
      if (!badgeType) {
        alert("No badge found for " + badgeName)
        return
      }

      // find the account of the handle
      const query = new Parse.Query("Account")
      query.equalTo("profileId", handle)
      let account = await query.first()
      if (!account) {
        alert("No account found for " + handle)
        return
      }

      if (window.confirm("Do you give a badge to " + handle + "? It will notify them.")) {
        const newBadge = { date: new Date(), type: badgeName }
        const existingBadges = account.get("badges") ?? []
        account.set("badges", existingBadges.concat([newBadge]))
        await account.save();

        const Seam_Official_Account_Id = "HcOlXYbdUP"
        const title = "You earned a badge!"
        const description = `You've been awarded the ${badgeType.displayTitle} badge!`
        const url = "user/" + handle
        let res = await Parse.Cloud.run('sendNotification', { actorAccountId: Seam_Official_Account_Id, notifiers: [account.get("userId")], description, url, title });
        alert(res);
      }
    };
    const currentBadgeTypes = Object.keys(BadgeTypes).join(', ');
    return (
      <div className={classes.flexColumn} style={{ paddingTop: "12px" }}>
        <h2>Give Badge</h2>
        <p>Current options: {currentBadgeTypes}</p>
        <Box
          component="form"
          onSubmit={onFinish}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="handle"
            label="Handle to give badge"
            name="handle"
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="badge"
            label="badge"
            name="badge"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            style={{ backgroundColor: "gold", color: "maroon" }}
          >
            Grant
          </Button>
        </Box>
      </div>
    )
  }

  const renderGivePointsForm = () => {
    if (!isAdmin) { return }

    const onFinish = async (event: any) => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      let handle = data.get('handle') as string
      let delta = data.get('sp') as string
      let reason = data.get('reason') as string
      // don't grant if sp is too high or too low
      if (parseInt(delta) > 1000 || parseInt(delta) < -1000) {
        alert("Invalid sp amount")
        return
      }
      if (!reason) {
        alert("Please provide a reason")
        return
      }

      // find the account of the handle
      const query = new Parse.Query("Account")
      query.equalTo("profileId", handle)
      let account = await query.first()
      if (!account) {
        alert("No account found for " + handle)
        return
      }

      if (window.confirm("Do you want to give " + handle + " a bunch of sp?")) {
        let res = Parse.Cloud.run("pointTransaction", { delta: parseInt(delta), accountId: account.id, reason: reason })
        alert(res);
      }
    };

    return (
      <div className={classes.flexColumn} style={{ paddingTop: "12px" }}>
        <h2>Give Seam Points</h2>
        <Box
          component="form"
          onSubmit={onFinish}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="handle"
            label="Handle to give sp"
            name="handle"
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="sp"
            label="sp"
            name="sp"
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="reason"
            label="reason"
            name="reason"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            style={{ backgroundColor: "green", color: "gold" }}
          >
            Give
          </Button>
        </Box>
      </div>
    )
  }

  const renderAddQuestForm = () => {
    if (!isAdmin) { return }

    function parseDate(dateString: string) {
      // Split the date string and create a new Date object with year, month, and day
      const parts = dateString.split('-');
      const year = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10) - 1; // Months are 0-based in JS
      const day = parseInt(parts[2], 10);

      return new Date(year, month, day);
    }

    let supportedBlocks = Object.entries(BlockTypes).filter(
      (blockType) =>
        !blockType[1].deprecated &&
        blockType[1].doesBlockPost
    );
    const currentMiniapps = supportedBlocks.map((blockType) => blockType[0]);

    const onFinish = async (event: any) => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      let handle = data.get('handle') as string
      let blockType = data.get('blockType') as string
      if (BlockTypes[blockType] === undefined) {
        alert("No blockType found for " + blockType)
        return
      }
      let reason = data.get('description') as string
      if (!reason) {
        alert("Please provide a description")
        return
      }
      const dateString = data.get('date') as string

      // Create a new Date object
      let date = parseDate(dateString);
      if (date.toString() === "Invalid Date") {
        alert("Invalid date")
        return
      }
      let tag = data.get('tag') as string
      if (!tag) {
        alert("Please provide a tag")
        return
      }
      const newQuest = new Parse.Object("DailyQuest")
      newQuest.set("description", reason)
      newQuest.set("BlockType", blockType)
      newQuest.set("reward", 10)
      newQuest.set("type", "daily_post")
      newQuest.set("Date", date)
      newQuest.set("tag", tag)
      newQuest.save().then(
        (newQuest: any) => {
          alert("Success!");
        },
        (error: any) => {
          alert("Failed to create new object, with error code: " + error.message);
        }
      );
    };

    return (
      <div className={classes.flexColumn} style={{ paddingTop: "12px" }}>
        <h2>Make New Daily Quest</h2>
        <p>Current miniapps: {currentMiniapps.join(", ")}</p>
        <Box
          component="form"
          onSubmit={onFinish}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="blockType"
            label="Miniapp Type"
            name="blockType"
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="tag"
            label="tag (dailyquest##)"
            name="tag"
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="description"
            label="description"
            name="description"
          />
          <TextField
            margin="normal"
            required
            fullWidth
            id="date"
            label="date for quest (format YYYY-MM-DD)"
            name="date"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            style={{ backgroundColor: "purple", color: "white" }}
          >
            Make
          </Button>
        </Box>
        <h3>Next 5 upcoming quests</h3>
        <FetchNextQuests />
      </div>
    )
  }

  const renderNewHandleForm = () => {
    if (!isAdmin) { return }

    const onFinish = (event: any) => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      let newHandle = data.get('title') as string
      if (window.confirm("Do you really want to create a page at www.seam.so/" + newHandle + "/ ?")) {
        if (newHandleIsGroup) {
          // createNewGroup(newHandle)
        } else {
          createNewCard(newHandle)
        }
      }
    };

    return (
      <div className={classes.flexColumn} style={{ paddingTop: "100px" }}>
        <h1>Welcome Admins! Seam Dream Team ✌️</h1>
        <h2>New Handle Creation Form</h2>
        <Box
          component="form"
          onSubmit={onFinish}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="title"
            label="Handle for new card"
            name="title"
          />
          <FormControlLabel control={
            <Checkbox
              checked={newHandleIsPrivate}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setNewHandleIsPrivate(event.target.checked); }}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          } label="New page is private" />
          <FormControlLabel control={
            <Checkbox
              checked={newHandleIsGroup}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setNewHandleIsGroup(event.target.checked); }}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          } label="New page is group" />
          <Button
            type="submit"
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            style={{ backgroundColor: "limegreen", color: "black" }}
          >
            Publish Live
          </Button>
        </Box>
      </div>
    );
  }

  const renderGroupButton = () => {
    if (!isAdmin) { return }

    return (
      <Button sx={{ marginTop: '50px', }} onClick={createNewGroup}>
        <Typography>
          Create Group
        </Typography>
      </Button>
    )
  }

  const styleHeight = isAdmin ? {} : { height: "100vh" }
  return (
    <div className={classes.root} style={styleHeight}>
      {!isAuthenticated &&
        <h1>if you're actually an admin of Seam, login with your wallet</h1>
      }
      {isAuthenticated && !isAdmin &&
        "nice try, you're not an admin"
      }
      {isAdmin &&
        <div className="flex flex-col items-center overflow-auto h-50">
          {renderNewHandleForm()}
          <Divider className="pt-4" />
          {renderBanForm()}
          <Divider className="pt-4" />
          {/* {renderGroupButton()} */}
          {renderSendNotifForm()}
          <Divider className="pt-4" />
          {renderGiveBadgeForm()}
          <Divider className="pt-4" />
          {renderGivePointsForm()}
          <Divider className="pt-4" />
          {renderAddQuestForm()}
          <Divider className="pt-4" />
          <FormControlLabel control={
            <Checkbox
              name="reportedFeed"
              color="primary"
              checked={showDeletedStuff}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setShowDeletedStuff(event.target.checked) }}
            />} label="show reported stuff" />
          {/* <button onClick={() => { runBackfill() }}> run selected backfill </button> */}
          <h2 style={{ paddingTop: '24px' }}>Reported Posts Feed</h2>
          <Stack direction={"row"}>
            {showDeletedStuff && <AdminReportedPostsFeed height={"100vh"} />}
          </Stack>
        </div>
      }
    </div>
  )
}
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useMobile } from '../utils/MobileContext';
import { useSeamUser } from '../utils/SeamUserContext';
import Parse from 'parse';
import { Virtuoso } from 'react-virtuoso';
import FeedItem from '../Discover/FeedItem';
import { LoadingSkeleton } from "../components/LoadingSkeleton";

const POSTS_PER_PAGE = 10;

const AdminFeed = () => {
  const { isMobile, isMobileApp } = useMobile();
  const { isAdmin } = useSeamUser();
  const [adminGroup, setAdminGroup] = useState(null);
  const [adminPosts, setAdminPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const virtuosoRef = useRef();

  useEffect(() => {
    const fetchAdminGroup = async () => {
      if (isAdmin) {
        const groupQuery = new Parse.Query("Group");
        groupQuery.equalTo("objectId", "LR1iVesmEr");
        try {
          const fetchedAdminGroup = await groupQuery.first();
          if (fetchedAdminGroup) {
            setAdminGroup(fetchedAdminGroup);
          }
        } catch (error) {
          console.error("Error fetching admin group:", error);
        }
      }
    };
    fetchAdminGroup();
  }, [isAdmin]);

  useEffect(() => {
    if (adminGroup && adminPosts.length === 0) {
      loadMore();
    }
  }, [adminGroup]);

  const loadMore = useCallback(async () => {
    if (loading || !hasMore) return;
    setLoading(true);

    const query = new Parse.Query("Post");
    query.include("author");
    query.include("OGPost");
    query.include("OGPost.author");
    query.descending("createdAt");
    query.limit(POSTS_PER_PAGE);
    query.skip(adminPosts.length);

    try {
      const results = await query.find();
      setAdminPosts(prev => [...prev, ...results]);
      setHasMore(results.length === POSTS_PER_PAGE);
    } catch (error) {
      console.error("Error fetching admin posts:", error);
    } finally {
      setLoading(false);
    }
  }, [loading, hasMore, adminPosts.length]);

  useEffect(() => {
    const handleNewPost = (newPost) => {
      if (newPost.get("groupLocationId") === "LR1iVesmEr") {
        setAdminPosts(prev => [newPost, ...prev]);
      }
    };

    const handleDeletePost = (deletedPostId) => {
      setAdminPosts(prev => prev.filter(post => post.id !== deletedPostId));
    };

    window.emitter.on("SEAM_EVENT_POST_SUCCESSFUL", handleNewPost);
    window.emitter.on("SEAM_EVENT_DELETE_POST", handleDeletePost);

    return () => {
      window.emitter.off("SEAM_EVENT_POST_SUCCESSFUL", handleNewPost);
      window.emitter.off("SEAM_EVENT_DELETE_POST", handleDeletePost);
    };
  }, []);

  if (!isAdmin) {
    return null;
  }

  const Footer = () => (
    loading ? (
      <div className={`w-full h-full ${!isMobileApp && "max-w-[720px] flex flex-col justify-center mx-auto"}`}>
        <LoadingSkeleton />
      </div>
    ) : null
  );

  return (
    <div className="w-full h-full">
      {adminGroup ? (
        <div className="flex flex-col items-center justify-center h-full w-full">
          <Virtuoso
            ref={virtuosoRef}
            className="hide-scrollbar w-full ion-content-scroll-host"
            data={adminPosts}
            endReached={loadMore}
            overscan={6}
            itemContent={(index, post) => (
              <div key={post.id} className="w-full max-w-[720px] h-auto m-auto pt-4 px-4 bg-white rounded">
                <FeedItem post={post} ownerAccount={post?.get("author")} />
              </div>
            )}
            components={{
              Footer: Footer
            }}
          />
        </div>
      ) : (
        <div>Loading admin feed...</div>
      )}
    </div>
  );
};

export default AdminFeed;
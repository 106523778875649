import { useMobile } from "../utils/MobileContext";
import logo from "../assets/SeamPink.png";
import { createTheme } from "@mui/material";
import mixpanel from "mixpanel-browser";
import { IonContent, IonPage } from "@ionic/react";
import { motion } from "framer-motion";
import SeamStar from "../assets/seam_star_blue.svg";


export default function LoadingComponent() {
  const { isMobile } = useMobile();

  return (
    <IonPage>
      <IonContent fullscreen={true} scrollY={false}>
      <div style={{height: '100%'}} className="absolute flex flex-row h-screen w-screen grow">
        <div
          className="flex flex-col h-full w-full justify-center items-between"
          style={{
            background:
              "linear-gradient(168deg, #0051E8 -11.45%, #DD3BF7 182.62%)",
          }}
        >
        <motion.div
          className="horizontal-lines-desktop"
          initial={{ y: 0 }}
          animate={{ y: "-100%" }}
          transition={{ ease: "linear", duration: 120, repeat: Infinity }}
        ></motion.div>

        <motion.div
          className="vertical-lines-desktop"
          initial={{ x: "0%" }}
          animate={{ x: "-50%" }}
          transition={{ ease: "linear", duration: 80, repeat: Infinity }}
        ></motion.div>
        <div className="absolute w-screen h-screen flex justify-end">
          <img src={SeamStar} alt="Seam Star" className="max-w-[100px] animate-rotate" />
        </div>
          <div className="relative flex flex-col h-screen w-screen overflow-hidden items-center justify-start">
            <div className="w-full h-[17.5%] mx-auto my-16 flex items-center justify-center">
              <img src={logo} alt="Seam Logo" className="w-[200px]" />
            </div>
            <div className="w-screen h-screen my-auto flex items-start justify-center pt-[25vh] text-center">
              <h1 className="text-seam-white text-center">Loading . . .</h1>
            </div>
            {/* <div className="flex flex-row justify-between items-center w-full h-full overflow-hidden">
            </div> */}
          </div>
        </div>
    </div>
      </IonContent>
    </IonPage>
  );
}

import { makeStyles } from '@mui/styles';
import Parse from 'parse';
import React, { useEffect, useState, useRef, useContext } from 'react';
import GroupBanner from '../assets/GroupBanner.svg'
import { MoreHorizontal } from 'react-feather';
import { SeamUserContext } from '../utils/SeamUserContext';
import useOutsideClick from '../utils/useOutsideClick'

const useStyles = makeStyles({
  cardStyle: {

  },
  media: {
    height: '200px',
    width: '100%',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    alignSelf: 'center',
    alignItems: 'flex-end',
    position: 'relative',
  },
  gradientOverlay: {
    position: 'absolute',
    top: 'auto',
    left: 0,
    right: 0,
    bottom: 0,
    height: '50%',
    backgroundImage: 'linear-gradient(to top, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%)',
  },
})

function GroupPageHeader({ currentGroup, memberIds, onEditGroup }) {
  const classes = useStyles();
  const { isAdmin } = useContext(SeamUserContext);
  const [members, setMembers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [searchResultsOpen, setSearchResultsOpen] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef(null);
  const open = Boolean(menuAnchorEl);
  const searchFieldRef = useRef(null);

  const handleClick = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setMenuAnchorEl(null);
  };

  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    if (value.trim() === '') {
      setSearchResults([]);
      setSearchResultsOpen(false);
      return;
    }

    Parse.Cloud.run("searchUsers", { searchTerm: value }).then(results => {
      setSearchResults(results);
      setSearchResultsOpen(results.length > 0);
    }).catch(error => console.error("Error searching users:", error));
  };

  const handleAddUserToGroup = async (userId) => {
    await Parse.Cloud.run("joinGroup", { groupId: currentGroup.id, accountId: userId }).then(() => {
      setSearchTerm('');
      setSearchResults([]);
      setSearchResultsOpen(false);
    }).catch(error => console.error("Error adding user to group:", error));
  };
 
  useOutsideClick(menuRef, () => setShowMenu(false));

  const handleMenuToggle = () => {
    setShowMenu(!showMenu);
  };

  useEffect(() => {
    const fetchMemberDetails = async () => {
      try {
        const Account = Parse.Object.extend('Account');
        const queries = memberIds.map(id => {
          const query = new Parse.Query(Account);
          query.equalTo('userId', id);
          return query.first();
        });

        const memberDetails = await Promise.all(queries);
        setMembers(memberDetails);
      } catch (error) {
        console.error("Error fetching member details: ", error);
      }
    };

    if (memberIds && memberIds.length > 0) {
      fetchMemberDetails();
    }
  }, [memberIds]);

  const headerImage = currentGroup?.get("headerPhoto") ? currentGroup.get("headerPhoto") : GroupBanner; const defaultBanner = GroupBanner;
  const groupName = currentGroup?.get('name') || 'Group Name';
  const groupSubtitle = 'Private Group';

  return (
    <div className="flex flex-col">
      {/* Header Image */}
      <img className="w-full h-[200px] object-cover rounded-[8px] my-4" src={currentGroup?.get("headerPhoto") || GroupBanner} alt="Group Header" />

      {/* Info Row */}
      <div className="flex items-center justify-between my-2 bg-white">
        {/* Avatar Group */}
        <div className="flex -space-x-2">
          {members.slice(0, 3).map((member, index) => (
            <img key={index} className="w-10 h-10 rounded-full border-2 border-white" src={member.get('profilePhoto')} alt={`Member ${index}`} />
          ))}
          {members.length > 3 && (
            <div className="w-10 h-10 rounded-full border-2 border-white bg-gray-200 flex justify-center items-center text-sm">
              +{members.length - 3}
            </div>
          )}
        </div>

        {/* Group Name and Status */}
        <div className="flex-grow px-4 text-left">
          <h3 className="text-base font-normal leading-6 text-[16px] font-semibold">{groupName}</h3>
          <h3 className="text-base font-normal leading-6 text-gray-500/40">Private Group</h3>
        </div>

        {/* Edit Icon */}
          <div className="relative">
            <button onClick={handleMenuToggle} className="p-2">
              <MoreHorizontal className="w-6 h-6" />
            </button>
            {showMenu && (
              <div ref={menuRef} className="absolute right-0 w-48 bg-white shadow-md mt-2 rounded-md z-10">
                <div className="p-2 hover:bg-gray-100 cursor-pointer" onClick={() => onEditGroup('editName')}>Edit Group Name</div>
                <div className="p-2 hover:bg-gray-100 cursor-pointer" onClick={() => onEditGroup('editPhoto')}>Edit Header Image</div>
                {/* <div className="p-2 hover:bg-gray-100 cursor-pointer" onClick={() => onEditGroup('addMember')}>Add Member</div> */}
              </div>
            )}
          </div>
      </div>
    </div>
  );
}

export default GroupPageHeader;

import React, { useRef, forwardRef } from 'react';
import Parse from 'parse';
import { useSeamUser } from '../utils/SeamUserContext';
import Feed from '../components/Feed';
import HomeFeedHeader from './HomeFeedHeader';
import { useAnimation } from 'framer-motion';

const POSTS_PER_PAGE = 10;

const DiscoveryFeed = forwardRef((props, ref) => {
  const { account, first500UserFollowedChannels, refreshAccount } = useSeamUser();
  const controls = useAnimation();
  const feedRef = useRef();

  React.useImperativeHandle(ref, () => ({
    scrollToTop: () => {
      if (feedRef.current) {
        feedRef.current.scrollToTop();
      }
    },
  }));

  const fetchPosts = async (skip, limit) => {
    if (first500UserFollowedChannels.length === 0) return [];
  
    let query = new Parse.Query('PostInChannel');
    query.containedIn('channel', first500UserFollowedChannels);
    query.include('post');
    query.include('post.author');
    query.include('channel');
    query.include('collector');
    query.descending('createdAt');
    query.limit(limit);
    query.skip(skip);
  
    try {
      const results = await query.find();
      return results;
    } catch (error) {
      console.error('Error fetching posts:', error);
      return [];
    }
  };  

  const Header = ({ context }) => (
    <HomeFeedHeader state={context.state} controls={controls} account={account} />
  );

  return (
    <Feed
      ref={feedRef}
      fetchPosts={fetchPosts}
      Header={Header}
      refreshAccount={refreshAccount}
      itemType="PostInChannel"
      dependencies={[first500UserFollowedChannels]}
      isOnChannelPage={false}
    />
  );
});

export default DiscoveryFeed;
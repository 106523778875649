import { makeStyles } from "@mui/styles";
import Parse from "parse";
import { useEffect, useState } from "react";
import { Dialog, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import animatedLogo from "../assets/Seam_Anim_03_PINK.json";
import LoadingComponent from "../components/LoadingComponent";
import mixpanel from "mixpanel-browser";
import MobileOnboardingTutorial from "./MobileOnboardingTutorial";
import MobileLoginComponent from "./MobileLoginComponent";
import { useSeamUser } from "../utils/SeamUserContext";
import { IonContent, IonPage } from "@ionic/react";
import { motion } from "framer-motion";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexFlow: "row nowrap",
    flexDirection: "row",
    height: "100vh",
    width: "100%",
    flexGrow: 1,
    backgroundColor: "#0051E8",
    overflow: "hidden",
  },
  content: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    paddingBlock: theme.spacing(12),
  },
  logo: {
    marginTop: -60,
  },
}));

export default function MobileLandingPage() {
  const { account, isLoadingAccount, isNewUser } = useSeamUser();
  const classes = useStyles();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    window.emitter.on("SEAM_EVENT_IS_LOADING", (loading) => {
      setIsLoading(loading);
    });

    mixpanel.track("iOS Landing Page Viewed");
  }, []);

  useEffect(() => {
    if (account && Parse.User.current()) {
      history.replace("/profileTab");
    }
  }, [account]);

  if (isNewUser) {
    return (
      <MobileOnboardingTutorial />
    );
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animatedLogo,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const SwiperContent = ({
    headerString,
    subtitleString,
    ImageContainer
  }: {
    headerString: string;
    subtitleString: string;
    ImageContainer: React.FC;
  }) => {
    return (
      <div className="flex flex-col max-w-[1080px] mx-auto px-[24px] text-center items-center justify-between h-full w-auto">
        <Typography variant="h1" className="h-auto w-full text-seam-white">
          {headerString}
        </Typography>
        <div className="w-full flex relative items-start justify-center h-full py-4 overflow-visible">
          <ImageContainer />
        </div>
        <Typography variant="h3" className="h-auto w-full text-seam-white pb-8">
            {subtitleString}
        </Typography>
      </div>
    );
  };

  const ImageComponent = ({src}: { src: any} ) => {
    return (
      <img
        src={src}
        className='max-w-[90px] w-full h-auto'
      />
    )
  }

  const ImageContainerSlide1: React.FC = () => (
    <>
      <img
        src={"https://firebasestorage.googleapis.com/v0/b/seam-static-assets/o/Profile_1.png?alt=media"}
        className="place-self-end max-w-[140px] h-auto rotate-[8deg] mb-[15%] z-2"
      />
      <img
        src={"https://firebasestorage.googleapis.com/v0/b/seam-static-assets/o/Profile_2.png?alt=media"}
        className="self-center max-w-[140px] h-auto -rotate-1 mb-[20%] z-3"
      />
      <img
        src={"https://firebasestorage.googleapis.com/v0/b/seam-static-assets/o/Profile_3.png?alt=media"}
        className="self-center max-w-[140px] h-auto -rotate-[20deg] z-4"
      />
    </>
  );
  
  const ImageContainerSlide2: React.FC = () => (
    <div className="flex flex-row w-auto mx-6 justify-between h-full">
      <img 
        src={"https://firebasestorage.googleapis.com/v0/b/seam-static-assets/o/LandingSlide2.png?alt=media"}
        className="self-center w-auto h-auto"
      />
    </div>
  );
  
  const ImageContainerSlide3: React.FC = () => (
    <div className="flex flex-row w-full justify-between h-full space-x-6">
      <div className="flex flex-col items-start justify-start space-y-6">
        <ImageComponent src={"https://firebasestorage.googleapis.com/v0/b/seam-static-assets/o/LandingSlide3_1.png?alt=media"} />
        <ImageComponent src={"https://firebasestorage.googleapis.com/v0/b/seam-static-assets/o/LandingSlide3_2.png?alt=media"} />
      </div>
      <div className="flex flex-col items-center justify-center space-y-6 h-auto">
      <ImageComponent src={"https://firebasestorage.googleapis.com/v0/b/seam-static-assets/o/LandingSlide3_3.png?alt=media"} />
      <ImageComponent src={"https://firebasestorage.googleapis.com/v0/b/seam-static-assets/o/LandingSlide3_4.png?alt=media"} />
      </div>
      <div className="flex flex-col items-end justify-end space-y-6 h-auto">
        <ImageComponent src={"https://firebasestorage.googleapis.com/v0/b/seam-static-assets/o/LandingSlide3_5.png?alt=media"} />
        <ImageComponent src={"https://firebasestorage.googleapis.com/v0/b/seam-static-assets/o/LandingSlide3_6.png?alt=media"} />
      </div>
    </div>
  );

  return (
    <IonPage>
      <IonContent fullscreen={true} scrollY={false}>
        <div
          className="flex flex-col h-full w-full justify-between items-center"
          style={{
            background:
              "linear-gradient(180deg, #0051E8 -10.19%, #013392 71.15%, #DD3BF7 120.38%)",
          }}
        >
          <motion.div
            className="horizontal-lines"
            initial={{ y: 0 }}
            animate={{ y: "-50%" }} // This scrolls through half the element's height
            transition={{ ease: "linear", duration: 20, repeat: Infinity }}
          ></motion.div>

          <motion.div
            className="vertical-lines"
            initial={{ x: "0%" }}
            animate={{ x: "-50%" }} // Updated to -50% to loop seamlessly
            transition={{ ease: "linear", duration: 10, repeat: Infinity }}
          ></motion.div>

          <div className="relative flex flex-col h-full w-full items-center justify-between overflow-hidden">
            <img 
              src={"https://firebasestorage.googleapis.com/v0/b/seam-static-assets/o/WhiteLogo.svg?alt=media"}
              className="w-[64px] mt-[70px]"
            />

            <div className={`flex-1 w-full flex justify-center items-center my-6`}>
              <Swiper
                spaceBetween={200}
                pagination={{ clickable: true }}
                loop={true}
                autoplay={{ delay: 3000, disableOnInteraction: false }}
                modules={[Autoplay, Pagination]}
                className="swiper-container w-full h-full"
              >
                <SwiperSlide className="w-full h-full flex flex-col items-center justify-center">
                <SwiperContent
                  headerString="Stitch the Internet Together"
                  subtitleString="Craft a home for your creation"
                  ImageContainer={ImageContainerSlide1}
                />
                </SwiperSlide>
                <SwiperSlide className="h-full w-full flex flex-col items-center justify-center">
                  <SwiperContent
                    headerString="Curate Inspiration"
                    subtitleString="Organize ideas by following and collecting"
                    ImageContainer={ImageContainerSlide2}
                  />
                </SwiperSlide>
                <SwiperSlide className="h-full w-full flex flex-col items-center justify-center">
                  <SwiperContent
                    headerString="Infinite ways to create"
                    subtitleString="New miniapps launching all the time built by the Seam community"
                    ImageContainer={ImageContainerSlide3}
                  />
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
          {/* Mobile Login Component */}
          <div className="mb-[20px] w-full self-center">
            <MobileLoginComponent />
          </div>
        </div>

        <Dialog open={isLoading} onClose={() => setIsLoading(false)}>
          <LoadingComponent />
        </Dialog>
      </IonContent>
    </IonPage>
  );
}
import React, { useState, useEffect, forwardRef } from "react";
import Parse from "parse";
import { useMobile } from "../../utils/MobileContext";
import { Skeleton, Card, Stack } from "@mui/material";
import FeedItem from "../../Discover/FeedItem";
import { Virtuoso } from 'react-virtuoso';
import { useSeamUser } from "../../utils/SeamUserContext";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles({
  noScrollBar: {
    "&::-webkit-scrollbar": {
      display: "none"
    },
    "-ms-overflow-style": "none",  /* IE and Edge */
    "scrollbar-width": "none",  /* Firefox */
  },
});

export default function SeamUserFeed(currentCard, creatorAccount, isActivePage, feedType, header) {
  const POSTS_PER_PAGE = 10;
  const classes = useStyles();
  const [loadedPosts, setLoadedPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const { isMobile, isMobileApp } = useMobile();

  // initial feed load
  useEffect(() => {
    if (!currentCard) { return; }
    if (!loading && loadedPosts.length === 0) {
      loadMore(); // Call loadMore when loading is true and loadedPosts is empty
    }
  }, [currentCard]);

  useEffect(() => {
    window.emitter.on("SEAM_EVENT_DELETE_POST", (post) => {
      setLoadedPosts(prevPosts => prevPosts.filter(prevPost => prevPost.id !== post));
    });
    window.emitter.on("SEAM_EVENT_POST_SUCCESSFUL", (post) => {
      if (!isActivePage) return;
      setLoadedPosts(prevPosts => [post, ...prevPosts]);
    });
  }, []);

  async function loadMore() {
    if (loading || !hasMore) return;
    if (!currentCard) return;
    setLoading(true);

    let mainQuery;

    if (feedType == "group") {
      const groupQuery = new Parse.Query("Post");
      groupQuery.equalTo("groupLocationId", currentCard.id);
      mainQuery = groupQuery;
    }

    if (feedType == "profile" || feedType == "card") {
      const locationQuery = new Parse.Query("Post");
      locationQuery.equalTo("locationId", currentCard);
      locationQuery.doesNotExist("groupLocationId");
      locationQuery.descending("createdAt")

      if (feedType == "profile") {
        const queryByAuthor = new Parse.Query("Post");
        queryByAuthor.equalTo("author", creatorAccount);
        //queryByAuthor.doesNotExist("locationId");
        mainQuery = Parse.Query.or(locationQuery, queryByAuthor);
      } else {
        mainQuery = locationQuery;
      }
      mainQuery.doesNotExist("groupLocationId");
    }

    // Common query conditions
    mainQuery.descending("createdAt");
    mainQuery.notEqualTo("isDeleted", true);
    mainQuery.include("author");
    mainQuery.include("OGPost");
    mainQuery.include("OGPost.author");
    mainQuery.limit(POSTS_PER_PAGE);
    mainQuery.skip(loadedPosts.length);

    mainQuery.find().then((results) => {
      setLoadedPosts(prevPosts => [...prevPosts, ...results]);
      setHasMore(results.length === POSTS_PER_PAGE);
      setLoading(false);
    })
  }

  function Header() {
    return (
      <div id={"header"} className="w-auto pt-4 pl-4 pr-4">
        {header}
      </div>
    )
  }

  function Footer() {
    return (
      <div id={"footer"} className="w-auto h-[128px]"></div>
    )
  }

  return (
      <Virtuoso
      style={isMobileApp ? { height: window.innerHeight - 96 } : { height: window.innerHeight }}
      className={`${classes.noScrollBar}`}
        data={loadedPosts}
        endReached={loadMore}
        overscan={3}
        itemContent={(index, post) => {
          return (
            <div key={post.id} className="w-full max-w-[720px] m-auto pt-4 px-4">
              <FeedItem post={post} />
            </div>
          );
        }}
        components={
          {
            Header: Header,
            Footer: Footer,
          }
        }
      />
  );
}
import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button"
import Stack from '@mui/material/Stack';
import { Colors } from "./utils/colors";
import IconButton from "@mui/material/IconButton";
import ContentCopyRoundedIcon from '@mui/icons-material/FileCopyOutlined';
import CheckIcon from '@mui/icons-material/Check';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Tooltip } from "@mui/material";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    alignItems: "center",
    padding: 20
  },
  buttonContained: {
    fontFamily: "Public Sans",
    backgroundColor: Colors.SEAM_BLUE_400,
    height: 48,
    width: 180,
    margin: 4
  },
  buttonOutline: {
    fontFamily: "Public Sans",
    color: Colors.SEAM_BLUE_400,
    height: 48,
    width: 180,
    margin: 4
  },
  subtitle: {
    fontFamily: "Public Sans",
    fontSize: 16,
    height: "24px",
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 300
  }
});

interface SuccessfulPublishModalProps {
  title: string;
  onCancel: () => void;
}

export default function SuccessfulPublishModal(
  { title, onCancel }: SuccessfulPublishModalProps
) {
  const classes = useStyles();

  const [copied, setCopied] = useState(false)

  return (
    <div className={classes.root}>
      <h2>Your page is live!</h2>
      <Stack spacing={1} direction="row" style={{ padding: 4, alignItems: "center" }} sx={{ flexWrap: "wrap", justifyContent: "center" }}>
        <span className={classes.subtitle}>{"Now published to"}</span>
        <span className={classes.subtitle} style={{ backgroundColor: Colors.SEAM_GREEN_600 }}>{"seam.so/" + title}</span>
        <CopyToClipboard text={"http://www.seam.so/" + title} onCopy={() => setCopied(true)}>
          <Tooltip title={"Copy share URL"}>
            <IconButton> {copied ? <CheckIcon /> : <ContentCopyRoundedIcon />} </IconButton>
          </Tooltip>
        </CopyToClipboard>
      </Stack>
      <Stack direction="row" style={{ padding: 16 }} sx={{ flexWrap: "wrap", justifyContent: "center" }}>
        <Button variant="contained" color="primary" className={classes.buttonContained} onClick={() => { onCancel() }}>Back to Editing</Button>
        <Button variant="outlined" color="primary" className={classes.buttonOutline} onClick={() => { 
          window.emitter.emit("SEAM_EVENT_TOGGLE_MODE", "view");
          window.emitter.emit("SEAM_EVENT_TOGGLE_MOBILE", "desktop"); 
          }}>View Page</Button>
      </Stack>
    </div>
  )
}
import React, { useState } from "react";
import { IonContent, IonModal } from "@ionic/react";
import Palette from "@mui/icons-material/Palette";
import { X } from "react-feather";
import { Avatar, Divider, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Parse from "parse";
import { ThemeFactory } from "../themes/themeFactory";
import { useMobile } from "../utils/MobileContext";

const useStyles = makeStyles(() => ({
  field: {
    '& .MuiInputBase-input': {
      color: 'black',
      fontFamily: "Public Sans",
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'black',
      },
    },
    '& .MuiInputLabel-root': {
      color: 'black',
    }
  },
}));

const SeamPageThemeModal = ({ currentCard, deleteCurrentCard}) => {
  const [isOpen, setIsOpen] = useState(false);
  const {isMobileApp} = useMobile();
  const [title, setTitle] = useState(currentCard.get("title"));
  const [description, setDescription] = useState(currentCard.get("description"));
  const classes = useStyles();
  const allThemes = ThemeFactory.loadLocalThemes();

  const saveChanges = async () => {
    currentCard.set("title", title);
    currentCard.set("description", description);
    await currentCard.save(); 
    setIsOpen(false);
  };

  const isProfileType = currentCard.get("type") === "profile";

  return (
    <>
      <div onClick={() => setIsOpen(true)} className="flex justify-center items-center gap-2 py-2 px-2 text-white rounded-full bg-[#efefef] cursor-pointer">
        <Palette style={{ color: "black" }} />
      </div>
      <IonModal
        isOpen={isOpen}
        canDismiss={true}
        onDidDismiss={() => setIsOpen(false)}
        swipeToClose={true}
        presentation="bottom-sheet"
        className={`${isMobileApp ? "" : "full-height-modal"}`}
        style={{ marginTop: "env(safe-area-inset-top)" }}
      >
        <IonContent>
          <div className="relative m-4">
            <button
                className="absolute top-0 right-0 p-2"
                onClick={() => setIsOpen(false)}
              >
                <X size={24} color="black" />
              </button>
            <div className="my-4">
              <h2 className="text-center">Your Page</h2>
            </div>
            <TextField
              fullWidth
              margin="normal"
              label="Page Title"
              variant="outlined"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className={classes.field}
            />
            <TextField
              fullWidth
              margin="normal"
              label="Page Description"
              variant="outlined"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className={classes.field}
            />
            <div className="my-4">
              <h3 className="mt-4 mb-2">Themes</h3>
              <div style={{ display: 'flex', overflowX: 'auto', padding: '10px 0' }}>
                {allThemes.map((theme) => (
                  <div
                    key={theme.themeKey}
                    onClick={() => {
                      window.emitter.emit('SEAM_EVENT_CHANGE_THEME', theme.themeKey);
                      setIsOpen(false);
                    }}
                    className="flex-shrink-0 flex flex-col items-center mr-4"
                  >
                    <Avatar src={theme.thumbnailURL} alt={theme.name} style={{ width: '100px', height: '100px' }} />
                    <p>{theme.name}</p>
                  </div>
                ))}
              </div> 
            </div>
            <div className="flex flex-col justify-center items-center my-4">
              <button
                onClick={saveChanges}
                className="w-full max-h-[56px] text-[16px] text-center bg-seam-green border-2 border-seam-green rounded-[8px] py-4 cursor-pointer mb-4"
                style={{ width: '90%' }}
              >
                <h3 className="text-seam-black">Save Changes</h3>
              </button>
              {!isProfileType && ( // Only render the delete button if the card type is not 'profile'
                <button
                  onClick={deleteCurrentCard}
                  className="w-full max-h-[56px] text-[16px] text-center bg-seam-red border-2 border-seam-red rounded-[8px] py-4 cursor-pointer"
                  style={{ width: '90%' }}
                >
                  <h3 className="text-white">Delete Page</h3>
                </button>
              )}
            </div>
          </div>
        </IonContent>
      </IonModal>
    </>
  );
};

export default SeamPageThemeModal;
import App from "../App";
import { IonPage, IonContent } from "@ionic/react";

const CardPage = ({ pageName }) => {
  return (
    <IonPage className="w-full items-center m-auto h-full">
      <IonContent fullscreen={true} scrollY={false}>
        <App pageName={pageName} />
      </IonContent>
    </IonPage>
  );
};

export default CardPage;

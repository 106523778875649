import React, { useState, useEffect, memo } from "react";
import { makeStyles } from "@mui/styles";
import {
  Stack,
  Modal,
  Box,
  IconButton,
  Button,
  Typography,
  Divider,
} from "@mui/material";
import { AddLink, ArrowBack, CenterFocusStrong } from "@mui/icons-material";
import SearchBar from "../components/SearchBar";
import Parse from "parse";
import { createFriendRequest } from "../utils/ConnectionRequestNetworkHelpers";
import SendMessagesPage from "../MobileOnboarding/SendMessagesPage";
import { useMobile } from "../utils/MobileContext";
import RandomCardList from "../components/RandomCardList";
import { Copy, Share2 } from "react-feather";
import { Share } from "@capacitor/share";
import { Colors } from "../utils/colors";
import { useSeamUser } from "../utils/SeamUserContext";
import { useSeamNavigator } from "../Navigation/SeamNavigatorContext";

const useStyles = makeStyles({
  container: {
    height: 500,
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  graphicsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "-20px",
  },
  textContainer: {
    "& h1": {
      fontSize: "1.5em",
      color: "#333",
      marginBottom: "0.5em",
      marginTop: "0",
      textAlign: "center",
    },
    "& p": {
      fontSize: "1em",
      color: "#666",
      textAlign: "center",
    },
  },
  addFriendsButton: {
    backgroundColor: "black",
    color: "white",
    borderRadius: "24px",
    width: "100%",
    height: "auto",
    fontFamily: "Public Sans",
    fontWeight: "bold",
    fontSize: "24px",
  },
  continueButton: {
    width: "100%",
    height: 48,
    borderRadius: 24,
    color: "white",
    backgroundColor: "black",
    fontFamily: "Public Sans",
    fontWeight: "bold",
    fontSize: 18,
    textTransform: "none",
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflow: "hidden",
  },
  shareButton: {
    backgroundColor: "#EBEBEB",
    borderRadius: "50%",
    "&:focus": {
      backgroundColor: "#EBEBEB",
    },
  },
  shareStack: {
    alignItems: "center",
    paddingInline: "20vw",
    marginTop: "0px",
    marginBottom: "12px",
  },
  actionText: {
    fontSize: "0.75rem",
    fontFamily: "Public Sans",
    color: "#4F4F4F",
    textAlign: "center",
    marginTop: "8px",
  },
  actionItemContainer: {
    minWidth: "120px",
    textAlign: "center",
  },
  copiedText: {
    color: Colors.SEAM_BLUE_400,
  },
});

const handleAddFriend = async (username) => {
  try {
    const currentUser = Parse.User.current();
    if (!currentUser) {
      alert("You must be logged in to add a friend!");
      return;
    }

    // Query for the current user's account
    const Account = Parse.Object.extend("Account");
    const queryCurrentUser = new Parse.Query(Account);
    queryCurrentUser.equalTo("userId", currentUser.id);
    const currentUserAccount = await queryCurrentUser.first();

    if (!currentUserAccount) {
      alert("Your account was not found. Please try again.");
      return;
    }

    // Query for the friend's account
    const queryFriend = new Parse.Query(Account);
    queryFriend.equalTo("profileId", username);
    const friendAccount = await queryFriend.first();

    if (!friendAccount) {
      alert("Friend's account not found.");
      return;
    }

    // Call the createFriendRequest function with both accounts
    createFriendRequest(currentUserAccount, friendAccount, () => {});

    // Display success alert
    alert(`Sent a friend request to ${friendAccount.get("name")}`);
  } catch (error) {
    console.error("Error while adding friend: ", error);
    alert("Failed to add friend: " + error.message);
  }
};

function AddFriendsUpsell({}) {
  const { isMobile } = useMobile();
  const { account } = useSeamUser();
  const classes = useStyles({ isMobile });
  const [modalOpen, setModalOpen] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [hideSearchIcon, setHideSearchIcon] = useState(true);
  const [linkCopied, setLinkCopied] = useState(false);

  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);
  const seamNavigator = useSeamNavigator();

  const code = account.get("referralCode");

  useEffect(() => {
    const openModal = () => setModalOpen(true);

    window.emitter.on("SEAM_OPEN_INVITE_FRIENDS_MODAL", openModal);

    return () => {
      window.emitter.off("SEAM_OPEN_INVITE_FRIENDS_MODAL", openModal);
    };
  });

  const copyLink = () => {
    const referralLink = "http://www.seam.so/?ref=" + code;
    navigator.clipboard.writeText(referralLink).then(
      () => {
        setLinkCopied(true);
        setTimeout(() => setLinkCopied(false), 3000);
      },
      () => {
        console.error("Failed to copy invite link.");
      }
    );
  };

  const openShareCard = async () => {
    const referralLink = `http://www.seam.so/?ref=${code}`; // Your referral link logic here
    try {
      await Share.share({
        title: "Invite Friends to Seam",
        text: `Join me on Seam and let's explore together. Here's my referral link: ${referralLink}`,
        url: referralLink,
        dialogTitle: "Share Seam with Friends!",
      });
    } catch (error) {
      console.error("Error sharing:", error);
    }
  };

  const retrieveListOfContacts = async () => {
    // const projection = {
    //   name: true,
    //   phones: true,
    //   image: true,
    // };

    // try {
    //   const result = await Contacts.getContacts({
    //     projection,
    //   });
    //   console.log(result.contacts);
    //   setContacts(result.contacts);
    // } catch (error) {
    //   // user has denied contacts permission, nothing more to do here
    // }
  };

  const SyncContactsPage = () => {
    return (
      <div className={classes.flexColumn}>
        <Stack spacing={2} direction={"column"}>
          <Button
            className={classes.continueButton}
            onClick={() => retrieveListOfContacts()}
          >
            Use Contacts
          </Button>
          <h4 className={classes.body} style={{ color: "lightgray" }}>
            {" "}
            We don't store this info or send it anywhere, of course.{" "}
          </h4>
        </Stack>
      </div>
    );
  };

  const addFriendsModal = () => {
    const dropdownWidth = isMobile ? "82vw" : "625px";

    const handleCloseModal = () => {
      handleClose();
    };

    return (
      <Modal
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            width: isMobile ? "100vw" : "768px",
            maxWidth: isMobile ? "100vw" : "768px",
            minWidth: isMobile ? "100vw" : "768px",
            height: isMobile ? "100%" : "auto",
            overflowY: isMobile ? "auto" : "visible",
            overflowX: isMobile ? "hidden" : "visible",
          }}
        >
          <Stack
            direction="column"
            spacing={0}
            style={{ marginTop: "28px", maxWidth: "100vw" }}
          >
            {/* Header */}
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <IconButton onClick={handleClose}>
                <ArrowBack />
              </IconButton>
              <Typography variant="h6">Add Friends</Typography>
              <Box width={48} height={48} />
            </Stack>
            {/* Share Section */}
            <Stack
              direction="row"
              justifyContent="center"
              spacing={4}
              className={classes.shareStack}
            >
              <Stack
                direction="column"
                alignItems="center"
                className={classes.actionItemContainer}
              >
                <IconButton onClick={copyLink} className={classes.shareButton}>
                  <Copy style={{ margin: "8px" }} />
                </IconButton>
                <Typography
                  className={`${classes.actionText} ${
                    linkCopied ? classes.copiedText : ""
                  }`}
                >
                  {linkCopied ? "Link Copied!" : "Copy Invite Link"}
                </Typography>
              </Stack>
              {isMobile && (
                <Stack
                  direction="column"
                  alignItems="center"
                  className={classes.actionItemContainer}
                >
                  <IconButton
                    onClick={openShareCard}
                    className={classes.shareButton}
                  >
                    <Share2 style={{ margin: "8px" }} />
                  </IconButton>
                  <Typography className={classes.actionText}>
                    Share Invite Link
                  </Typography>
                </Stack>
              )}
            </Stack>
            <Divider style={{ marginBottom: "12px" }} />
            {/* Search Bar */}
            <Box
              sx={{
                bgcolor: "transparent",
                borderRadius: "8px",
                position: "relative",
                left: isMobile ? "27.5%" : "35%",
                marginRight: "24px",
                paddingTop: "12px",
                width: "100%",
                height: "38px",
              }}
            >
              <SearchBar
                setShowSearchBar={true}
                onTapAction={handleAddFriend}
                prompt="Add by Username"
                hideSearchIcon={true}
                dropdownWidth={dropdownWidth}
              />
            </Box>
            <Box
              sx={{
                position: "absolute",
                bgcolor: "#EFEFEF",
                width: "85%",
                height: "46px",
                borderRadius: "24px",
                top: "212px",
              }}
            ></Box>

            {isMobile && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{ textAlign: "center", mt: "0px" }}
                >
                  Find your friends
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ textAlign: "center", color: "text.secondary", mb: 2 }}
                >
                  Sync your phone contacts to find people you know on Seam.
                </Typography>
                {contacts.length == 0 && SyncContactsPage()}
                {contacts.length > 0 && (
                  <SendMessagesPage
                    contacts={contacts}
                    handleNext={() => {
                      handleClose();
                      seamNavigator.navigateTo("inbox");
                    }}
                  />
                )}
              </Box>
            )}
            {account.get("friendCount") < 5 && (
              <RandomCardList closeModal={handleCloseModal} />
            )}
          </Stack>
        </Box>
      </Modal>
    );
  };

  return (
    <>
      {contacts.length === 0 ? (
        <div className="flex flex-col w-full items-center text-center px-4 py-6 space-y-4 border-2 border-seam-black/20 rounded-[20px]">
          <div className="flex items-center justify-center bg-seam-gray rounded-full w-16 h-16">
            <h1>👯</h1>
          </div>
          <h2 className="text-seam-black">Send your best friend a message</h2>
          <h3 className="text-seam-black/40 leading-6">
            Sync your contacts to find people you know on Seam
          </h3>
          <div
            className="w-full max-h-[60px] text-center bg-seam-blue rounded-[8px] py-4 cursor-pointer"
            onClick={() => retrieveListOfContacts()}
            role="button"
            aria-label="Sync Contacts"
          >
            <h3 className="text-seam-white">Sync Contacts</h3>
          </div>
        </div>
      ) : (
        <SendMessagesPage
          contacts={contacts}
        />
      )}
    </>
  );
}

export default memo(AddFriendsUpsell);

import React, { useEffect, useState } from "react";
import Parse from "parse";
import { BadgeTypes } from "../Badges/BadgeTypes";
import { useSeamUser } from "../utils/SeamUserContext";
import { useHistory, useParams } from "react-router";
import { useSeamNavigator } from "../Navigation/SeamNavigatorContext";
import { IonPage, IonContent } from "@ionic/react";
import { ChevronLeft } from "react-feather";
import Header from "../Navigation/Header";
import DesktopSidebarWrapper from "../components/DesktopSidebarWrapper";
import { useMobile } from "../utils/MobileContext";

const AwardsPage = () => {
  const { account } = useSeamUser();
  const { isMobile, isMobileApp } = useMobile();
  const history = useHistory();
  const { profileId } = useParams();
  const seamNavigator = useSeamNavigator();
  const [creatorAccount, setCreatorAccount] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [pageTitle, setPageTitle] = useState("");
  const isOwnProfile = account ? account.get("profileId") === profileId : false;

  useEffect(() => {
    const fetchCreatorAccount = async () => {
      setIsLoading(true); // Ensure loading state is true at the start of fetch operation
      try {
        if (account && account.get("profileId") === profileId) {
          setCreatorAccount(account);
        } else {
          const accountQuery = new Parse.Query("Account");
          accountQuery.equalTo("profileId", profileId);
          const result = await accountQuery.first();
          if (result) {
            setCreatorAccount(result);
          } else {
            console.error("Creator account not found");
          }
        }
      } catch (error) {
        console.error("Error fetching creator account:", error);
      } finally {
        setIsLoading(false); // Ensure loading state is false after fetch operation completes
      }
    };

    fetchCreatorAccount();
  }, [account, profileId]);

  useEffect(() => {
    // Set the page title based on whether it's the user's own profile or someone else's
    if (creatorAccount) {
      const title = isOwnProfile
        ? "Your Patches"
        : `${creatorAccount.get("username")}'s Patches`;
      setPageTitle(title);
    }
  }, [creatorAccount, isOwnProfile]);

  const BadgeItem = ({ badge }) => {
    const badgeData = BadgeTypes[badge.type];
    if (!badgeData) return null;

    return (
      <div className="flex flex-row items-center space-x-4 space-y-1 py-4">
        <img src={badgeData.imageURL} alt={badgeData.displayTitle} className="w-16 h-16" />
        <div>
          <h3 className="text-seam-black">{badgeData.displayTitle}</h3>
          <h4 className="text-[#949494]">{badgeData.description}</h4>
        </div>
      </div>
    );
  };

  const leftIcon = isMobileApp ? null : <ChevronLeft color="black" className="py-2 px-6 cursor-pointer" />;
  const leftAction = isMobileApp ? () => { } : () => history.go(-1);

  return (
    <IonPage>
      <Header
        isActivePage={true}
        leftIcon={leftIcon}
        rightIcon={null}
        leftAction={leftAction}
        centerIcon={<h3>{pageTitle}</h3>}
      />
      <IonContent>
        <DesktopSidebarWrapper>
        <div className="w-full h-full flex flex-col items-center justify-start mt-4 space-y-6">
          {isLoading ? (
            <div>Loading...</div>
          ) : creatorAccount && creatorAccount.get("badges") && creatorAccount.get("badges").length > 0 ? (
            <div className="p-4 w-full max-w-[720px] mx-auto">
              <h2 className="text-2xl font-bold mb-4">Patches</h2>
              {creatorAccount.get("badges").map((badge, index) => (
                <BadgeItem key={index} badge={badge} />
              ))}
            </div>
          ) : (
            <div className="p-4">No patches found</div>
          )}
        </div>
        </DesktopSidebarWrapper>
      </IonContent>
    </IonPage>
  );
};

export default AwardsPage;

import React from 'react';
import { ListItem, ListItemText, Avatar } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Parse from "parse";
import { useSeamUser } from '../utils/SeamUserContext';

const useStyles = makeStyles({
  cardItem: {
    width: "95%",
    cursor: "pointer",
    backgroundColor: "#EFEFEF",
    borderRadius: 24,
    marginBottom: '16px',
    marginLeft: '8px',
    marginRight: '8px',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: "#DADADA",
    }
  },
  cardTitle: {
    fontFamily: "Public Sans",
    fontWeight: 700,
    color: 'black',
  },
});

interface ContactPayload {
  name?: { display?: string; };
  image?: { base64String?: string; };
}

interface SendMessagesPageProps {
  contacts: ContactPayload[];
  handleNext: () => void;
}

export default function SendMessagesPage({ contacts }: SendMessagesPageProps) {
  const classes = useStyles();
  const { account } = useSeamUser();

  const createNewGroupWithContact = async (contact: any) => {
    if (!account) return;

    const accountId = account.get("userId");

    const friendName = contact?.name?.display;
    const phone = contact?.phones?.[0]?.number?.replace(/\D/g, '');
    const groupName = `${account.get("name")} and ${friendName}'s Private Space`;

    // Prepare ACL for the new group
    const acl = new Parse.ACL();
    acl.setReadAccess(accountId, true);
    acl.setWriteAccess(accountId, true);

    const Group = Parse.Object.extend("Group");
    const group = new Group();
    group.set("name", groupName);
    group.set("Members", [accountId]); // Always include the initiating account in the Members field
    group.set("creator", accountId)
    group.setACL(acl);

    // Attempt to find an existing user
    const userQuery = new Parse.Query(Parse.User);
    userQuery.equalTo("phone", phone);
    const userFriend = await userQuery.first();

    if (userFriend) {
      // If a user is found, add them to the group alongside the initiating user
      const members = group.get("Members");
      members.push(userFriend.id);
      group.set("Members", members);
      acl.setReadAccess(userFriend.id, true);
      acl.setWriteAccess(userFriend.id, true);
    } else {
      // If no user is found, create a shadow user
      const ShadowUser = Parse.Object.extend('ShadowUser');
      const shadowUser = new ShadowUser();
      shadowUser.set('phone', phone);
      shadowUser.set('name', friendName);
      shadowUser.set('image', contact?.image?.base64String); // Adjust based on your data model
      shadowUser.set('referredBy', accountId);

      try {
        const savedShadowUser = await shadowUser.save();
        group.add("ShadowMembers", savedShadowUser.id);
        acl.setReadAccess(savedShadowUser.id, true);
        acl.setWriteAccess(savedShadowUser.id, true);
      } catch (error) {
        console.error("Failed to create shadow user", error);
        return; // Exit if unable to create shadow user
      }
    }

    // Save the new group
    try {
      const newGroup = await group.save();
      console.log("New group created successfully", newGroup);
    } catch (error) {
      console.error("Failed to create new group", error);
    }
  };

  const contactItem = (contact: ContactPayload, index: number) => (
    <ListItem
      alignItems="flex-start"
      className={classes.cardItem}
      onClick={() => createNewGroupWithContact(contact)}
      key={index}
    >
      <Avatar
        src={`${contact.image?.base64String}`}
        style={{ height: 48, width: 48, marginRight: '12px' }}
      />
      <ListItemText
        primary={contact.name?.display}
        classes={{ primary: classes.cardTitle }}
      />
    </ListItem>
  );

  return (
    <div className="w-full text-center">
      <h2 className="mb-4">Your Contacts</h2>
      <div className="overflow-auto">
        {contacts.map((contact, index) => contactItem(contact, index))}
      </div>
    </div>
  );
}

import React, { useContext, useState, useRef, useEffect } from "react";
import { SeamUserContext, useSeamUser } from "../utils/SeamUserContext";
import SeamCommentRepository from "../Comment/SeamCommentRepository";
import { MoreHorizontal } from "react-feather";
import * as Sentry from "@sentry/react";
import mixpanel from "mixpanel-browser";
import { Clipboard } from "@capacitor/clipboard";

export default function CommentItemOptions({ comment }) {
  const { account } = useSeamUser();
  const { isAdmin } = useContext(SeamUserContext);
  const [commentReported, setCommentReported] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const optionsRef = useRef(null);

  // Always call hooks at the top level
  useEffect(() => {
    function handleClickOutside(event) {
      if (optionsRef.current && !optionsRef.current.contains(event.target)) {
        setShowOptions(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [optionsRef]);

  // Return null if comment or account is not available
  if (!comment || !account) {
    return null;
  }

  const author = comment.get("author");
  const isCommentAuthor = author?.id === account?.id;

  const reportComment = async () => {
    setCommentReported(true);
    try {
      mixpanel.track("comment_reported");
      await SeamCommentRepository.flag(comment, account.get("userId"));
      alert("Thanks for reporting -- we'll take a look!");
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const deleteComment = async () => {
    try {
      await SeamCommentRepository.deleteComment(comment);
      alert("Comment deleted successfully.");
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
    }
  };

  const handleCopyLink = async () => {
    const objectId = comment.id;
    const url = `https://seam.so/comment/${objectId}`;

    try {
      await Clipboard.write({
        string: url
      });
      alert("Link copied to clipboard!");
      mixpanel.track("share via link", {'url': url})
    } catch (err) {
      console.error("Failed to copy link: ", err);
    }
    setShowOptions(false);
  };

  const toggleOptions = () => {
    setShowOptions(!showOptions);
  };

  return (
    <div className="relative" ref={optionsRef}>
      <button onClick={toggleOptions} className="p-2 border-0">
        <MoreHorizontal />
      </button>
      {showOptions && (
        <div className="absolute right-0 mt-2 w-48 bg-white shadow-md rounded-md z-10">
          <ul className="text-left">
            <li
              className="hover:bg-gray-100 cursor-pointer p-2"
              onClick={handleCopyLink}
            >
              Copy Link
            </li>
            {!isCommentAuthor && (
              <li
                className="hover:bg-gray-100 cursor-pointer p-2"
                onClick={reportComment}
              >
                Report
              </li>
            )}
            {isCommentAuthor && (
              <li
                className="hover:bg-gray-100 cursor-pointer p-2"
                onClick={deleteComment}
              >
                Delete
              </li>
            )}
            {isAdmin && (
              <>
                <li className="hover:bg-gray-100 cursor-pointer p-2">
                  [ADMIN]: Delete
                </li>
                <li className="hover:bg-gray-100 cursor-pointer p-2">
                  [ADMIN]: Hide
                </li>
              </>
            )}
          </ul>
        </div>
      )}
    </div>
  );
}
import { useReducer } from 'react';

const initialState = {
  showSearchBar: false,
  searchString: '',
  showSearchResults: false,
};

const searchReducer = (state, action) => {
  switch (action.type) {
    case 'TOGGLE_SEARCH_BAR':
      return {
        ...state,
        showSearchBar: !state.showSearchBar,
        ...(state.showSearchBar && { searchString: '', showSearchResults: false }),
      };
    case 'UPDATE_SEARCH_STRING':
      return {
        ...state,
        searchString: action.payload,
        showSearchResults: true,
      };
    case 'RESET_SEARCH':
      return {
        ...state,
        searchString: '',
        showSearchResults: false,
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

const useSearchReducer = () => {
  const [state, dispatch] = useReducer(searchReducer, initialState);
  return [state, dispatch];
};

export default useSearchReducer;

import Parse from 'parse';
import mixpanel from "mixpanel-browser";
import { useEffect, useState } from "react";
import { Button, Checkbox, checkboxClasses, CircularProgress, Divider } from "@mui/material";
import FormControlLabel from '@mui/material/FormControlLabel';
import UsernameTextField from './components/UsernameTextField';
import { createFriendRequest } from './utils/ConnectionRequestNetworkHelpers';
import { usePrivy } from '@privy-io/react-auth';
import LoadingComponent from './components/LoadingComponent';
import Header from './Navigation/Header';
import ProfilePhotoPage from './MobileOnboarding/ProfilePhotoPage';
import { useSeamUser } from './utils/SeamUserContext';
import { ChevronLeft } from 'react-feather';
import { IonContent, IonPage } from '@ionic/react';
import { useMobile } from './utils/MobileContext';
import { ReactComponent as AppStoreBadge } from "./assets/AppStoreBadge.svg"

interface OnboardingTutorialProps {
  referredBy: Parse.Object | null
}

export default function OnboardingTutorial(
  { referredBy }: OnboardingTutorialProps
) {
  const { logout } = usePrivy();
  const Account = Parse.Object.extend("Account");
  const { account, setAccount, refreshAccount } = useSeamUser();
  const currentUser = Parse.User.current()
  const [activeStep, setActiveStep] = useState(0)

  const [displayName, setDisplayName] = useState("")
  const [username, setUsername] = useState("")
  const [validUsername, setValidUsername] = useState(false)
  const [profilePic, setProfilePic] = useState(getRandomProfilePic());
  const [savingUser, setSavingUser] = useState(false)

  const logoutReload = async () => {
    let _ = await logout()
    let __ = await Parse.User.logOut()
    window.location.reload()
  }

  function getRandomProfilePic() {
    const baseUrl = 'https://upcdn.io/W142hWW/raw/uploads/defaultProfile';
    const randomNumber = Math.floor(Math.random() * 6) + 1; // generates a random number between 1 and 6
    const imageUrl = `${baseUrl}${randomNumber}.png`;
    return imageUrl;
  }

  const saveUser = () => {
    if (currentUser === undefined) {
      alert("Your session has expired - please login again.")
      return
    }
    setSavingUser(true)
    let newHandle = username
    let chosenProfilePic = profilePic;
    const eligibleForAppstoreUpsellToFinishOnboarding = true // in the future we can auto not if you're on android but for now we want to get as many people in the app as possible

    const account = new Account()
    account.save({
      userId: currentUser.id,
      profileId: newHandle,
      name: displayName,
      username: username,
      bio: "",
      profilePhoto: chosenProfilePic,
      sp: -1, // New accounts get SP from their referrals and this tags it as a new account
      referrals: 0,
      quests: {},
      referredBy: referredBy?.get('referralCode'),
      referralCode: username!,
      friendCount: 0,
      isOnboarded: false,
    }).then(async (newAccount: Parse.Object) => {
      const newPhone = Parse.User.current()?.get("phone")
      if (newPhone) {
        const selfSyncedContact = new Parse.Object("SyncedContact")
        selfSyncedContact.set("ownerAccount", newAccount)
        selfSyncedContact.set("phone", newPhone)
        selfSyncedContact.save();
      }
      mixpanel.register_once({
        "initial_referrer": referredBy?.get('referralCode')
      });
      mixpanel.track("account created")

      if (!eligibleForAppstoreUpsellToFinishOnboarding) {
        setTimeout(() => {
          setAccount(newAccount);
        }, 2000);
        friendReferrer(newAccount, true)
      } else {
        setActiveStep(3)
        friendReferrer(newAccount, false)
      }

    }, (error: { message: any; }) => {
      console.log(error.message)
      window.emitter.emit("SEAM_EVENT_CREATED_ACCOUNT", false)
    });
  };

  const continueOnWeb = () => {
    refreshAccount()
  }

  // if the new user came in from a referral, send a friend request right away
  const friendReferrer = (account: Parse.Object, refreshAccountWhenFinished: boolean) => {
    if (referredBy == undefined) { return }
    createFriendRequest(account, referredBy, () => { if (refreshAccountWhenFinished) { refreshAccount() }}) // to establish the mutual connection
  }

  const chooseHandlePage = () => {
    return (
      <div className="flex flex-col space-y-8 w-full items-center justify-start">
        <h2 className="text-seam-black">Claim your handle</h2>
        <div className="w-full">
          <UsernameTextField
            startingValue={username}
            onValidChange={(isValid: boolean) => setValidUsername(isValid)}
            onValueChange={(username: string) => { setUsername(username); setDisplayName(username); }}
          />
          <Divider className="w-full bg-seam-black/[5%]" />
        </div>
      </div>
    );
  };

  const profilePhotoPage = () => {
    return (
      <div className="flex-col w-full h-full space-y-4">
        <h2 className="text-seam-black text-center">Add a profile picture</h2>
        <div className="w-full h-full">
          <ProfilePhotoPage
            profilePic={profilePic}
            setProfilePic={setProfilePic}
          />
        </div>
      </div>
    );
  }

  const downloadAppPage = () => {
    return (
      <div className="flex-col w-full h-full space-y-4">
        <img src={"https://www.seam.so/seam-logo.png"} className="h-auto mx-auto w-32 rounded-xl" />
        <h1 className='text-center'> Account created! </h1>
        <h2 className='text-center'> Download the app to continue </h2>
        <div className="mt-4 flex justify-center">
          <a href={"https://apps.apple.com/us/app/seam-social/id6473547569"} target="_blank">
            <AppStoreBadge />
          </a>
        </div>
        <div className="flex justify-center items-center w-full">
          <a href={"https://apps.apple.com/us/app/seam-social/id6473547569"} target="_blank" className='w-full'>
            <Button
              variant="contained"
              color="primary"
              className="w-full h-auto mt-6 flex py-4 bg-seam-blue justify-center items-center"
              sx={{
                "&.Mui-disabled": {
                  background: "#EFEFEF",
                  color: "#949494"
                },
                textTransform: 'none'
              }}
              style={{ textTransform: 'none', fontFamily: "Public Sans" }}>
              Download Seam
            </Button>
          </a>
        </div>
        <div onClick={() => { continueOnWeb() }}>
          <h4 className='text-center text-gray-500'> Continue on web :( </h4>
        </div>
      </div>
    );
  }

  const loadingPage = () => {
    return (
      <LoadingComponent />
    )
  }

  const pageForState = () => {
    switch (activeStep) {
      case 0: return chooseHandlePage();
      case 1: return profilePhotoPage();
      case 2: return loadingPage();
      case 3: return downloadAppPage();
      default: return <h1>Error</h1>;
    }
  };

  const handleNext = () => {
    // Increment the step first
    const nextStep = activeStep + 1;
    setActiveStep(nextStep);
    mixpanel.track("onboarding: page" + nextStep);
    if (activeStep === 1) saveUser();
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const canAdvance = () => {
    switch (activeStep) {
      case 0: return validUsername && username !== ""
      case 1: return true;
      case 2: return false; // Cannot advance from the loading page
      default: return false;
    }
  };

  const advanceDisabled = !canAdvance()

  const getLeftIcon = () => {
    if (activeStep > 0) {
      return (
        <ChevronLeft size={24} color="black" className="py-2 px-6 cursor-pointer" />
      )
    } else return <></>;
  }

  return (
    <IonPage>
      {(activeStep !== 2) &&
        <Header
          isActivePage={true}
          leftIcon={getLeftIcon()}
          rightAction={() => { logoutReload() }}
          leftAction={() => { handleBack() }}
          leftActionOnMobileApp={false}
          rightIcon={<h4 className="text-gray-500 py-2 px-6 w-full cursor-pointer">Log Out</h4>}
          showSearchBar={false}
          searchState={undefined}
          dispatch={undefined}
          onSearchSubmit={undefined}
          scrollToTop={undefined}
          leftIconColor={undefined}
          rightIconColor={"clear"}
          centerIcon={undefined}
          darkMode={false}
        />}
      <IonContent fullscreen={true} scrollY={true} scrollX={false}>
        <div className="flex flex-col justify-between items-center max-w-[720px] h-auto px-6 my-16 py-6 rounded-[20px] mx-auto border-[2px] border-seam-black/[5%]">
          <div className="w-full">{pageForState()}</div>
          {activeStep < 2 && <Button
            variant="contained"
            onClick={handleNext}
            disabled={advanceDisabled}
            className="w-full h-auto mt-6 flex py-4 bg-seam-blue justify-center items-center"
            sx={{
              "&.Mui-disabled": {
                background: "#EFEFEF",
                color: "#949494"
              },
              textTransform: 'none'
            }}
          >
            {savingUser ? <CircularProgress color="inherit" size={24} /> : <h3 className="text-seam-white">Continue</h3>}
          </Button>}
          {activeStep == 0 &&
            <h4 className="text-gray-500 text-center pt-4"> by continuing you agree to the Seam <a href="https://getseam.xyz/information-pages/community-guidelines" target="_blank" rel="noopener noreferrer" className="text-seam-blue"> terms </a> </h4>}
        </div>
      </IonContent>
    </IonPage>
  )
}
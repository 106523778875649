import { Typography } from "@mui/material";
import { getProcessedChannelPhotoURL } from "../components/helpers/ImageTransformers";
import { useSeamNavigator } from "../Navigation/SeamNavigatorContext";
import { useMobile } from "../utils/MobileContext";
import { useSeamUser } from "../utils/SeamUserContext";
import { Img } from "react-image";
import SeamStar from "../assets/seam-star.png";

function CollectionItem({ channel, creatorAccount, darkMode = false, defaultBackground = true }) {
  const seamNavigator = useSeamNavigator();
  const { account } = useSeamUser();
  const isOwnProfile = account?.id === creatorAccount?.id
  const { isMobile } = useMobile();
  let backgroundImageUrl = getProcessedChannelPhotoURL(channel?.get("headerImage"));
  const emoji = channel?.get("emoji");
  const name = channel?.get("name");
  const numPosts = channel?.get("numPosts");
  const textColor = darkMode ? 'white' : 'black';

  return (
    <div
      className="widget cursor-pointer flex flex-col h-full rounded-[12px] overflow-hidden"
      onClick={() => seamNavigator.navigateTo(`/collection/${channel.id}`, channel)}
    >
      <div
        className={`${!backgroundImageUrl && `border border-${textColor}`}`}
        style={{
          backgroundColor: (defaultBackground || backgroundImageUrl) ? null : textColor,
          opacity: !backgroundImageUrl && !defaultBackground ? 0.2 : 1,
          width: '100%',
          paddingTop: '100%',
          position: 'relative',
          borderRadius: '12px',
          boxSizing: 'border-box'
        }}
      >
        <Img
          src={backgroundImageUrl ? ([backgroundImageUrl, channel?.get("headerImage")]) : (SeamStar)}
          loader={<div className="collectionSkeleton" />}
          style={{
            position: 'absolute',
            top: backgroundImageUrl ? 0 : '25%',
            left: backgroundImageUrl ? 0 : '25%',
            width: backgroundImageUrl ? '100%' : '50%',
            height: backgroundImageUrl ? '100%' : '50%',
            objectFit: backgroundImageUrl && 'cover',
            objectPosition: 'center',
            borderRadius: '12px',
            boxSizing: 'border-box',
          }}
        />
      </div>
      <div
        style={{ color: textColor }}
        className="w-full h-full flex flex-col items-start justify-center py-2 text-center"
      >
        <div className="flex space-x-2 h-auto w-full items-center overflow-hidden">
          <span className="h-auto">{emoji}</span>
          <Typography className={`truncate w-full text-start text-${textColor}`} variant={isMobile ? "h4" : "h3"}>
            {name}
          </Typography>
        </div>
        <Typography className={`whitespace-nowrap ml-4 flex-shrink-0 text-${textColor} text-opacity-[40%]`}variant={isMobile ? "h4" : "h3"}>
          {numPosts} posts
        </Typography>
      </div>
    </div>
  );
}

export default CollectionItem;
import React, { useEffect, useState, useReducer, memo } from "react";
import {
  Box,
  Typography,
  Button,
  Stack,
  Modal,
  Card,
  Drawer,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useMobile } from "./utils/MobileContext";
import ConnectionsFeed from "./Discover/ConnectionsFeed";
import GroupsList from "./components/GroupsList";
import { ChevronLeft, Command, Edit, Search } from "react-feather";
import AddFriendsUpsell from "./Discover/AddFriendsUpsell";
import Parse from "parse";
import { formatPostDate } from "./utils/formatPostDate";
import { useSeamUser } from "./utils/SeamUserContext";
import { IonPage, IonContent } from "@ionic/react";
import SearchFeed from "./Discover/SearchFeed";
import MobileHeader from "./Navigation/Header";
import useSearchReducer from "./utils/useSearchReducer";

const useStyles = makeStyles({
  headerText: {
    fontFamily: "Public Sans",
    fontWeight: 600,
    fontSize: "14px",
    color: "#868686",
  },
});

const Inbox = () => {
  console.log("rendering inbox page");
  const classes = useStyles();
  const { isMobile } = useMobile();
  const [state, dispatch] = useSearchReducer();
  const { account } = useSeamUser();
  const [createGroupOpen, setCreateGroupOpen] = useState(false);
  const [groups, setGroups] = useState([]);
  const [groupMemberDetails, setGroupMemberDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [openDrawer, setOpenDrawer] = React.useState(false);

  useEffect(() => {
    window.emitter.on("SEAM_EVENT_CREATE_GROUP", () => {
      setCreateGroupOpen(true);
    });
    window.emitter.on("SEAM_EVENT_CREATED_GROUP", () => {
      setCreateGroupOpen(false);
      fetchGroups();
    });
    return () => {
      window.emitter.off("SEAM_EVENT_CREATE_GROUP");
      window.emitter.off("SEAM_EVENT_CREATED_GROUP");
    };
  }, []);

  useEffect(() => {
    fetchGroups();
  }, [account]);

  const fetchGroupMembers = async (groups) => {
    const memberDetails = {};
    const validGroupIds = new Set();
    const currentUserName = account.get("name").split(" ")[0];

    for (const group of groups) {
      let activity = group.get("activity") || "";
      if (activity.startsWith(currentUserName)) {
        activity = "You" + activity.substring(currentUserName.length);
      }
      const lastUpdated = formatPostDate(group.get("updatedAt"));

      const groupDetails = {
        name: "Unknown User",
        avatar: null,
        activity: activity,
        lastUpdated: lastUpdated,
      };

      if (group.get("customHandle")) {
        // Use the group's name and photo if customHandle is true
        groupDetails.name = group.get("name");
        groupDetails.avatar = group.get("headerPhoto");
        validGroupIds.add(group.id);
      } else if (group.get("Members").length === 1) {
        // Single member logic
        groupDetails.name = group.get("name");
        groupDetails.avatar = group.get("photo");
        validGroupIds.add(group.id);
      } else {
        // Other member logic
        const otherMemberId = group
          .get("Members")
          .find((id) => id !== account.get("userId"));
        const accountQuery = new Parse.Query("Account");
        accountQuery.equalTo("userId", otherMemberId);
        const otherMemberAccount = await accountQuery.first();
        if (otherMemberAccount) {
          groupDetails.name = otherMemberAccount.get("name");
          groupDetails.avatar = otherMemberAccount.get("profilePhoto");
          validGroupIds.add(group.id);
        }
      }

      memberDetails[group.id] = groupDetails;
    }
    setGroupMemberDetails(memberDetails);
    setIsLoading(false);
    return validGroupIds;
  };

  const fetchGroups = async () => {
    if (!account) return;
    const groupQuery = new Parse.Query("Group");
    groupQuery.equalTo("Members", account.get("userId"));
    groupQuery.descending("updatedAt");
    try {
      const results = await groupQuery.find();
      const validGroupIds = await fetchGroupMembers(results);
      const validGroups = results.filter((group) =>
        validGroupIds.has(group.id)
      ); // Filter by valid group IDs
      setGroups(validGroups);
    } catch (error) {
      console.error("Error fetching groups: ", error);
    }
  };

  const toggleSearchBar = () => {
    dispatch({ type: "TOGGLE_SEARCH_BAR" });
  };

  const handleSearch = (searchString) => {
    dispatch({ type: "UPDATE_SEARCH_STRING", payload: searchString });
  };

  return (
    <IonPage>
    <div className="w-full">
      <MobileHeader
        leftIcon={<Search color="black" />}
        rightIcon={<Edit onClick={() => setCreateGroupOpen(true)} />}
        leftAction={toggleSearchBar}
        showSearchBar={state.showSearchBar}
        onSearchSubmit={handleSearch}
      />
      {state.showSearchResults ? (
        <SearchFeed tagName={state.searchString} />
      ) : (
        <div className="w-full mt-4">
          <div className="w-full p-4 text-[40px] font-light leading-[44px] tracking-normal">
            Private Spaces
          </div>

          <div className="flex mt-6 px-4">
            {groups.length === 0 && !isLoading && isMobile ? (
              <AddFriendsUpsell />
            ) : (
              <GroupsList
                groups={groups}
                groupMemberDetails={groupMemberDetails}
              />
            )}
          </div>

          <Modal open={createGroupOpen} onClose={() => setCreateGroupOpen(false)}>
          <Box style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, calc(-50%))',
            paddingTop: "env(safe-area-inset-top)",
            width: isMobile ? '100%' : '33vw',
            height: isMobile ? '100%' : '66vh',
            overflowY: 'auto',
            paddingLeft: '24px',
            paddingRight: '24px',
            backgroundColor: 'white',
            borderRadius: isMobile ? 0 : '24px',
          }}>
            <iconButton
              onClick={() => setCreateGroupOpen(false)}
              style={{ color: 'black', paddingTop: "env(safe-area-inset-top)" }}>
              <ChevronLeft size={24} color={'black'} />
            </iconButton>
            <Stack direction="column" sx={{ display: 'flex', flexDirection: 'column', alignItems: "center" }}>
              <Typography sx={{ fontFamily: "Public Sans", fontWeight: 600, fontSize: '20px', color: "#0051E8" }}>
                Create a new space
              </Typography>
              <Typography className={classes.headerText}>
                Choose a friend to send a message.
              </Typography>
            </Stack>
            <ConnectionsFeed includeGroupAdd={true} />
          </Box>
        </Modal>
        </div>
      )}
    </div>
    </IonPage>
  );
};

export default memo(Inbox);

import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Typography, Button } from "@mui/material";
import { Layout } from "react-feather";
import { customAlphabet } from 'nanoid'
import { Colors } from "./utils/colors"
import TemplateList from './templates/TemplateList'
import mixpanel from "mixpanel-browser";
import Stack from '@mui/material/Stack';
import Parse from 'parse'
import { useSeamNavigator } from "./Navigation/SeamNavigatorContext";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    padding: 20
  },
  body: {
    padding: "0.5rem",
    flexGrow: 1
  },
  startButton: {
    backgroundColor: 'black',
    color: 'white',
    borderRadius: '24px',
    '&:hover': {
      backgroundColor: Colors.SEAM_GREEN_600,
      color: 'black',
    },
    width: '100%',
    maxWidth: '95%',
    padding: '16px',
    height: 'auto',
    marginTop: '20px',
    fontFamily: 'Public Sans',
  }
});

interface CreateNewCardModalProps {
  title: string;
  closeModal: () => void; // pass closemodal prop as an empty function
}

export default function CreateNewCardModal(
  { title, closeModal }: CreateNewCardModalProps
) {
  const classes = useStyles();
  const seamNavigator = useSeamNavigator();
  const isAuthenticated = Parse.User.current() != undefined

  enum CreateNewCardState {
    selectTemplate,
    chooseNickName, // deprecated for now, until we release handles (or randomized selection of nicknames)
    chooseEmptyOrTemplate,
    successLoading
  }

  const [creationState, setCreationState] = useState(CreateNewCardState.chooseEmptyOrTemplate);

  const componentForState = () => {
    if (!isAuthenticated) {
      return <h2>First, log in to create a new page.</h2>
    }
    switch (creationState) {
      case CreateNewCardState.chooseEmptyOrTemplate:
        return renderOptionButtons();
      case CreateNewCardState.chooseNickName:
        return <h1> Choose a nickname </h1>; // deprecated for now, until we release handles (or randomized selection of nicknames)
      case CreateNewCardState.selectTemplate:
        return <TemplateList onClick={(template: Parse.Object) => {
          setCreationState(CreateNewCardState.successLoading)
          const name = generateNewCardId()
          createNewCard(name, template);
        }}
        />
      case CreateNewCardState.successLoading:
        return renderSuccessLoading();
    }
  }

  const generateNewCardId = () => {
    // only lowercase for easier url parsing later
    const nanoid = customAlphabet('1234567890abcdefghijklmnopqrstuvwxyz', 10)
    return nanoid()
  };

  // TODO: refactor this into the general network utility (once I figure out how to use the Parse hook in a static function)
  const createNewCard = (newHandle: string, templatedCard?: Parse.Object) => {
    const saveObject = async () => {
      const currentUser = Parse.User.current()
      if (currentUser === undefined) {
        alert("You must be logged in to create a new page!");
        return
      }

      const Card = Parse.Object.extend("Card");
      const card = new Card();
      card.set("name", newHandle)
      card.set("theme", "Blueprint")
      card.set("creator", currentUser.id)
      card.set("customHandle", false)
      card.set("isPrivate", false)
      if (templatedCard != null) {
        card.set("layout", templatedCard.get("layout"))
        card.set("blocks", templatedCard.get("blocks"))
      } else {
        card.set("layout", "{\"lg\":[]}")
        card.set("blocks", [])
      }

      const cardACL = new Parse.ACL();
      cardACL.setPublicReadAccess(true);
      cardACL.setWriteAccess(currentUser.id, true);
      card.setACL(cardACL);

      card.save().then(
        (newCard: any) => {
          seamNavigator.navigateTo(newHandle)
          mixpanel.track("created new card", { "name": newHandle })
          mixpanel.track("card saved") // track new card creations as also card saves, since we are saving a new card

          setTimeout(closeModal, 1500); // after card is created, close the modal after 1.5 seconds
        },
        (error: any) => {
          // Execute any logic that should take place if the save fails.
          alert("Failed to create new object, with error code: " + error.message);
          setTimeout(closeModal, 3000); // on error, close modal after 3 seconds
        }
      );
    };
    saveObject();
  }

  const renderSuccessLoading = () => {
    return (
      <div className={classes.root}>
        <h2>Success!</h2>
        <h4>Saving and setting up your new page workspace.</h4>
      </div>
    );
  }

  const renderOptionButtons = () => {
    return (
      <Stack spacing={2} direction="column" alignItems="center" justifyContent="center">
        <Typography variant="h5">{title}</Typography>
        <Typography style={{ paddingBottom: 16 }}>Create new pages to showcase your projects, games, and interests.</Typography>
        <Layout size={48}/>
        <Button
          className={classes.startButton}
          onClick={() => {
            mixpanel.track("create: tapped scratch");
            setCreationState(CreateNewCardState.successLoading);
            const name = generateNewCardId();
            createNewCard(name);
          }}
        >
          <Typography style={{fontFamily: "Public Sans", fontWeight: 600, fontSize: 16}}>Start from Scratch</Typography>
        </Button>
      </Stack>
    );
  };

  return (
    <div className={classes.root}>
      {componentForState()}
    </div>
  )
}
export const Colors = {
  SEAM_BLUE_400: '#004FE4',
  SEAM_BLUE_600: '#668AFF',
  SEAM_BLUE_800: '#9EB5FF',
  SEAM_BLUE_USERNAME: '#0051E8',
  SEAM_PINK_400: '#A600B1',
  SEAM_PINK_600: '#EE39FB',
  SEAM_PINK_800: '#FFA9FC',
  SEAM_GREEN_400: '#506600',
  SEAM_GREEN_600: '#CCFE07',
  SEAM_GREEN_800: '#ABD600',
  SEAM_GREEN_900: '#DBFF6D',
  SEAM_GRAY_100: '#1A1B23',
  SEAM_GRAY_400: '#5D5E67',
  SEAM_GRAY_600: '#8F909A',
  SEAM_GRAY_900: '#E2E1EC',
  SEAM_GRAY_TEXT: '#494949',
  SEAM_YELLOW_600: '#AA8E12',
  SEAM_YELLOW_900: '#F2E2A6'
}

export const tailwindColors = {
  SEAM_GREEN: '#506600',
  SEAM_BLACK: '#101010',
  SEAM_BLUE: '#0051E8',
  SEAM_PINK: '#A600B1',
  SEAM_RED: '#E12451',
}

export default {
  PRIMARY: Colors.SEAM_BLUE_400,
  SECONDARY: Colors.SEAM_GREEN_600,
  TERTIARY: Colors.SEAM_PINK_800
}
import { Checkbox } from "@mui/material";
import { Colors } from "../utils/colors";
import SeamCheckboxUnchecked from "../assets/icons/SeamCheckboxUnchecked.svg";
import SeamCheckboxChecked from "../assets/icons/SeamCheckboxChecked.svg";
import SeamPillButton from "../Block-SDK/src/components/SeamPillButton";

export default function QuestItem({ questType, questStatus }) {
  const percentageComplete =
    questStatus == undefined
      ? 0
      : questStatus["completed"] / questStatus["total"];
  const clampedPercentage = Math.min(
    100,
    Math.max(0, percentageComplete * 100)
  );

  const isCompleted = percentageComplete >= 1;
  const spColor = isCompleted ? Colors.SEAM_GREEN_600 : "#D9D9D9";

  return (
    <div key={questType.name} className="py-4 w-full">
      <div className="flex flex-row items-center w-full justify-between">
        <div className="flex flex-row items-center space-x-4 flex-grow">
          <Checkbox
            checked={isCompleted}
            disableRipple={true}
            checkedIcon={<img src={SeamCheckboxChecked} alt="Checked" />}
            icon={<img src={SeamCheckboxUnchecked} alt="Unchecked" />}
            sx={{ padding: 0, cursor: "auto" }}
          />
          <div className="flex-shrink-0">
            <SeamPillButton
              icon={<h3 className="w-auto">{questType.displayTitle}</h3>}
              onClick={() => {}}
              colorOverride={isCompleted ? Colors.SEAM_GREEN_600 : undefined}
            />
          </div>
        </div>
        <h4 className="flex-grow pl-4 overflow-hidden text-ellipsis text-end">{questType.description}</h4>
      </div>
    </div>
  );
}

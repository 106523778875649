import { Colors } from "../utils/colors";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  flexRow: {
    display: "flex",
    flexDirection: "row"
  },
  root: {
    flexDirection: "column",
    backgroundColor: "white",
    width: "33%",
    margin: 10,
    padding: 10,
    border: `1px solid`,
    borderColor: "white",
    cursor: "pointer",
    "&:hover": {
      border: `1px solid`,
      borderColor: Colors.SEAM_GRAY_100
    },
    textAlign: "left"
  },
  spacer: {
    flexGrow: 1
  },
  body: {
    padding: "0.5rem",
    flexGrow: 1
  },
  image: {
    width: "100%",
    height: "100px",
    border: `1px solid`,
    borderColor: "black",
  }
});

function TemplateItem(props) {
  const { title, image, caption, onClick } = props
  const classes = useStyles();
  var keys = Object.keys(Colors);
  var randomColor = Colors[keys[ keys.length * Math.random() << 0]];

  return (
    <div className={classes.root} onClick={onClick}>
      <div className={classes.image} style={{backgroundColor: randomColor}} />
      <h4 style={{ paddingTop: 10 }}>{title}</h4>
    </div>
  )
}

export default TemplateItem;
import React, { useState, useEffect, memo } from "react";
import {
  IconButton,
  Button,
  Typography,
  ListItem,
  Avatar,
  ListItemText,
  ListItemButton,
  CircularProgress,
} from "@mui/material";
import Parse from "parse";
import { createFriendRequest } from "../utils/ConnectionRequestNetworkHelpers";
import { useMobile } from "../utils/MobileContext";
import { ChevronLeft, Copy, Share2, UserCheck, UserPlus } from "react-feather";
import { useSeamUser } from "../utils/SeamUserContext";
import { useSeamNavigator } from "../Navigation/SeamNavigatorContext";
// import { Contacts } from "@capacitor-community/contacts";
import mixpanel from "mixpanel-browser";

function SyncContactsPage({ done }) {
  const { isMobileApp } = useMobile();
  const { account, refreshAccount } = useSeamUser();
  const [contacts, setContacts] = useState(undefined);   // contacts of the form { name: string, phone: string, seamUsername: string, seamProfilePhoto: string }
  const [linkCopied, setLinkCopied] = useState(false);
  const [invitedContacts, setInvitedContacts] = useState([]);
  const [friendedContacts, setFriendedContacts] = useState([]);
  const [syncingContacts, setSyncingContacts] = useState(false);
  const [pymk, setPymk] = useState([]);

  const code = account?.get("referralCode");

  useEffect(() => {
    if (pymk.length > 0) { return }
    const phone = Parse.User.current().get("phone");
    Parse.Cloud.run("getPeopleYouMayKnow", { accountId: account.id, phone: phone }).then((result) => {
      setPymk(result);
    });
  }, [account]);

  useEffect(() => {
    if (contacts != undefined) { return }
    const query = new Parse.Query("SyncedContact");
    query.equalTo("uploaderAccount", account);
    query.limit(5);
    query.doesNotExist("ownerAccount") // get the contacts they had that never signed up
    query.find().then((result) => {
      if (result.length > 0) {
        let notFoundContacts = [];
        result.forEach((contact) => {
          const cleanedContact = {
            name: contact.get("nameFORUPLOADERONLY"),
            phone: contact.get("phone"),
          }
          notFoundContacts.push(cleanedContact);
        });
        setContacts({ foundContacts: [], notFoundContacts });
      }
    });
  }, [account]);

  const copyLink = () => {
    const referralLink = "http://www.seam.so/?ref=" + code;
    navigator.clipboard.writeText(referralLink).then(
      () => {
        setLinkCopied(true);
        setTimeout(() => setLinkCopied(false), 3000);
      },
      () => {
        console.error("Failed to copy invite link.");
      }
    );
  };

  const handleAddFriend = async (username) => {
    const alreadyFriended = friendedContacts.includes(username);
    if (alreadyFriended) { return }
    setFriendedContacts([...friendedContacts, username]);
    try {
      if (!account) {
        alert("Your session has expired. Please log in again.");
        return;
      }

      // Query for the friend's account
      const queryFriend = new Parse.Query("Account");
      queryFriend.equalTo("profileId", username);
      const friendAccount = await queryFriend.first();

      if (!friendAccount) {
        alert("Friend's account not found.");
        return;
      }

      // Call the createFriendRequest function with both accounts
      createFriendRequest(account, friendAccount, refreshAccount);
    } catch (error) {
      console.error("Error while adding friend: ", error);
      alert("Failed to add friend: " + error.message);
    }
  };

  const openShareCard = async (number) => {
    const alreadyInvited = invitedContacts.includes(number);
    if (alreadyInvited) { return }
    const referralLink = `https://www.seam.so/?ref=${code}`;
    const message = `Check out all my collections on Seam: ${referralLink}`;
    window.location.href = `sms:${number}?body=${message}`;
  };

  // comment this out because large numbers of contacts breaks it
  const retrieveListOfContacts = async () => {
    // mixpanel.track("sync contacts");
    // setSyncingContacts(true);
    // const projection = {
    //   name: true,
    //   phones: true,
    //   image: false,
    // };

    // try {
    //   const result = await Contacts.getContacts({
    //     projection,
    //   });
    //   const nameAndNumberContacts = result.contacts.map((contact) => { return { name: contact.name?.display, phone: contact.phones[0]?.number } });
    //   const processedContacts = await Parse.Cloud.run("syncContacts", { accountId: account.id, contacts: nameAndNumberContacts });
    //   setContacts(processedContacts);
    // } catch (error) {
    //   console.log("Error getting contacts: ", error);
    //   // user has denied contacts permission, nothing more to do here
    // }
  };

  const InvitePill = ({ invited }) => (
    <div className={`rounded-full px-4 py-2 ${invited ? 'bg-seam-gray' : 'bg-seam-green'}`}>
      <span className="flex flex-row items-center justify-center">
        <h4 className={`text-seam-black text-center`}>{invited ? 'Invited!' : 'Invite +40'}</h4>
        &nbsp;
        {invited ? null : <img className="w-4 h-4" src="https://upcdn.io/W142hWW/raw/Seam%20Assets/Seam%20Point.png" />}
      </span>
    </div>
  )

  const FriendPill = ({ friended }) => (
    <div className="bg-seam-pink rounded-full px-4 py-2">
      <span className="flex flex-row items-center justify-center">
        {friended ? <UserCheck size={16} color="white" className="mr-2" /> : <UserPlus size={16} color="white" className="mr-2" />}
        <h4 className={`text-white text-center`}>{friended ? "Added" : "Add"}</h4>
      </span>
    </div>
  )

  const ContactItem = ({ id, seamProfilePhoto, seamUserName, phoneName, phoneNumber, isFound, textColor }) => {
    const isFriended = friendedContacts.includes(seamUserName);
    return (
      <ListItem key={id} divider={true} disableGutters={true}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Avatar
            src={seamProfilePhoto}
            style={{ height: 48, width: 48, marginRight: '12px', border: '2px solid white' }}
          />
          <ListItemText
            primary={seamUserName ?? phoneName}
            secondary={phoneNumber}
            primaryTypographyProps={{ style: { color: textColor, fontSize: '1rem' } }}
            secondaryTypographyProps={{ style: { color: '#CCC', fontSize: '0.875rem' } }}
          />
        </div>
        <div className="flex-grow" />
        <ListItemButton
          onClick={() => {
            if (!isFound) {
              openShareCard(phoneNumber);
              setInvitedContacts([...invitedContacts, phoneNumber]);
            } else {
              handleAddFriend(seamUserName);
            }
          }}
        >
          {isFound ? <FriendPill friended={isFriended} /> : <InvitePill invited={invitedContacts.includes(phoneNumber)} />}
        </ListItemButton>
      </ListItem>
    );
  };

  const CopyReferralLinkSection = () => (
    <div className="flex justify-center">
      <div className="border border-gray-300 rounded-full py-2 px-4 cursor-pointer flex-shrink" onClick={copyLink}>
        <IconButton className="p-0">
          <Copy className="mr-2" />
        </IconButton>
        <span className="text-black text-sm">
          {linkCopied ? "Link Copied!" : "Copy your referral link here"}
        </span>
      </div>
    </div>
  )

  return (
    <div style={{ paddingTop: "env(safe-area-inset-top" }}>
      <div className="flex flex-row items-center justify-between px-4">
        <IconButton onClick={done}>
          <ChevronLeft />
        </IconButton>
        <div className="flex-grow" />
        <Typography variant="h3">Add Friends</Typography>
        <div className="flex-grow" />
        <Typography variant="h3" onClick={done}>Done</Typography>
      </div>
      <div className="overflow-y-auto h-screen p-4 space-y-4" style={{ fontFamily: "Public Sans" }}>
        <CopyReferralLinkSection />
        {/* comment this out for now since it breaks with large numbers of contacts */}
        {false && isMobileApp && <div className="p-2" style={{ background: 'linear-gradient(180deg, #3F5EF6 0%, #DFA0FF 100%)', borderRadius: '12px' }}>
          {!contacts && (
            <div className="flex flex-col items-center text-center space-y-4 px-2 py-6">
              <h2 className="text-seam-white">Sync Contacts?</h2>
              <h3 className="text-seam-white leading-6">
                You already have friends on Seam!
              </h3>
              <div className="flex items-center justify-center bg-seam-gray rounded-full w-16 h-16">
                <Avatar src={account.get("profilePhoto")} style={{ height: 64, width: 64, border: '2px solid white' }} />
              </div>

              <div
                className="w-auto text-center bg-seam-green rounded-[8px] py-4 cursor-pointer w-full"
                onClick={() => retrieveListOfContacts()}
                role="button"
                aria-label="Sync Contacts"
                isDisabled={syncingContacts}
              >
                {syncingContacts ? <CircularProgress color='inherit' /> : <h3 className="text-seam-blue">Add Contacts</h3>}
              </div>
            </div>
          )}

          {contacts && (
            <div className="flex flex-col w-auto space-y-4 p-2">
              {/* Found Contacts Section */}
              {contacts.foundContacts.length > 0 && (
                <>
                  <h3 style={{ color: 'white', textAlign: 'center' }}>Add Contacts</h3>
                  <h4 style={{ color: 'white', marginBottom: '12px', textAlign: 'center' }}>You've already got friends on Seam, add them back</h4>
                  {contacts.foundContacts.map((contact, index) => (
                    <ContactItem
                      id={contact.phoneNumber}
                      seamProfilePhoto={contact.seamProfilePhoto}
                      seamUserName={contact.seamUsername}
                      phoneName={contact.name}
                      phoneNumber={contact.phone}
                      isFound={true}
                      textColor={'white'}
                    />
                  ))}
                  <Button
                    variant="contained"
                    style={{ padding: 16, backgroundColor: '#C4FA48', color: 'blue', width: '100%', marginBottom: '24px', borderRadius: '12px', textTransform: 'none' }}
                    onClick={() => contacts.foundContacts.forEach((contact) => handleAddFriend(contact.seamUsername))}
                  >
                    Add All Contacts
                  </Button>
                </>
              )}

              {/* Not Found Contacts Section */}
              <h3 style={{ color: 'white', textAlign: 'center' }}>Invite Contacts</h3>
              <h4 style={{ color: 'white', marginBottom: '12px', textAlign: 'center' }}>Earn 40 Seam Points when they join</h4>
              {contacts.notFoundContacts.map((contact, index) => (
                <ContactItem
                  id={contact.phone}
                  seamProfilePhoto={contact.seamProfilePhoto}
                  seamUserName={contact.seamUsername}
                  phoneName={contact.name}
                  phoneNumber={contact.phone}
                  isFound={false}
                  textColor={'white'}
                />
              ))}
            </div>
          )}
        </div>}
        <div className="flex flex-col w-full space-y-4 overflow-y-auto">
          <h2 style={{ textAlign: "center" }}>People You Might Know</h2>
          {pymk.map((person) => (
            <ContactItem
              id={person.accountId}
              seamProfilePhoto={person.profilePhoto}
              seamUserName={person.profileId}
              phoneName={undefined}
              phoneNumber={person.why}
              isFound={true}
              textColor={'black'}
            />
          ))}
          {pymk.length === 0 && <h3 style={{ textAlign: "center" }}>No more suggestions right now!</h3>}
        </div>
        <div style={{ height: "250px" }} />
      </div>
    </div>
  );
};

export default memo(SyncContactsPage);

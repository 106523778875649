import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@mui/styles";
import Card from '@mui/material/Card';
import Modal from '@mui/material/Modal';
import Content from "./Content";
import "./styles.css";
import SuccessfulPublishModal from "./SuccessfulPublishModal";
import mixpanel from "mixpanel-browser";
import { ThemeTypes } from "./themes/ThemeTypes";
import WebFont from 'webfontloader';
import { getCustomStyle } from "./themes/GetCustomStyle";
import Parse from 'parse';
import { useSeamUser } from "./utils/SeamUserContext";
import { Theme } from "@mui/material/styles";
import { useMobile } from "./utils/MobileContext";
import SeamPageEditHeader from "./Navigation/SeamPageEditHeader";
import { useIonRouter } from "@ionic/react";
import ClaimGate from "./components/ClaimGate";

type StyleProps = {
  isMobile: boolean;
};

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  content: (props) => ({
    flexGrow: 1,
    marginBottom: props.isMobile ? 'calc(128px + env(safe-area-inset-bottom))' : '',
  }),
}));

interface AppProps {
  pageName?: string;
}

export default function App(
  props: AppProps
) {
  let { pageName } = props;
  const router = useIonRouter();
  const communityId = pageName ?? router.routeInfo.pathname.substring(1) ?? "seam";

  const { isMobile } = useMobile();
  const { isAdmin } = useSeamUser();
  const isAuthenticated = Parse.User.current() !== undefined
  const user = Parse.User.current() ?? undefined
  const classes = useStyles({ isMobile });
  const CardData = Parse.Object.extend("Card");
  Parse.Object.registerSubclass("Card", CardData);
  const Account = Parse.Object.extend("Account");
  Parse.Object.registerSubclass("Account", Account);

  const [hasSuccessfulPublish, setHasSuccessfulPublish] = useState(false);
  const [isGated, setIsGated] = useState(false);
  const [creatorAccount, setCreatorAccount] = useState<Parse.Object | undefined>(undefined) // the account that created the card that is being viewed
  const [isInEditMode, setIsInEditMode] = useState(false)
  const [hasWriteAccess, setHasWriteAccess] = useState(false);
  const [theme, setTheme] = useState("Blueprint")
  const [data, setData] = useState<Parse.Object | undefined>(undefined)
  const [bgStyle, setBgStyle] = useState<any>({});
  const [isLoading, setIsLoading] = useState(true)

  // fetch the data for the card
  useEffect(() => {
    setIsLoading(true)
    // exit edit mode when navigating between cards
    if (isInEditMode) {
      setIsInEditMode(false)
      setHasSuccessfulPublish(false)
    }
    let query = new Parse.Query("Card")
    query.equalTo("name", communityId.toLowerCase())
    query.first().then((result: any) => {
      setData(result)
      setIsLoading(false)
    });
  }, [communityId]);

  // Check if this handle is in use
  useEffect(() => {
    if (isLoading) { return }
    if (data === undefined) {
      setIsGated(true)
    } else {
      // Card data is loaded
      const currentCard = data
      const acl = currentCard.getACL(); // if an object has no ACL, then it is writable by everyone
      const passesACL = (acl === null ? true : (user === undefined ? false : acl!.getWriteAccess(user.id)))
      const canWrite = (isAdmin || passesACL)

      if (canWrite) {
        setHasWriteAccess(canWrite)
      } else {
        setHasWriteAccess(false)
      }

      const pageTheme = currentCard.get("theme")
      // protect against unreleased themes crashing the app
      if (pageTheme in ThemeTypes) {
        setTheme(pageTheme)
        const newBgStyle = getCustomStyle(ThemeTypes[theme], ThemeTypes[theme].theme.blockOptions.backgroundImage);
        setBgStyle(newBgStyle);
        WebFont.load({
          google: {
            families: [ThemeTypes[pageTheme].theme.typography.fontFamily as string]
          }
        });
      }
    }
  }, [isAdmin, data, isLoading, isAuthenticated]);

  // Log to analytics
  useEffect(() => {
    try {
      mixpanel.track('Card View', { 'id': window.location.pathname + window.location.search })
      mixpanel.register_once({ 'platform': isMobile ? "mobile" : "desktop" })
    } catch (error) {
      return // cookies prevented
    }
  }, []);

  // Setup event bus listeners to key events once
  useEffect(() => {
    window.emitter.on("SEAM_EVENT_PUBLISH_SUCCESSFUL", () => { setHasSuccessfulPublish(true) })
    window.emitter.on("SEAM_EVENT_CHANGE_THEME", (themeType) => {
      setTheme(themeType)
    })
    window.emitter.on("SEAM_EVENT_TOGGLE_MODE", (mode) => {
      setIsInEditMode(mode === "edit");
      setHasSuccessfulPublish(false);
    });
  }, []);

  // uses custom style helper to update current theme with custom styles
  useEffect(() => {
    const newBgStyle = getCustomStyle(ThemeTypes[theme], ThemeTypes[theme].theme.blockOptions.backgroundImage);
    setBgStyle(newBgStyle);
  }, [theme]);

  // Get the account of the card creator
  useEffect(() => {
    if (data === undefined) { return }

    const query = new Parse.Query("Account");
    query.equalTo("userId", data.get("creator"));
    query.first().then((result: any) => {
      setCreatorAccount(result)
    });
  }, [data]);

  const renderSuccessfulPublishModal = () => {
    return (
      <Modal
        open={hasSuccessfulPublish}
        onClose={() => setHasSuccessfulPublish(false)}
        className="overflow-scroll block text-[16px] font-medium"
      >
        <Card
          className="mt-[10px] rounded-2xl p-4 w-full max-w-[500px] flex flex-col absolute top-[10%] left-[50%] translate-x-[50%]"
        >
          {<SuccessfulPublishModal title={communityId} onCancel={() => setHasSuccessfulPublish(false)} />}
        </Card>
      </Modal>
    );
  }

  const mainComponentForState = () => {
    const blocksLoaded = (!isLoading && data != null)
    if (isGated) {
      return <ClaimGate />
    } else if (blocksLoaded) {
      return <Content
        data={data}
        creatorAccount={creatorAccount}
        hasWriteAccess={hasWriteAccess}
        isInEditMode={isInEditMode}
      />;
    }
  };

  return (
    <div className={`flex flex-col h-full w-full overflow-auto ${classes.noScrollBar}`} style={{ ...bgStyle }}>
      {creatorAccount && (
        <SeamPageEditHeader
          hasWriteAccess={hasWriteAccess}
          currentCard={data}
          creatorAccount={creatorAccount}
          isInEditMode={isInEditMode}
          isActive={true}
        />
      )}
      <div className="flex flex-row justify-center w-full h-full">
        <main className={classes.content}>
          {mainComponentForState()}
        </main>
      </div>
      {renderSuccessfulPublishModal()}
    </div>
  );
}

import { Theme, createTheme } from "@mui/material"

export type ThemeType = {
  name: string
  theme: Theme
  retired: boolean
}

export const ThemeTypes: { [key: string]: ThemeType } = {
  "Blueprint": {
    name: "Seam Original",
    theme: createTheme({
      palette: {
        primary: {        // Page Background
          main: "#FEFEFE"
        },
        secondary: {
          main: "#FEFEFE"  // Block Background (Profile, ect. Buttons take on the info)
        },
        info: {
          main: "#0288D1"
        }
      },
      typography: {
        fontFamily: "Public Sans"
      },
      blockOptions: {
        cornerRadius: 5,
        cursorURL: "auto",
      },
    }),
    retired: false
  },

  "Winter Wonderland": {
    name: "Winter Wonderland",
    theme: createTheme({
      blockOptions: {
        cornerRadius: 32,
        cursorURL: "https://cur.cursors-4u.net/holidays/hol-4/hol394.cur"
      },
      palette: {
        primary: {
          main: "#C2E0EB"
        },
        secondary: {
          main: "#FEFEFE"
        },
        info: {
          main: "#FEFEFE"
        }
      },
      typography: {
        fontFamily: 'Comfortaa'
      },
    }),
    retired: true
  },

  "Hacker": {
    name: "Hacker",
    theme: createTheme({
      blockOptions: {
        cornerRadius: 0,
        cursorURL: "https://cur.cursors-4u.net/cursors/cur-2/cur192.cur",
      },
      palette: {
        primary: {
          main: "#020303"
        },
        secondary: {
          main: "#1C1C1C"
        },
        info: {
          main: "#CCFE07" // Button Background
        }
      },
      typography: {
        fontFamily: "monospace"
      },
    }),
    retired: false
  },

  "Pixels": {
    name: "Pixels",
    theme: createTheme({
      blockOptions: {
        cornerRadius: 0,
        cursorURL: "auto",
      },
      palette: {
        primary: {
          main: "#222044"
        },
        secondary: {
          main: "#4E4D68"
        },
        info: {
          main: "#FFFFFF"
        }
      },
      typography: {
        fontFamily: "VT323"
      },
    }),
    retired: false
  },

  "Spring": {
    name: "Spring",
    theme: createTheme({
      blockOptions: {
        cornerRadius: 0,
        cursorURL: "auto",
      },
      palette: {
        primary: {
          main: "#E2E66C"
        },
        secondary: {
          main: "#D9EDF7"
        },
        info: {
          main: "#6C8A21"
        }
      },
      typography: {
        fontFamily: "Comfortaa"
      },
    }),
    retired: true
  },

  "NuCyber": {
    name: "NuCyber",
    theme: createTheme({
      blockOptions: {
        cornerRadius: 16,
        cursorURL: "https://cur.cursors-4u.net/mechanics/mec-5/mec466.cur",
        dropShadow: "0px 0px 8px #FF00FF",
      },
      palette: {
        primary: {
          main: "#1F2955"
        },
        secondary: {
          main: "#151030"
        },
        info: {
          main: "#E1C8E8"
        }
      },
      typography: {
        fontFamily: "Space Mono"
      },
    }),
    retired: false
  },

  "Tastemakerz": {
    name: "Tastemakerz",
    theme: createTheme({
      blockOptions: {
        cornerRadius: 0,
        cursorURL: "https://cur.cursors-4u.net/food/foo-6/foo523.cur",
        border: "3px solid black",
      },
      palette: {
        primary: {
          main: "#430C8C"
        },
        secondary: {
          main: "#FFFFFF"
        },
        info: {
          main: "#6A1ADB"
        }
      },
      typography: {
        fontFamily: "Mansalva"
      },
    }),
    retired: false
  },

  "SubwayRats": {
    name: "Subway Rats",
    theme: createTheme({
      blockOptions: {
        cornerRadius: 16,
        cursorURL: "https://cur.cursors-4u.net/food/foo-2/foo169.cur",
        contractAddress: "0x696383fc9C5C8568C2E7aF8731279b58B9201394"
      },
      palette: {
        primary: {
          main: "#656581"
        },
        secondary: {
          main: "#223944"
        },
        info: {
          main: "#FEFEFE"
        }
      },
      typography: {
        fontFamily: "DotGothic16"
      },
    }),
    retired: false
  },

  "Barbie": {
    name: "Barbie",
    theme: createTheme({
      blockOptions: {
        cornerRadius: 40,
        cursorURL: "https://cur.cursors-4u.net/nature/nat-9/nat871.png",
        border: "1px #FFF8FD",
      },
      palette: {
        primary: {
          main: "#FE9DE0"
        },
        secondary: {
          main: "#FFF8FD"
        },
        info: {
          main: "#DA428D"
        }
      },
      typography: {
        fontFamily: "Courgette",
      },
    }),
    retired: false
  },

  "Oppenheimer": {
    name: "Oppenheimer",
    theme: createTheme({
      blockOptions: {
        cornerRadius: 8,
        cursorURL: "https://cur.cursors-4u.net/others/oth-1/oth3.png",
        backgroundImage: "https://wallpaperaccess.com/full/8881280.jpg",
        backgroundSize: "cover",
        backgroundPosition: "center",
      },
      palette: {
        primary: {
          main: "#ABABAB"
        },
        secondary: {
          main: "#F9F0E7"
        },
        info: {
          main: "#070707"
        }
      },
      typography: {
        fontFamily: "Archivo"
      },
    }),
    retired: false
  },
  "Space": {
    name: "Space",
    theme: createTheme({
      blockOptions: {
        cornerRadius: 5,
        cursorURL: "https://cur.cursors-4u.net/nature/nat-7/nat606.cur",
        backgroundSize: "cover",
        backgroundPosition: "center",
      },
      palette: {
        primary: {
          main: "#141920"
        },
        secondary: {
          main: "#020208"
        },
        info: {
          main: "#00FF38"
        }
      },
      typography: {
        fontFamily: "Audiowide"
      },
    }),
    retired: false
  },

  "Farm Hands": {
    name: "Farm Hands",
    theme: createTheme({
      blockOptions: {
        cornerRadius: 4,
        contractAddress: "0xB9F325A9f41b380C66159F96453e1a3FdB51383f",
        cursorURL: "https://cur.cursors-4u.net/food/foo-2/foo106.cur",
        backgroundSize: "cover",
        backgroundPosition: "center",
      },
      palette: {
        primary: {
          main: "#333"
        },
        secondary: {
          main: "#0362FE"
        },
        info: {
          main: "#FEFEFE"
        }
      },
      typography: {
        fontFamily: "Oswald"
      },
    }),
    retired: false
  },

  "Dininho Adventures": {
    name: "Dininho Adventures",
    theme: createTheme({
      blockOptions: {
        cornerRadius: 30,
        contractAddress: "0x5733E9490472AC6Be02a879f7C3e19EcEABC24BE",
        cursorURL: "https://cur.cursors-4u.net/nature/nat-9/nat803.cur",
        backgroundPosition: "center",
        backgroundSize: "cover",
      },
      palette: {
        primary: {
          main: "#5989CE"
        },
        secondary: {
          main: "#F2FFF5"
        },
        info: {
          main: "#6D238E"
        }
      },
      typography: {
        fontFamily: "Darumadrop One"
      },
    }),
    retired: false
  },

  "Glizzy Gang": {
    name: "Glizzy Gang",
    theme: createTheme({
      blockOptions: {
        cornerRadius: 30,
        contractAddress: "0xA727ceA448c740fbF827574026395Cf7e5f973c1",
        cursorURL: "https://cur.cursors-4u.net/food/foo-4/foo322.cur",
        backgroundPosition: "center",
        backgroundSize: "cover",
      },
      palette: {
        primary: {
          main: "#9AC6DF"
        },
        secondary: {
          main: "#353548"
        },
        info: {
          main: "#E6CE57"
        }
      },
      typography: {
        fontFamily: "Knewave"
      },
    }),
    retired: false
  },

  "Pixel Beasts": {
    name: "Pixel Beasts",
    theme: createTheme({
      blockOptions: {
        cornerRadius: 30,
        contractAddress: "0xd539a3A5edb713E6587E559A9D007FfFF92bd9ab",
        cursorURL: "https://cur.cursors-4u.net/nature/nat-11/nat1022.cur",
        backgroundPosition: "center",
        backgroundSize: "cover",
      },
      palette: {
        primary: {
          main: "#B8E2E9"
        },
        secondary: {
          main: "#FFFEF1"
        },
        info: {
          main: "#2AB0B6"
        }
      },
      typography: {
        fontFamily: "Outfit"
      },
    }),
    retired: false
  },

  "Crypto Kitties": {
    name: "Crypto Kitties",
    theme: createTheme({
      blockOptions: {
        cornerRadius: 10,
        contractAddress: "0x06012c8cf97BEaD5deAe237070F9587f8E7A266d",
        cursorURL: "https://cur.cursors-4u.net/nature/nat-10/nat924.cur",
        backgroundPosition: "center",
        backgroundSize: "cover",
      },
      palette: {
        primary: {
          main: "#FFFFFF"
        },
        secondary: {
          main: "#FFE2FB"
        },
        info: {
          main: "#DD5DCC"
        }
      },
      typography: {
        fontFamily: "Carme"
      },
    }),
    retired: false
  },

  "Pudgy Penguins": {
    name: "Pudgy Penguins",
    theme: createTheme({
      blockOptions: {
        cornerRadius: 10,
        contractAddress: "0xBd3531dA5CF5857e7CfAA92426877b022e612cf8",
        cursorURL: "https://ani.cursors-4u.net/movie/mov-2/mov140.png",
        dropShadow: "3px 3px 0 #000000",
        backgroundPosition: "center",
        backgroundSize: "cover",
      },
      palette: {
        primary: {
          main: "#4A8DC6"
        },
        secondary: {
          main: "#F9FCFF"
        },
        info: {
          main: "#88A9F9"
        }
      },
      typography: {
        fontFamily: "Bangers"
      },
    }),
    retired: false
  },

  "Nouns": {
    name: "Nouns",
    theme: createTheme({
      blockOptions: {
        cornerRadius: 24,
        contractAddress: "0x9C8fF314C9Bc7F6e59A9d9225Fb22946427eDC03",
        backgroundPosition: "center",
        backgroundSize: "cover",
      },
      palette: {
        primary: {
          main: "#D5D7E1"
        },
        secondary: {
          main: "#F3322C"
        },
        info: {
          main: "#FFFFFF"
        }
      },
      typography: {
        fontFamily: "Londrinda Solid"
      },
    }),
    retired: false
  },

  "Wedding": {
    name: "Wedding",
    theme: createTheme({
      blockOptions: {
        cornerRadius: 1,
        cursorURL: "https://cur.cursors-4u.net/nature/nat-1/nat1.cur",
      },
      palette: {
        primary: {
          main: "#FFFBEB"
        },
        secondary: {
          main: "#FBFFF6"
        },
        info: {
          main: "#343E24"
        }
      },
      typography: {
        fontFamily: "Crimson Text"
      },
    }),
    retired: true
  }
}

// Use Typescript Module Augmentation to add new variables to the theme struct
// https://mui.com/material-ui/customization/theming/
declare module '@mui/material' {
  interface Theme {
    blockOptions: {
      cornerRadius: number;
      cursorURL: string; // pointer to a png or cur file that is max 32x32
      border: string; 
      dropShadow: string;
      backgroundImage: string;
      backgroundSize: string;
      backgroundPosition: string;
      contractAddress: string;
      collectionSlug: string;
      thumbnailURL: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    blockOptions?: {
      cornerRadius?: number;
      cursorURL?: string;
      border?: string; 
      dropShadow?: string;
      backgroundImage?: string;
      backgroundSize?: string;
      backgroundPosition?: string;
      contractAddress?: string;
      collectionSlug?: string;
      thumbnailURL?: string;
    };
  }
}

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}
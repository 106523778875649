import { Dialog, DialogTitle, DialogContent, Button, DialogActions, Input } from '@mui/material';
import { useState } from 'react';
import UploadFormComponent from '../Block-SDK/src/blocks/utils/UploadFormComponent';

function GroupPhotoEditDialog({ open, handleClose, handleSave }) {
  const [newPhoto, setNewPhoto] = useState(null);

  const handlePhotoChange = (photoData) => {
    if (photoData.length > 0) {
      const photoUrl = photoData[0].fileUrl;
      setNewPhoto(photoUrl);
    }
  };

  const onSave = () => {
    handleSave(newPhoto);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Edit Group Photo</DialogTitle>
      <DialogContent>
        <UploadFormComponent onUpdate={handlePhotoChange} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={onSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
}

export default GroupPhotoEditDialog;

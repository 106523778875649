import React from "react";
import { Stack, Divider, Typography, Card } from "@mui/material";
import { makeStyles } from "@mui/styles";
import QuestsPage from "./Quests/QuestsPage";
import { Colors } from "./utils/colors";
import { useSeamUser } from "./utils/SeamUserContext";
import { IonContent, IonPage } from "@ionic/react";
import Header from "./Navigation/Header";
import { ChevronLeft } from "react-feather";
import DesktopSidebarWrapper from "./components/DesktopSidebarWrapper";
import { useMobile } from "./utils/MobileContext";
import { useHistory } from "react-router";

const useStyles = makeStyles({
  questPageHeaderText: {
    fontFamily: "Public Sans",
    fontSize: "18px",
    color: "blue",
    textAlign: "center",
    fontWeight: 600,
  },
  questPageStack: {
    display: "flex",
    alignItems: "center",
    padding: 16,
    height: "100%",
  },
  questPageCard: {
    width: "90vw",
    height: "100%",
    alignItems: "center",
  },
});

const MobileQuestsPage = () => {
  const classes = useStyles();
  const { account } = useSeamUser();
  const { isMobile, isMobileApp } = useMobile();
  const history = useHistory();
  const leftAction = isMobileApp ? () => { } : () => history.go(-1);

  return (
    <IonPage>
      <IonContent fullscreen={true}>
        <Header
          isActivePage={true}
          leftIcon={<ChevronLeft color="black" className="py-2 px-6 cursor-pointer" />}
          rightIcon={null}
          leftAction={leftAction}
          rightAction={() => { }}
        />
        <DesktopSidebarWrapper>
          <div className="w-full h-full max-w-[720px]  flex jusitfy-center items-center">
            <div className="flex flex-col items-start w-full w-max-[720px] h-full">
              <div className="p-4 w-full">
                <h2 className="pb-2 text-seam-black">GM @{account?.get("name")}</h2>
                <h4 className="text-seam-black">
                  Earn Seam Points by completing quests!
                </h4>
              </div>
              <div className="w-full mb-6 bg-seam-green h-auto rounded-1 flex items-center justify-center">
                <h2 className="text-seam-black py-6 px-4">{account?.get("sp")} Seam Points</h2>
              </div>
              <div className="w-full overflow-auto">
                <QuestsPage />
              </div>
            </div>
          </div>
        </DesktopSidebarWrapper>
      </IonContent>
    </IonPage>
  );
};

export default MobileQuestsPage;

import React, { useState } from 'react';
import { Button, TextField, IconButton, Box, Avatar, Select, MenuItem, Stack, Typography } from "@mui/material";
import { PlusCircle, MinusCircle } from 'react-feather';
import InternalBlock from "./InternalBlock";
import { BlockModel } from '../Block-SDK/src/blocks/types';
import { IconsSelector } from '../Block-SDK/src/blocks/utils/IconsRow';
import UploadFormComponent from '../Block-SDK/src/blocks/utils/UploadFormComponent';
import InternalProfile from './InternalProfile';
import '../Block-SDK/src/blocks/BlockStyles.css';
import QuestManager from "../Quests/QuestManager";
import { QuestTypes } from "../Quests/QuestTypes";

interface EditModalProps {
  model: BlockModel;
  done: (data: BlockModel) => void;
}

interface IconField {
  icon: string;
  url: string;
}

export default class InternalProfileBlock extends InternalBlock {
  render() {
    return InternalProfile(this.model, this.theme, this.creatorAccount, this.account);
  }

  renderEditModal(done: (data: BlockModel) => void) {
    const saveChangesToAccount = (bio: string, title: string) => {
      if (this.creatorAccount?.id !== this.account?.id) {
        console.log("you can't edit someone else's profile! get out of here!");
        return;
      }

      this.creatorAccount.set("name", title);
      this.creatorAccount.set("bio", bio);
      this.creatorAccount.save();
    };

    const ProfileEditModal: React.FC<EditModalProps> = ({ model, done }) => {
      const [fields, setFields] = useState<any>(model.data['icons'] ?? []);
      const [title, setTitle] = useState<string>(model.data['title'] ?? '');
      const [bio, setBio] = useState<string>(model.data['bio'] ?? '');
      const [imageURL, setImageURL] = useState<string>(model.data['imageURL'] ?? '');

      const handleAddField = () => {
        setFields([...fields, { icon: '', url: '' }]);
      };

      const handleRemoveField = (index: number) => {
        const newFields = fields.filter((_: any, i: any) => i !== index);
        setFields(newFields);
      };

      const handleFieldChange = (index: number, name: keyof IconField, value: string) => {
        const newFields = [...fields];
        newFields[index][name] = value;
        setFields(newFields);
      };

      const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const bioChanged = model.data['bio'] !== bio;
        const titleChanged = model.data['title'] !== title;

        if (bioChanged || titleChanged) {
          saveChangesToAccount(bio, title);
        }

        model.data['bio'] = bio;
        model.data['title'] = title;
        model.data['icons'] = fields;
        model.data['imageURL'] = imageURL;
        done(model);
      };

      return (
        <Box component="form" className="flex flex-col" onSubmit={handleSubmit}>
          <TextField
            label="Name"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            style={{ marginBottom: '16px' }}
            fullWidth
          />
          <TextField
            label="Bio"
            value={bio}
            onChange={(e) => setBio(e.target.value)}
            multiline
            rows={4}
            inputProps={{ maxLength: 280 }}
            helperText={`${bio.length}/280`}
            style={{ marginBottom: '16px' }}
            fullWidth
          />
          <Box style={{ marginBottom: '16px' }}>
            <UploadFormComponent onUpdate={files => {
              if (files.length === 0) {
                console.log('No files selected.');
              } else {
                const imageURL = files[0].fileUrl;
                setImageURL(imageURL);
                if (this.creatorAccount?.id === this.account?.id) {
                  this.creatorAccount.set("profilePhoto", imageURL);
                  this.creatorAccount.save();
                  QuestManager.completeQuest(QuestTypes.profilePhoto, 1, this.creatorAccount);
                  if (imageURL.includes('.gif')) {
                    QuestManager.completeQuest(QuestTypes.gif, 1, this.creatorAccount);
                  }
                }
              }
            }} />
          </Box>
          {fields.map((field: any, index: any) => (
            <Box key={index} className="flex items-center mb-4">
              <Box flex={1}>
                {IconsSelector()}
              </Box>
              <TextField
                placeholder="URL"
                value={field.url}
                onChange={(e) => handleFieldChange(index, 'url', e.target.value)}
                style={{ width: "250px", marginLeft: '16px' }}
                required
              />
              <IconButton onClick={() => handleRemoveField(index)}>
                <MinusCircle />
              </IconButton>
            </Box>
          ))}
          <Button variant="outlined" onClick={handleAddField} startIcon={<PlusCircle />}>
            Add Social Icon
          </Button>
          <Button type="submit" variant="contained" color="primary" className="save-modal-button" style={{ marginTop: "16px" }}>
            Save
          </Button>
        </Box>
      );
    };

    return <ProfileEditModal model={this.model} done={done} />;
  }

  renderErrorState() {
    return (
      <Typography variant="h1">Error: Couldn't render the profile header.</Typography>
    );
  }
}
import React, { useState, useEffect } from 'react';
import SeamUserItem from '../components/SeamUserItem';
import { useSeamNavigator } from '../Navigation/SeamNavigatorContext';
import FriendRequestButton from '../InternalBlocks/FriendRequestButton';
import { Divider, Typography } from '@mui/material';
import Parse from 'parse';
import { useMobile } from '../utils/MobileContext';

const SearchDropdown = ({ searchTerm }) => {
  const [searchResults, setSearchResults] = useState({ users: [], tags: [] });
  const {isMobileApp, isMobile} = useMobile();
  const seamNavigator = useSeamNavigator();

  useEffect(() => {
    const fetchSearchResults = async () => {
      if (searchTerm.trim().length === 0) {
        setSearchResults({ users: [], tags: [] });
        return;
      }
      
      try {
        const results = await Parse.Cloud.run('combinedSearch', { searchTerm });
        setSearchResults(results);
      } catch (error) {
        console.error('Error during search:', error);
        setSearchResults({ users: [], tags: [] });
      }
    };
    fetchSearchResults();
  }, [searchTerm]);

  const navigateToTag = (tagName) => {
    seamNavigator.navigateTo(`/search/${tagName}`);
  };

  return (
    <div className="mt-2 z-11 space-y-4 flex p-4 mx-2 flex-col rounded-[24px] max-h-[50%] overflow-auto overflow-y-scroll hide-scrollbar border border-seam-black/[5%] max-h-[500px] items-start justify-start w-full z-10 bg-white">
      {searchResults.users.length > 0 && (
        <div className={`flex flex-col z-11  w-full h-full justify-start items-start space-y-4`}>
          <Typography variant="h3" className="p-4">People</Typography>
          <div className="w-full h-auto space-y-4 items-start justify-start">
            {searchResults.users.map((user) => (
              <React.Fragment key={user.id}>
                  <SeamUserItem 
                    avatar={user.get("profilePhoto")}
                    title={<Typography variant="h3" className="text-center leading-none">@{user.get("profileId")}</Typography>}
                    subtitle={null}
                    link={`user/${user.get("profileId")}`}
                    actions={<FriendRequestButton creatorAccount={user} variant="small" textColor={undefined} />}
                  />
              </React.Fragment>
            ))}
          </div>
        </div>
      )}
      {searchResults.users.length > 0 && searchResults.tags.length > 0 && (
        <Divider className="w-full mt-4 mt-6 h-auto bg-seam-black/[5%]"/>
      )}
      {searchResults.tags.length > 0 && (
        <div className="w-full flex flex-col justify-start items-start space-y-4">
          <Typography variant="h3" className="w-auto p-4">Tags</Typography>
          {searchResults.tags.map((tag) => (
            <React.Fragment key={tag.tagName}>
              <div className="flex justify-between w-full items-center space-x-4"> 
                <h3 className={`truncate whitespace-nowrap inline-block rounded-[8px] px-2 py-1 hover:bg-gray-100 text-seam-blue bg-seam-green cursor-pointer`} onClick={() => navigateToTag(tag.tagName)}>
                  #{tag.tagName}
                </h3>
                <div className="w-auto">
                  <h3 className="text-seam-black opacity-40 whitespace-nowrap w-full">
                    {tag.tagCount} {tag.tagCount === 1 ? 'Post' : 'Posts'}
                  </h3>
                </div>
              </div>
            </React.Fragment>
          ))}
        </div>
      )}
      {searchResults.users.length === 0 && searchResults.tags.length === 0 && searchTerm.trim().length > 0 && (
        <div className="p-2 text-center text-sm text-gray-500">No results found</div>
      )}
    </div>
  );
};

export default SearchDropdown;

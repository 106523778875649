import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const ToastContent = ({ content, duration = 5000, onClose }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (content) {
      setIsVisible(true);

      const timer = setTimeout(() => {
        setIsVisible(false);
        if (onClose) onClose();
      }, duration);

      return () => clearTimeout(timer);
    }
  }, [content, duration, onClose]);

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          key="toast"
          initial={{ opacity: 0, y: 50, scale: 0.9 }}
          animate={{ opacity: 1, y: 0, scale: 1 }}
          exit={{ opacity: 0, y: -30, scale: 0.9 }}
          transition={{
            type: 'spring',
            stiffness: 300,
            damping: 20,
            duration: 0.5,
          }}
          style={{ left: 'calc(50% - 180px)', bottom: '120px', }}
          className="fixed transform -translate-x-1/2 w-[360px] bg-black text-white px-6 py-4 rounded-full shadow-lg"
        >
          {content}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default ToastContent;
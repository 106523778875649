import React, { useEffect, useState } from 'react';
import Parse from 'parse';
import SeamPostRepository from "./Post/SeamPostRepository";
import logo from "./assets/logo.svg";
import { useParams } from 'react-router';
import SeamUserFeedItem from './InternalBlocks/WallBlock/SeamUserFeedItem';

interface PostMetaSEOImageProps {
  postId?: string;
}

// a standalone route so that it can be screenshot by puppeteer on the backend to provide an image for SEO and metadata
const PostMetaSEOImage = (props: PostMetaSEOImageProps) => {
  const [post, setPost] = useState<Parse.Object | undefined>(undefined);
  const { postId } = useParams<{ postId: string }>();

  useEffect(() => {
    const fetchPost = async () => {
      if (!postId) {
        return;
      }

      const fetchedPost = await SeamPostRepository.getPost(postId);
      if (!fetchedPost) {
        return;
      }
      setPost(fetchedPost);
    };

    fetchPost();
  }, [postId]);

  return (
    <div className='max-w-[480px] flex flex-col items-center px-4' data-testid="seam-feed-item">
      <img src={logo} alt="Seam Logo" className="py-2 h-[44px]" />
      {post && <div className="w-full h-full max-w-full overflow-clip flex flex-col items-start p-2 rounded-[20px] border border-gray-200 bg-[#FCFCFC] shadow-none">
        <SeamUserFeedItem post={post} ownerAccount={post?.get("author")} isReblog={false} takingScreenshot={true} />
      </div>}
    </div>
  );
};

export default PostMetaSEOImage;
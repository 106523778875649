import { makeStyles } from "@mui/styles";
import Parse from 'parse';
import { useMobile } from "../utils/MobileContext";
import InfiniteScroll from "react-infinite-scroll-component";
import { Colors } from "../utils/colors"
import { SetStateAction, useCallback, useEffect, useState } from "react";
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Typography, Avatar, Box, TextField, IconButton } from "@mui/material";
import { PlusCircle } from "react-feather";
import * as Sentry from "@sentry/react";
import AddFriendsUpsell from "./AddFriendsUpsell";
import { useSeamUser } from "../utils/SeamUserContext";
import { debounce } from 'lodash';
import { useSeamNavigator } from "../Navigation/SeamNavigatorContext";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    width: '100%',
  },
  flexRow: {
    display: "flex",
    flexDirection: "row"
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column"
  },
  spacer: {
    flexGrow: 1
  },
  body: {
    padding: "24px",
    flexGrow: 1
  },
  cardTitle: {
    fontFamily: "Public Sans",
    fontWeight: 700,
    color: 'black',
  },
  cardSubtitle: {
    textAlign: "left",
    fontSize: 14,
    fontWeight: 300,
    color: 'black',
  },
  emptyStateContainer: {
    height: '33vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  emptyStateText: {
    textAlign: "left",
    fontFamily: "Public Sans",
    fontSize: 18,
    fontWeight: 500,
    color: Colors.SEAM_GRAY_600
  },
  cardItem: {
    width: "95%",
    fontSize: "16px",
    color: Colors.SEAM_GRAY_100,
    cursor: "pointer",
    display: 'flex',
    alignItems: 'center',
    paddingInline: 0,
  },
});

const breakpoint = 768

interface ConnectionsFeed {
  channels: Parse.Object[]
  includeGroupAdd: boolean
  creatorAccount: Parse.Object | null;
}

export default function ConnectionsFeed({ includeGroupAdd, channels, creatorAccount }: ConnectionsFeed) {
  const classes = useStyles();
  const seamNavigator = useSeamNavigator();
  const { isMobile } = useMobile();
  const { account } = useSeamUser();
  const [connections, setConnections] = useState<Parse.Object[]>([]);
  const [numFriends, setNumFriends] = useState<number>(0);
  const [hasMore, setHasMore] = useState(true);
  const [scrollHeight, setScrollHeight] = useState("500px");
  const [isLoading, setIsLoading] = useState(true);
  const targetAccount = creatorAccount || account;

  useEffect(() => {
    if (isMobile) {
      setScrollHeight('calc(100vh - 64px)');
    } else {
      setScrollHeight("500px");
    }
  }, []);

  const CONNECTIONS_PER_PAGE = 50;

  const fetchConnections = async (searchTerm: string | undefined = '', skip = 0) => {
    setIsLoading(true);

    if (!targetAccount) return;

    try {
      const connectionsRelation = targetAccount.relation("Connections");
      const query = connectionsRelation.query();

      if (searchTerm) {
        query.matches("profileId", new RegExp(searchTerm, "i")); // Apply search filter if searchTerm is provided
      }

      const results = await query.limit(CONNECTIONS_PER_PAGE).skip(skip).find();
      const count = await query.count(); // Get count after applying any searchTerm filters

      setNumFriends(count); // Update the number of friends based on the current query

      setIsLoading(false);
      if (results.length > 0) {
        if (skip === 0) { // If fetching the first page, replace the connections list
          setConnections(results);
        } else { // If loading more, append to the existing list
          setConnections(prev => [...prev, ...results]);
        }
      } else {
        if (skip === 0) { // No results found for the search term
          setConnections([]); // Clear the connections list
        }
        setHasMore(false);
      }
    } catch (error) {
      console.error("Failed to fetch connections:", error);
      Sentry.captureException(error);
    } finally {
      setIsLoading(false); // Ensure loading state is cleared
    }
  };

  useEffect(() => {
    fetchConnections();
  }, [targetAccount]);

  const loadMoreConnections = () => {
    fetchConnections(undefined, connections.length);
  };

  const debouncedHandleSearch = useCallback(debounce((searchTerm: string) => {
    fetchConnections(searchTerm, 0);
  }, 300), []); // reset the list on new search by passing 0 as skip value

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    debouncedHandleSearch(event.target.value);
  };

  const isOwner = Parse.User.current()?.id == targetAccount?.get("userId");
  const possessive = isOwner ? "Your" : "Their";
  const pronoun = isOwner ? "you" : "they";

  const tapAction = (connection: Parse.Object) => {
    seamNavigator.navigateTo("user/" + connection.get("profileId"));
  };

  const handleInviteToGroup = async (friendUserId: any) => {
    try {
      const currentUserId = account?.get("userId");
      if (!currentUserId) {
        console.error("Current user not found!");
        return;
      }

      const response = await Parse.Cloud.run("inviteToGroup", { currentUserId, friendUserId });

      const groupId = response.id; // Access the id directly from the response

      if (groupId) {
        seamNavigator.navigateTo(`space/${groupId}`);
      } else {
        console.error("Failed to get new group details");
      }
    } catch (error) {
      console.error("Failed to invite to group: ", error);
      Sentry.captureException(error);
    }
  };

  return (
    <div className={classes.root}>
      <Box sx={{ margin: '12px 0px 8px 4px', textAlign: 'left' }}>
        <Typography color="#7F7F7F" fontFamily="Public Sans" fontWeight={600}>
          Friends ({numFriends})
        </Typography>
      </Box>

      <TextField
        label="Search Friends"
        variant="outlined"
        fullWidth
        onChange={handleSearchChange}
        sx={{ marginBottom: 2, border: '2px solid #EFEFEF', color: 'black', }}
      />

      {connections.length > 0 || isLoading ? (
        <InfiniteScroll
          dataLength={connections.length}
          next={loadMoreConnections}
          hasMore={hasMore}
          loader={<h4>Loading...</h4>}
          height={scrollHeight}
          style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}
        >
          {connections.map((connection, index) => (
            <ListItem
              alignItems="flex-start"
              className={classes.cardItem}
              onClick={!includeGroupAdd ? () => tapAction(connection) : undefined}
              key={index}
            >
              <Avatar
                src={`${connection.get("profilePhoto")}?w=64&h=64&fit=crop&auto=format`}
                srcSet={`${connection.get("profilePhoto")}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                alt={connection.get("name")}
                style={{ height: 48, width: 48, marginRight: '12px' }}
              />
              <ListItemText
                classes={{ primary: classes.cardTitle, secondary: classes.cardSubtitle }}
                primary={connection.get("name")}
                secondary={`@${connection.get("profileId")}`}
              />
              {includeGroupAdd && (
                <IconButton onClick={() => handleInviteToGroup(connection.get("userId"))}>
                  <PlusCircle />
                </IconButton>
              )}
            </ListItem>
          ))}
          {(!isLoading && isMobile) && <AddFriendsUpsell />}
        </InfiniteScroll>
      ) : (
        <div className={classes.emptyStateContainer}>
          <Typography className={classes.emptyStateText}>
            {`Nothing here - ${pronoun} haven't made any friends yet.`}
          </Typography>
        </div>
      )}
    </div>
  );
}
import { BlockModel } from '../Block-SDK/src/blocks/types'
import { Theme } from "@mui/material"

interface IBlock {
  name: string
  model: BlockModel
  theme: Theme
  creatorAccount: Parse.Object // the account of the person who made the current card
  account: Parse.Object // the account of the person logged in viewing the current card
  currentCard: Parse.Object // the card hosting the block
  onEditCallback?: () => void

  render(): React.ReactNode
  renderEditModal(done: () => void): React.ReactNode
  renderErrorState(): React.ReactNode
}

export default class InternalBlock implements IBlock {
  name: string;
  model: BlockModel;
  theme: Theme;
  creatorAccount: Parse.Object;
  account: Parse.Object;
  currentCard: Parse.Object;
  onEditCallback?: () => void;

  constructor(model: BlockModel, theme: Theme, creatorAccount: Parse.Object, account: Parse.Object, currentCard: Parse.Object) {
    this.name = model.type
    this.model = model
    this.theme = theme
    this.creatorAccount = creatorAccount
    this.account = account
    this.currentCard = currentCard
  }

  render(): React.ReactNode {
    throw new Error("Method not implemented.");
  }
  renderEditModal(done: (data: BlockModel) => void): React.ReactNode {
    throw new Error("Method not implemented.");
  }
  renderErrorState(): React.ReactNode {
    throw new Error("Method not implemented.");
  }
}
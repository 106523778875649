import React, { useState, useRef, useEffect } from "react";
import { IonHeader, IonSearchbar, IonToolbar } from "@ionic/react";
import logo from "../assets/logo.svg";
import pinkLogo from "../assets/SeamPink.png";
import { useSeamNavigator } from "./SeamNavigatorContext";
import { useMobile } from "../utils/MobileContext";
import SearchDropdown from "../Discover/SearchDropdown";
import useSearchReducer from "../utils/useSearchReducer";
import { SearchOff } from "@mui/icons-material";

export default function Header({
  isActivePage,
  leftIcon,
  rightAction,
  leftAction,
  leftActionOnMobileApp,
  rightIcon,
  showSearchBar,
  searchState,
  dispatch,
  onSearchSubmit,
  scrollToTop,
  rightIconColor,
  leftIconColor,
  centerIcon,
  includePadding = false,
  darkMode,
}) {
  const { isMobile, isMobileApp } = useMobile();
  const [searchTerm, setSearchTerm] = useState('');
  const seamNavigator = useSeamNavigator();
  const searchbarRef = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);

  const handleToggleSearchBar = () => {
    dispatch({ type: 'TOGGLE_SEARCH_BAR' });
  };

  useEffect(() => {
    window.addEventListener('toggleSearchBar', handleToggleSearchBar);

    return () => {
      window.removeEventListener('toggleSearchBar', handleToggleSearchBar);
    };
  }, []);

  const handleInput = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    dispatch({ type: 'UPDATE_SEARCH_STRING', payload: value });
  };

  const handleSearchClear = () => {
    setSearchTerm('');
    setShowDropdown(false);
  };

  function LeftIcon() {
    const handleLeftClick = () => {
      if (leftActionOnMobileApp) {
        leftAction();
      } else if (isMobileApp && seamNavigator.canGoBack()) {
        seamNavigator.goBack();
      } else {
        leftAction();
      }
    };

    return (
      <div onClick={handleLeftClick} className={`flex justify-start items-center rounded-full`}>
        {leftIcon}
      </div>
    );
  }

  function RightIcon() {
    return (
      <div onClick={rightAction} className={`flex justify-end items-center rounded-full`} style={{ backgroundColor: rightIconColor }}>
        {isActivePage && rightIcon}
      </div>
    );
  }

  const handleCenterIconClick = () => {
    if (scrollToTop) {
      scrollToTop();
    } else {
      {!isMobileApp && seamNavigator.navigateTo("/")}
    }
  };

  let color = "transparent"
  color = darkMode ? "white" : "black";

  return (
    <IonHeader class="ion-no-border w-full h-[54px]">
      {/* Extend border using a pseudo-element */}
      {showSearchBar && isMobileApp ? (
        <>
          <div ref={searchbarRef} className="flex relative self-center p-0 my-0 w-full max-w-[720px] relative">
            <IonSearchbar
              onIonCancel={() => dispatch({ type: 'TOGGLE_SEARCH_BAR' })}
              onIonChange={(e) => onSearchSubmit(e.detail.value)}
              onIonInput={handleInput}
              onIonClear={handleSearchClear}
              showCancelButton="always"
              showClearButton="always"
              placeholder="Search Seam..."
              debounce={300}
              type="search"
              className="homeSearch"
            />
            {searchTerm && (
              <div className="absolute z-11 w-full mt-[48px] flex items-center justify-center">
                <SearchDropdown searchTerm={searchTerm} />
              </div>
            )}
          </div>
        </>
      ) : (
        <div className={`flex items-center bg-transparent bg-opacity-20 justify-between w-full h-full ${!isMobileApp && 'border-b'} border-seam-black/[5%]`} style={{paddingTop: includePadding ? 'calc(env(safe-area-inset-top)' : 0}}>
          <LeftIcon />
          <div className="flex w-auto mx-4 shrink truncate" onClick={handleCenterIconClick}>
            {centerIcon ? centerIcon : <img src={darkMode ? pinkLogo : logo} alt="Seam Logo" className="h-[44px]" />}
          </div>
          {rightIcon && RightIcon(rightIconColor)}
          {!rightIcon && <div></div>}
        </div>
      )}
    </IonHeader>
  );
}
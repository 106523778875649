import { memo } from "react";
import SeamEngagementBar from "../Comment/EngagementBar/SeamEngagementBar";
import SeamUserFeedItem from "../InternalBlocks/WallBlock/SeamUserFeedItem";

function FeedItem(props) {
  const { post, collection, postInCollection, isOnPostDetailPage = false, isOnChannelPage, darkMode } = props;
  let color = darkMode ? 'white' : 'black';
  
  const DeletedPost = (
    <div className={`h-12 flex items-center justify-center bg-seam-gray text-${color} rounded-xl`}>
      This post has been deleted
    </div>
  )

  if (post == undefined) {
    return null
  }

  return (
    <div
      key={post?.id}
      className="flex flex-col w-full items-start bg-transparent shadow-none"
    >
      <div className="w-full h-full">
        {post?.get("isDeleted") ? (
          DeletedPost
        ) : (
          <SeamUserFeedItem {...props} />
        )}
      </div>
      <div className="w-full">
        <div className="h-[16px]"></div>
        <SeamEngagementBar post={post} collection={collection} postInChannel={postInCollection} isOnPostDetailPage={isOnPostDetailPage} isOnChannelPage={isOnChannelPage} darkMode={darkMode}/>
      </div>
    </div>
  );
}

export default memo(FeedItem);

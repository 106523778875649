import { Card, Stack, Skeleton } from "@mui/material";

export const LoadingSkeleton = () => {
    return new Array(10).fill(10).map((x, index) =>
    <>
      <div key={index} className="flex flex-col p-4">
        <Stack spacing={1}>
          <Skeleton variant="circular" width={40} height={40} />
          <Skeleton variant="rectangular" width={"100%"} height={80} />
        </Stack>
      </div>
    </>
    );
}
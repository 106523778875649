import { useEffect, useRef, useState } from 'react';
import Parse from 'parse';
import FeedItem from "./Discover/FeedItem";
import SeamPostRepository from "./Post/SeamPostRepository";
import { useSeamUser } from "./utils/SeamUserContext";
import { IonPage, IonContent, useIonViewWillEnter, useIonViewWillLeave, IonModal } from '@ionic/react';
import { useHistory, useParams } from "react-router-dom";
import { ChevronLeft, Plus } from "react-feather";
import SeamCommentRepository from './Comment/SeamCommentRepository';
import CommentComposer from './Discover/CommentComposer';
import { sendCommentNotification } from './utils/NotificationSendingHelpers';
import { Virtuoso } from 'react-virtuoso';
import SeamComment from './Comment/SeamComment';
import { useMobile } from './utils/MobileContext';
import DesktopSidebarWrapper from './components/DesktopSidebarWrapper';
import { Seo } from './utils/Seo';
import BlockFactory from './Block-SDK/src/blocks/BlockFactory';
import Header from './Navigation/Header';
import logo from "./assets/logo.svg";
import { Box, Typography } from '@mui/material';
import { useSeamNavigator } from './Navigation/SeamNavigatorContext';
import SeamPillButton from './Block-SDK/src/components/SeamPillButton';
import SeamShareBottomSheet from './Comment/EngagementBar/SeamShareBottomSheet';
import { ReactComponent as StitchIcon } from "./assets/icons/stitchIcon.svg"
import mixpanel from 'mixpanel-browser';
import { getProcessedProfilePictureURL } from './components/helpers/ImageTransformers';
import { Avatar } from '@mui/material';

const VirtuosoHeader = ({ context }: any) => {
  const { post } = context;
  if (!post) {
    return null;
  }
  return (
    <div className="w-auto my-4 px-4">
      <FeedItem key={post.id} post={post} collection={undefined} isOnPostDetailPage={true} />
    </div>
  );
}

const VirtuosoFooter = ({ context }: any) => {
  const miniappNames = ["pixel art", "memes", "hot takes", "works of art", "anything you want"]; // end of footer CTA sentence strings
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fade, setFade] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(false);
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % miniappNames.length);
        setFade(true);
      }, 500); // Duration of fade-out animation
    }, 3000); // Duration to show each miniapp name

    return () => clearInterval(interval);
  }, [miniappNames.length]);

  const { isMobile, account } = context;
  if (account) { 
    return null; 
  } else {
    return (
      <>
      <style>{`
      @keyframes radiate {
        0% {
          box-shadow: 0 0 0 0 rgba(255, 0, 255, 0.4);
        }
        70% {
          box-shadow: 0 0 20px 40px rgba(255, 0, 255, 0);
        }
        100% {
          box-shadow: 0 0 0 0 rgba(255, 0, 255, 0);
        }
      }

      .radiating-button {
        animation: radiate 2s infinite;
      }

      .fade-transition {
        transition: opacity 0.5s;
      }

      .opacity-0 {
        opacity: 0;
      }

      .opacity-100 {
        opacity: 1;
      }
    `}</style>
        <Box className="w-auto rounded-[20px]" style={{ paddingBottom: `calc(env(safe-area-inset-bottom, 24px) + 24px)` }} sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, bgcolor: 'background.paper', boxShadow: 3, zIndex: 1301 }}>
          <div className="w-auto h-auto flex items-center justify-center p-4 flex-row bg-seam-white">
            <img src={logo} alt="Seam Logo" className="h-[44px] mr-4" />
            <div className="flex flex-1 items-center justify-center">
              {isMobile ? (
                <h4 className="font-sans">
                  Why are you reading this when you COULD be creating{" "}
                  <span className={`fade-transition ${fade ? 'opacity-100' : 'opacity-0'}`}>
                    {miniappNames[currentIndex]}
                  </span>
                </h4>
              ) : (
                <h3 className="font-sans">
                  Why are you reading this when you COULD be creating{" "}
                  <span className={`fade-transition ${fade ? 'opacity-100' : 'opacity-0'}`}>
                    {miniappNames[currentIndex]}
                  </span>
                </h3>
              )}
            </div>
            <div className="flex max-w-justify-end items-center text-white rounded-full bg-seam-pink radiating-button" style={{ marginLeft: '16px' }}>
              <div className="py-2 px-6 cursor-pointer h-full">
                <a href={"https://apps.apple.com/us/app/seam-social/id6473547569"} target="_blank" rel="noopener noreferrer">
                  <Plus color="white" />
                </a>
              </div>
            </div>
          </div>
        </Box>
      </>
    );
  }
}

interface PostDetailPageProps {
  postId?: string;
}

const PostDetailPage = (props: PostDetailPageProps) => {
  const modal = useRef(null);
  const { contextObject } = useSeamNavigator();
  const { postId } = useParams<{ postId: string }>();
  const [post, setPost] = useState<Parse.Object | undefined>(contextObject?.id == postId ? contextObject : undefined);
  const [comments, setComments] = useState<Parse.Object[]>([]);
  const { account } = useSeamUser();
  const history = useHistory();
  const { isMobile, isMobileApp } = useMobile();
  const [optimisticComments, setOptimisticComments] = useState<Parse.Object[]>([]);
  const [isActivePage, setIsActivePage] = useState(true);
  const [isSharing, setIsSharing] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const COMMENTS_PER_PAGE = 5;

  useEffect(() => {
    if (postId && postId == post?.id) { return }
    fetchPost();
  }, [postId]);

  useEffect(() => {
    if (post) {
      loadMoreComments();
    }
  }, [post]);

  const fetchPost = async () => {
    if (!postId) {
      return;
    }

    const fetchedPost = await SeamPostRepository.getPost(postId);
    if (!fetchedPost) {
      return;
    }
    setPost(fetchedPost);
  };

  // used to avoid showing the comment composer when the user is not on the page
  useIonViewWillEnter(() => {
    setIsActivePage(true);
  });

  useIonViewWillLeave(() => {
    setIsActivePage(false);
  });

  const loadMoreComments = async () => {
    if (!hasMore || !post || loading) {
      return;
    }
    setLoading(true);
    try {
      const query = new Parse.Query("Comment");
      query.equalTo("parentPost", post);
      query.doesNotExist("parentComment");
      query.include("author");
      query.ascending("createdAt");
      query.limit(COMMENTS_PER_PAGE);
      query.skip(comments.length);

      const results = await query.find();
      setComments(prevComments => [...prevComments, ...results]);
      if (results.length < COMMENTS_PER_PAGE) {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Failed to load comments:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleAddComment = async (commentText: any, mentionees: any, miniappData: any) => {
    const onSuccess = (newComment: Parse.Object) => {
      if (!post || !account) return;

      let notifierId = post.get("author")?.get("userId");
      sendCommentNotification(account, account.get("name"), notifierId, post.id, commentText);
      setOptimisticComments(prevComments => [...prevComments, newComment]);
    };

    const onError = (error: any) => {
      console.error("Unable to comment: ", error);
      // Error handling logic remains
    };

    SeamCommentRepository.createComment({
      author: account as any,
      parentPost: post as any,
      text: commentText,
      miniappData: miniappData,
      parentComment: undefined
    }, onSuccess, onError);
  };

  const leftAction = isMobileApp ? () => { } : () => history.go(-1);

  const authorAccount = post?.get("author");
  const postAuthorName = authorAccount?.get("profileId");
  const postData = post?.get("postData");
  const blockData = postData?.blockData;

  const miniappName = blockData ? BlockFactory.getPrintableBlockName(blockData) : "Unknown";
  const seoTitle = postData?.text ? postData.text : `${postAuthorName}'s post on Seam`;
  const seoImage = `https://firebasestorage.googleapis.com/v0/b/seam-social-posts/o/${postId}.jpg?alt=media`;
  const seoDescription = miniappName === "Unknown" ? "Create with miniapps on Seam" : `Use the ${miniappName} miniapp on Seam`;

  const HeaderCTA = () => {
    const seamNavigator = useSeamNavigator();
    const processedAvatar = getProcessedProfilePictureURL(authorAccount.get("profilePhoto"));
  
    return (
      <div className="flex items-center w-full h-auto">
        <div className="flex-shrink-0 mr-2">
          <Avatar src={processedAvatar} className="w-[32px] h-[32px]" />
        </div>
        <div className="flex-1 min-w-0">
          <div
            onClick={() => {
              seamNavigator.navigateTo('/');
            }}
            className="cursor-pointer hover:underline"
          >
            <Typography
              variant={isMobile ? 'h4' : 'h3'}
              className="font-sans"
              noWrap
            >
              {`${authorAccount.get("profileId")} is on Seam`}
            </Typography>
          </div>
          <Typography
            variant={isMobile ? 'h4' : 'h3'}
            className="font-sans text-gray-600"
            noWrap
          >
            {`Join now to connect`}
          </Typography>
        </div>
        <div className="flex-shrink-0 ml-2">
          <div className="py-1 px-3 flex items-center justify-center bg-seam-blue text-seam-white rounded-full whitespace-nowrap">
            <a
              href="https://apps.apple.com/us/app/seam-social/id6473547569"
              rel="noreferrer"
              target="_blank"
              className="text-seam-white font-sans"
            >
              Sign in
            </a>
          </div>
        </div>
      </div>
    );
  };

  const context = { post, account, isMobile, postAuthorName };

  const tapShareButton = async (event: any) => {
    if (!account) { return }
    mixpanel.track("share clicked")
    setIsSharing(true);
  }

  const leftIcon = () => {
    return (
      <div className={`${isMobile ? 'ml-4' : 'ml-[48px]'}`} onClick={leftAction}>
        <SeamPillButton
          icon={<ChevronLeft className={`text-seam-black h-4 w-4`} />}
          label={null}
          onClick={() => {}}
          darkMode={false}
          colorOverride={undefined}
          isOnChannelPage={false}
        />
      </div>
    )
  }

  const rightIcon = () => {
    
    return (
      <div className={`${isMobile ? 'mr-4' : 'mr-[48px]'}`}>
        <SeamPillButton
          icon={<StitchIcon className={`text-seam-black h-4 w-4`} />}
          label={null}
          darkMode={false}
          colorOverride={undefined}
          onClick={tapShareButton}
          isOnChannelPage={false}
        />
      </div>
    )
  }
  
  return (
    <IonPage className="w-full h-full" id={"post " + postId}>
      <Seo
        title={seoTitle}
        description={seoDescription}
        author={"@" + postAuthorName}
        imageURL={seoImage}
        url={"https://www.seam.so/post/" + postId}
      />
      <Header
        isActivePage={true}
        leftIcon={isMobile && leftIcon()}
        rightIcon={isMobile && rightIcon()}
        showSearchBar={false}
        searchState={undefined}
        dispatch={undefined}
        leftAction={() => { }}
        leftActionOnMobileApp={false}
        rightAction={() => { }}
        onSearchSubmit={() => { }}
        scrollToTop={undefined}
        leftIconColor={undefined}
        rightIconColor={undefined}
        centerIcon={post && !account && <HeaderCTA />}
        darkMode={false}
      />
      <IonContent fullscreen={true} scrollY={false}>
        <DesktopSidebarWrapper 
          showDesktopHeader={true} 
          leftIcon={!isMobile && leftIcon()}
          centerIcon={null}
          rightIcon={!isMobile && rightIcon()}
          navSection={undefined}
          darkMode={false}
        >
          <div className="flex flex-col w-full justify-between h-full hide-scrollbar">
            <div className="w-full h-full">
              {post && (
                <Virtuoso
                  className={`ion-content-scroll-host hide-scrollbar`}
                  data={comments.concat(optimisticComments)}
                  endReached={loadMoreComments}
                  overscan={3}
                  itemContent={(index, comment) => {
                    return (
                      <div key={comment.id} className="w-full m-auto">
                        <SeamComment comment={comment} post={post} isOnCommentDetailPage={false} />
                      </div>
                    )
                  }}
                  components={{
                    Header: () => <VirtuosoHeader context={context} />,
                    Footer: () => <VirtuosoFooter context={context} />,
                  }}
                />
              )}
            </div>
            <div>
              {account && <CommentComposer onSubmit={handleAddComment} isActivePage={isActivePage} />}
            </div>
          </div>

          {(isSharing) && (
        <IonModal
          ref={modal}
          isOpen={isSharing}
          initialBreakpoint={isSharing ? 0.3 : 1}
          breakpoints={isSharing ? [0, 0.3] : [0, 1]}
          canDismiss={true}
          showBackdrop={true}
          onDidDismiss={() => {
            setIsSharing(false);
          }}
        >
          <div style={{marginBottom: 'env(safe-area-inset-bottom)'}} className="flex flex-col justify-center w-auto h-full my-6 px-4">
            {isSharing && <SeamShareBottomSheet type="post" object={post} />}
          </div>
        </IonModal>
      )}
        </DesktopSidebarWrapper>
      </IonContent>
    </IonPage>
  );
};

export default PostDetailPage;
import { Typography, Stack } from '@mui/material';
import QuestItem from './QuestItem';
import { QuestTypes } from './QuestTypes';
import { useSeamUser } from '../utils/SeamUserContext';
import { Virtuoso } from 'react-virtuoso';

interface QuestsPageProps {

}

export default function QuestsPage(
  { }: QuestsPageProps
) {
  const { account } = useSeamUser();
  let currentQuests = Object.entries(QuestTypes)
  let accountQuestStatus = account?.get("quests") ?? {}

  return (
    <div className="flex flex-col w-full h-full">
        <Virtuoso
        style={{height: window.innerHeight - 250}}
        className={`ion-content-scroll-host w-full`}
        data={currentQuests}
        itemContent={(index, questType) => (
          <div className="w-full h-full flex-col justify-between px-4 max-w-[720px]">
            <QuestItem key={questType[0]} questType={questType[1]} questStatus={accountQuestStatus[questType[0]]} />
          </div>
        )}
        />
    </div>
  );
}